import React, { useState, useEffect } from "react";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import EditableInputWithDiv from "../../../../common/components/EditableText/EditableInputWithDiv";
import { Checkbox } from "antd";
import { useSelector } from "react-redux";
import Loading from "src/common/components/Loading";
import { LoadingSVG } from "src/common/components/icons/SVGIcon";

const ExaminationVitals = (props) => {
  const [data, setData] = useState([]);
  const [dataCheckedLeft, setDataCheckedLeft] = useState(false);
  const [dataCheckedRight, setDataCheckedRight] = useState(false);
  const ignore_bp_left = useSelector((state) => state.patientExamination.ignore_bp_left);
  const ignore_bp_right = useSelector((state) => state.patientExamination.ignore_bp_right);
  const loadingEx = useSelector((state) => state.patientExamination.loadingEx);

  useEffect(() => {
    if (props.data && props.data?.some((r) => r?.type == "ignore_bp_right") && props.data?.some((r) => r?.type == "ignore_bp_left")) {
      setData(props.data);
    } else {
      if (props.data) {
        setDataCheckedLeft(!!ignore_bp_left);
        setDataCheckedRight(!!ignore_bp_right);
        let dataMix = [
          ...props.data?.slice(0, 2),
          {
            type: "ignore_bp_right",
            value: ignore_bp_right,
          },
          ...props.data?.slice(2),
        ];
        dataMix = [
          ...dataMix?.slice(0, 4),
          {
            type: "ignore_bp_left",
            value: ignore_bp_left,
          },
          ...dataMix?.slice(4),
        ];
        setData(dataMix);
      }
    }
  }, [props.data, ignore_bp_right, ignore_bp_left]);

  const onChangeValue = (index, value) => {
    if (index === 11) return;
    const dataCheck = [...data];
    dataCheck[index]["value"] = value;
    if (value && value !== "0" && (index === 8 || index === 9)) {
      let height = parseInt(dataCheck?.[9]?.["value"]);
      let weight = parseInt(dataCheck?.[8]?.["value"]);
      if (weight > 0 && height > 0) {
        dataCheck[11]["value"] = weight ? (weight / (height * 0.0254) ** 2).toFixed(2) : weight.toFixed(2);
      }
    }
    setData(dataCheck);
    props.onChangeData(dataCheck);
  };

  const onChangeValueCheck = (index, value, type) => {
    let sub_type = null;
    if (type === "BP (Left)") sub_type = "ignore_bp_left";
    else if (type === "BP (Right)") sub_type = "ignore_bp_right";

    const findIndex = data.findIndex((r) => r?.type == sub_type);
    const findIndexMain = data.findIndex((r) => r?.type == type);
    if (findIndex != -1) {
      data[findIndex]["value"] = value;
      data[findIndexMain]["value"] = "";
      setData(data);
      props.onChangeData(data);
    }
  };

  const getUnitValue = (type) => {
    if (type === "Temperature") {
      return "℉";
    }
    if (type === "HR") {
      return "BPM";
    }
    if (type === "Resp") {
      return "RR";
    }
    if (type === "SpO2") {
      return "%";
    }
    if (type === "Weight") {
      return "Kg";
    }
    if (type === "Height") {
      return "Inches";
    }
    if (type === "BP (Right)" || type === "BP (Left)") {
      return "mmHg";
    }
    if (type === "Pain Rating") {
      return "/ 10";
    }
    return undefined;
  };

  const renderBP = (item, index) => {
    let valueCheck = item["type"] == "BP (Left)" ? dataCheckedLeft : dataCheckedRight;
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}>
        <EditableInputWithDiv
          isErrorTextSpacing={valueCheck ? false : true}
          label={item["type"]}
          id={
            item["type"] &&
            item["type"]
              .replaceAll("-[object Object]", "")
              .replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
              .replaceAll(" ", "_")
          }
          value={item["value"]}
          subLabel={"BP"}
          unit={"mmHg"}
          handleChange={(value) => onChangeValue(index, value)}
          required={valueCheck ? false : true}
          isError={valueCheck ? false : true}
          enableErrorText={valueCheck ? false : true}
          isCheckBox={true}
          disabled={props?.isLocked}
          helperText={`${item["type"]} is required.`}
        />
        <Checkbox
          disabled={props?.isLocked}
          style={{ marginLeft: "10px", fontWeight: "bold", position: "absolute", left: "400px", top: "12px" }}
          checked={item["type"] == "BP (Left)" ? dataCheckedLeft : dataCheckedRight}
          onChange={(e) => {
            if (item["type"] === "BP (Left)") {
              setDataCheckedLeft(e?.target?.checked);
            } else {
              setDataCheckedRight(e?.target?.checked);
            }
            onChangeValueCheck(index, e?.target?.checked, item["type"]);
          }}
        >
          Exclude
        </Checkbox>
      </div>
    );
  };

  return (
    <div className="resourceInfo no-padding" style={{ paddingLeft: "0" }}>
      {loadingEx ? (
        <div className="loading-icon loading-vital-ros">
          <div style={{ width: 120, height: 120 }}>
            <LoadingSVG />
          </div>
        </div>
      ) : (
        <React.Fragment>
          {(data || []).map((item, index) => (
            <>
              {item["type"] !== "ignore_bp_right" && item["type"] !== "ignore_bp_left" ? (
                <div key={`vital-${index}`} className={`resourceInfo d-flex required-field`}>
                  {item["type"] !== "BP (Right)" &&
                    item["type"] !== "BP (Left)" &&
                    item["type"] !== "ignore_bp_left" &&
                    item["type"] !== "ignore_bp_right" && (
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        id={
                          item["type"] &&
                          item["type"]
                            .replaceAll("-[object Object]", "")
                            .replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                            .replaceAll(" ", "_")
                        }
                        label={item["type"]}
                        value={item["value"]}
                        unit={getUnitValue(item["type"])}
                        handleChange={(value) => onChangeValue(index, value)}
                        required
                        isError={!item["value"]}
                        enableErrorText={true}
                        helperText={`${item["type"]} is required.`}
                        disabled={props.isLocked}
                      />
                    )}
                  {(item["type"] === "BP (Right)" || item["type"] === "BP (Left)") && renderBP(item, index)}
                </div>
              ) : null}
            </>
          ))}
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(ExaminationVitals);

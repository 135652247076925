import { createReducer } from "redux-create-reducer";
import moment from "moment";
import AT from "../actionTypes";

const initialState = {
  conditions: [],
  allConditions: [],
  descList: {},
  calList: [],
  saving: false,
  leftRight: {},
  reason_for_visit: "",
  same_hpi_selection_reason: "",
  ultrasounds: [],
  obl_hospital_appointments: [],
  has_aaa_condition: false,
  has_same_hpi: false,
  isSaveHPI: false,
};

const LOAD_CONDITION_HPI_UNTRASOUND_SUCCEEDED = `${AT.LOAD_CONDITION_HPI_UNTRASOUND}_SUCCEEDED`;
const SET_IS_SAVE_HPI_SUCCEEDED = `${AT.SET_IS_SAVE_HPI}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      ...initialState,
      conditions: [],
      allConditions: [],
      descList: {},
      saving: false,
      leftRight: {},
      reason_for_visit: "",
      same_hpi_selection_reason: "",
      has_same_hpi: false,
      ultrasounds: [],
      obl_hospital_appointments: [],
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.HPI_GET_CONDITIONS}_SUCCEEDED`](state, action) {
    const {
      conditions,
      allConditions,
      descList,
      leftRight,
      reason_for_visit,
      ultrasounds,
      obl_hospital_appointments,
      has_aaa_condition,
      same_hpi_selection_reason,
      has_same_hpi,
    } = action?.payload || {};
    return {
      ...state,
      conditions,
      allConditions,
      descList,
      leftRight,
      reason_for_visit,
      same_hpi_selection_reason,
      ultrasounds,
      obl_hospital_appointments,
      has_aaa_condition,
      has_same_hpi,
    };
  },
  [`${AT.HPI_SET_CONDITIONS}`](state, action) {
    const { conditions } = action?.payload || {};
    return {
      ...state,
      conditions,
    };
  },
  [`${AT.SAVE_SAME_HPI_CHECK}`](state, action) {
    const { has_same_hpi } = action?.payload || {};
    console.log(has_same_hpi, "has_same_hpi");
    return {
      ...state,
      has_same_hpi,
    };
  },
  [`${AT.HPI_SAVE_CONDITION}_STARTED`](state, action) {
    const { message } = action?.payload || {};
    return {
      ...state,
      saving: true,
    };
  },
  [`${AT.HPI_SAVE_CONDITION}_SUCCEEDED`](state, action) {
    const { leftRight } = action?.payload || {};
    return {
      ...state,
      leftRight: leftRight || {},
      saving: false,
    };
  },
  [LOAD_CONDITION_HPI_UNTRASOUND_SUCCEEDED](state, action) {
    const { calList, descList } = action?.payload || {};
    return Object.assign({}, state, { ...state, calList: calList || [], descList: descList || {} });
  },
  [SET_IS_SAVE_HPI_SUCCEEDED](state, action) {
    const isSaveHPI = action?.payload || false;
    return Object.assign({}, state, { ...state, isSaveHPI });
  },
});

import { Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PDFView from "../../../common/components/PDFView";
import { getLocal } from "../../../helpers/Local";
import {
  clear_patient_documents,
  get_filter_patient_document,
  put_patient_document,
  reset_select_document,
  setSidebarState,
} from "../../../store/actions";
import LaboratoryTable from "./LaboratoryTable";
import VideoImageViewer from "./VideoImageViewer";
import PatientChartSideBar from "./sidebar";

const { TabPane } = Tabs;

const PatientChart = (props) => {
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const selectedDocument = useSelector((state) => state.clinicProvider.selectedDocument);

  const dispatch = useDispatch();
  const current_patient_id = getLocal("current_patient_id");
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState("documentation");
  const [selectPDF, setSelectPDF] = useState(null);
  const [resetClick, setResetClick] = useState(false);
  const opener = useRef();
  useEffect(() => {
    dispatch(reset_select_document());
    dispatch(setSidebarState(true));
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    } else if (patientResource) {
      setIsSidebar(true);
    }
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  useEffect(() => {
    setSelectPDF(selectedDocument);
  }, [selectedDocument]);

  const rotatePdf = (orientation) => {
    const { id } = selectPDF;
    if (id) {
      dispatch(
        put_patient_document(id, {
          media_orientation: orientation,
        })
      );
    }
  };

  const fetchCheck = async () => {
    let params = { patient_id: current_patient_id, page: 1 };
    if (activeTab === "documentation") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    } else if (activeTab === "image-viewer-tab") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    } else if (activeTab === "laboratory") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    }
    await dispatch(get_filter_patient_document(params));
    await setIsSidebar(true);
  };

  useEffect(() => {
    fetchCheck();
  }, [activeTab, current_patient_id]);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleTab = (activeTab) => {
    dispatch(clear_patient_documents());
    setActiveTab(activeTab);
  };
  const sidebarRef = useRef();
  return (
    <div
      style={{
        display: !props.display ? "flex" : props.display === "flex" ? "flex" : "none",
      }}
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <Tabs className="patientChart" type="card" onChange={handleTab} activeTab={activeTab} onTabClick={() => setIsSidebar(true)}>
          <TabPane tab="Documentation" key="documentation">
            <PDFView
              url={selectPDF && selectPDF.file_path}
              onRotate={rotatePdf}
              rotations={selectPDF?.media_orientation ? JSON.parse(selectPDF?.media_orientation) : []}
            />
          </TabPane>

          <TabPane tab="Images" key="image-viewer-tab">
            <VideoImageViewer resetClick={resetClick} setResetClick={setResetClick} />
          </TabPane>

          {/*<TabPane tab="Laboratory" key="laboratory">
            <LaboratoryTable />
          </TabPane>*/}
        </Tabs>
      </div>
      <PatientChartSideBar
        ref={sidebarRef}
        sidebarIcon={props.sidebarIcon}
        opener={opener}
        resource={patientResource}
        setResetClick={setResetClick}
        activeTab={activeTab}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
    </div>
  );
};

export default PatientChart;

import { Checkbox, Collapse, Icon, Input, Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import DeleteIconButton from "../../../../common/components/icons/DeleteIconButton";
import { CloseIconSVG, SearchSVG } from "../../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { getLocal } from "../../../../helpers/Local";
import RemoveN from "../../../../helpers/RemoveN";
import ReplaceN from "../../../../helpers/ReplaceN";
import {
  angioPatientSnapshotLoadInit,
  detail_angio_type,
  HPIGetConditions,
  load_intra_operative,
  save_intra_operative,
  set_data_template_review,
} from "../../../../store/actions";
import { SelectOptions, SelectOptionsExtra } from "../../../../data/hpi";

let timer = null;

const ProcedureSearch = ({ setResource, updateData, options }) => {
  const [data, setData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [isDelete, setIsDelete] = useState(-1);
  const [searchInput, setSearch] = useState("");
  const [stateShowRed, setStateShowRed] = useState({});
  const dispatch = useDispatch();
  const id_patient = getLocal("current_patient_id");
  const id_apoinment = getLocal("current_appointment_id");
  const [masterAaa, setMasterAaa] = useState(false);
  const intra = useSelector((state) => state.patientExamination.intra);
  const data_template_review = useSelector((state) => state.patientSchedule.data_template_review);
  const is_locked = useSelector((state) => state.common.is_locked);
  const referralConditions = useSelector((state) => state.common.referralConditions);

  const [indexesLeft, setIndexesLeft] = useState([]);
  const [indexesRight, setIndexesRight] = useState([]);
  const [checkHover, setCheckHover] = useState({});
  const [checkHover2, setCheckHover2] = useState({});
  const [indexesLeftCarotidA, setIndexesLeftCarotidA] = useState(false);
  const [indexesRightCarotidA, setIndexesRightCarotidA] = useState(false);
  const [indexesLeftCarotidS, setIndexesLeftCarotidS] = useState(false);
  const [indexesRightCarotidS, setIndexesRightCarotidS] = useState(false);

  const leftRight = useSelector((state) => state.patientExamination.leftRightOBL);
  const { resource } = useSelector((state) => ({
    resource: state?.patientSnapshot?.resource,
  }));

  useEffect(() => {
    if (leftRight) {
      // check left
      if (leftRight?.left && leftRight?.n_choice) {
        let indexLeft = [];
        if (leftRight?.left[0] === true) {
          if (indexLeft.indexOf("Acute Limb Ischemia") === -1) {
            indexLeft = [...indexLeft, "Acute Limb Ischemia"];
          }
        }
        if (leftRight?.left[1] === true) {
          if (indexLeft.indexOf("Vein Graft Stenosis") === -1) {
            indexLeft = [...indexLeft, "Vein Graft Stenosis"];
          }
        }
        if (leftRight?.left[6] === true && leftRight?.left[7] === true && leftRight?.left[8] === true) {
          if (indexLeft.indexOf("Claudication") === -1) indexLeft = [...indexLeft, "Claudication"];
        }
        if (leftRight?.left[2] === true && leftRight?.n_choice && leftRight?.n_choice.indexOf("Claudication") > -1) {
          if (indexLeft.indexOf("In-Stent or Recurrent Disease with Claudication") === -1)
            indexLeft = [...indexLeft, "In-Stent or Recurrent Disease with Claudication"];
        }
        if (leftRight?.left[3] === true) {
          if (indexLeft.indexOf("In-stent or Recurrent Disease with Rest Pain/Wound/Gangrene") === -1)
            indexLeft = [...indexLeft, "In-stent or Recurrent Disease with Rest Pain/Wound/Gangrene"];
        }
        if (leftRight?.left[4] === true && leftRight?.left[5] === true && leftRight?.n_choice && leftRight?.n_choice.indexOf("Wound/Gangrene") > -1) {
          if (indexLeft.indexOf("Nonhealing Ulcer or Wound") === -1) indexLeft = [...indexLeft, "Nonhealing Ulcer or Wound"];
        }
        if (leftRight?.left[4] === true && leftRight?.left[5] === true && leftRight?.n_choice && leftRight?.n_choice.indexOf("Rest Pain") > -1) {
          if (indexLeft.indexOf("Rest Pain") === -1) indexLeft = [...indexLeft, "Rest Pain"];
        }
        setIndexesLeft([...indexLeft]);
      }
      // check right
      if (leftRight?.right && leftRight?.m_choice) {
        let indexRight = [];
        if (leftRight?.right[0] === true) {
          if (indexRight.indexOf("Acute Limb Ischemia") === -1) indexRight = [...indexRight, "Acute Limb Ischemia"];
        }
        if (leftRight?.right[1] === true) {
          if (indexRight.indexOf("Vein Graft Stenosis") === -1) indexRight = [...indexRight, "Vein Graft Stenosis"];
        }
        if (leftRight?.right[6] === true && leftRight?.right[7] === true && leftRight?.right[8] === true) {
          if (indexRight.indexOf("Claudication") === -1) indexRight = [...indexRight, "Claudication"];
        }
        if (leftRight?.right[2] === true && leftRight?.m_choice && leftRight?.m_choice.indexOf("Claudication") > -1) {
          if (indexRight.indexOf("In-Stent or Recurrent Disease with Claudication") === -1)
            indexRight = [...indexRight, "In-Stent or Recurrent Disease with Claudication"];
        }
        if (leftRight?.right[3] === true) {
          if (indexRight.indexOf("In-stent or Recurrent Disease with Rest Pain/Wound/Gangrene") === -1)
            indexRight = [...indexRight, "In-stent or Recurrent Disease with Rest Pain/Wound/Gangrene"];
        }
        if (
          leftRight?.right[4] === true &&
          leftRight?.right[5] === true &&
          leftRight?.m_choice &&
          leftRight?.m_choice.indexOf("Wound/Gangrene") > -1
        ) {
          if (indexRight.indexOf("Nonhealing Ulcer or Wound") === -1) indexRight = [...indexRight, "Nonhealing Ulcer or Wound"];
        }
        if (leftRight?.right[4] === true && leftRight?.right[5] === true && leftRight?.m_choice && leftRight?.m_choice.indexOf("Rest Pain") > -1) {
          if (indexRight.indexOf("Rest Pain") === -1) indexRight = [...indexRight, "Rest Pain"];
        }
        setIndexesRight([...indexRight]);
      }
    }
  }, [leftRight]);

  const checkIndexOf = (value, valueCheck) => {
    if (value && valueCheck) {
      return value.toLowerCase().indexOf(valueCheck.toLowerCase()) !== -1;
    }
    return false;
  };

  useEffect(() => {
    const objectRow = data.find((r) => r?.condition_name == "Peripheral Artery Disease (PAD)");
    if (objectRow !== null && typeof objectRow === "object" && objectRow) {
      const rowStruct = objectRow["questions"] || [];
      let stateCheck = {};
      // index plavix Aspirin
      const indexRowPA = rowStruct.findIndex((r) => r?.question == "Has the patient been on antiplatelet therapy for at least 12 weeks?");
      const indexRowAce = rowStruct.findIndex((r) => r?.question == "Has the patient been on ACE inhibitor or Angiotensin Receptor Blocker?");
      // index stain
      const indexRowS = rowStruct.findIndex((r) => r?.question == "Has the patient been on statin therapy for at least 12 weeks?");
      // index Benign Hypertension && Essential Hypertension // icd
      const indexRowEHBH = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have a diagnosis of hypertension which is being managed by PCP?"
      );
      // index Diabetes // icd
      const indexRowD = rowStruct.findIndex((r) => r?.question == "Does the patient have a diagnosis of diabetes which is being managed by PCP?");
      // index chantix && Wellbutrin
      const indexRowCW = rowStruct.findIndex((r) => r?.question == "Has the patient been on a smoking cessation plan for at least 12 weeks?");
      // index Cilostazol
      const indexRowC = rowStruct.findIndex((r) => r?.question == "Has the patient been on Cilostazol (Pletal) therapy for at least 12 weeks?");
      // medications
      if (resource?.medications && Array.isArray(resource?.medications)) {
        // plavix Aspirin
        if (
          resource?.medications.some(
            (r) => r?.full_medication && (checkIndexOf(r?.full_medication, "Plavix") || checkIndexOf(r?.full_medication, "Clopidogrel"))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowPA + 1}`]: [...(stateCheck[`quest_${indexRowPA + 1}`] || []), "Plavix"],
          };
        }
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Aspirin"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowPA + 1}`]: [...(stateCheck[`quest_${indexRowPA + 1}`] || []), "Aspirin"],
          };
        }
        // statin
        if (
          resource?.medications.some(
            (r) =>
              r?.full_medication &&
              (checkIndexOf(r?.full_medication, "statin") ||
                checkIndexOf(r?.full_medication, "lipitor") ||
                checkIndexOf(r?.full_medication, "crestor") ||
                checkIndexOf(r?.full_medication, "rosuvastatin") ||
                checkIndexOf(r?.full_medication, "Altocor") ||
                checkIndexOf(r?.full_medication, "Altoprev") ||
                checkIndexOf(r?.full_medication, "Baycol") ||
                checkIndexOf(r?.full_medication, "cerivastatin") ||
                checkIndexOf(r?.full_medication, "Crestor") ||
                checkIndexOf(r?.full_medication, "Ezallor") ||
                checkIndexOf(r?.full_medication, "FloLipid") ||
                checkIndexOf(r?.full_medication, "fluvastatin") ||
                checkIndexOf(r?.full_medication, "Lescol") ||
                checkIndexOf(r?.full_medication, "Livalo") ||
                checkIndexOf(r?.full_medication, "lovastatin") ||
                checkIndexOf(r?.full_medication, "Mevacor") ||
                checkIndexOf(r?.full_medication, "Nikita") ||
                checkIndexOf(r?.full_medication, "pitavastatin") ||
                checkIndexOf(r?.full_medication, "Pravachol") ||
                checkIndexOf(r?.full_medication, "pravastatin") ||
                checkIndexOf(r?.full_medication, "rosuvastatin") ||
                checkIndexOf(r?.full_medication, "simvastatin") ||
                checkIndexOf(r?.full_medication, "Zocor") ||
                checkIndexOf(r?.full_medication, "Zypitamag"))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowS + 1}`]: [...(stateCheck[`quest_${indexRowS + 1}`] || []), "Yes"],
          };
        }
        if (
          resource?.medications.some(
            (r) =>
              r?.full_medication &&
              (checkIndexOf(r?.full_medication, "Benazepril") ||
                checkIndexOf(r?.full_medication, "Captopril") ||
                checkIndexOf(r?.full_medication, "Enalapril") ||
                checkIndexOf(r?.full_medication, "Fosinopril") ||
                checkIndexOf(r?.full_medication, "Lisinopril") ||
                checkIndexOf(r?.full_medication, "Moexipril") ||
                checkIndexOf(r?.full_medication, "Perindopril") ||
                checkIndexOf(r?.full_medication, "Quinapril") ||
                checkIndexOf(r?.full_medication, "Ramipril") ||
                checkIndexOf(r?.full_medication, "Trandolapril") ||
                checkIndexOf(r?.full_medication, "Azilsartan") ||
                checkIndexOf(r?.full_medication, "Candesartan") ||
                checkIndexOf(r?.full_medication, "Irbesartan") ||
                checkIndexOf(r?.full_medication, "Losartan") ||
                checkIndexOf(r?.full_medication, "Olmesartan") ||
                checkIndexOf(r?.full_medication, "Telmisartan") ||
                checkIndexOf(r?.full_medication, "Valsartan") ||
                checkIndexOf(r?.full_medication, "Lotensin") ||
                checkIndexOf(r?.full_medication, "Vasotec") ||
                checkIndexOf(r?.full_medication, "Zestril") ||
                checkIndexOf(r?.full_medication, "Edarbi") ||
                checkIndexOf(r?.full_medication, "Atacand") ||
                checkIndexOf(r?.full_medication, "Cozaar") ||
                checkIndexOf(r?.full_medication, "Benicar") ||
                checkIndexOf(r?.full_medication, "Micardis") ||
                checkIndexOf(r?.full_medication, "Diovan"))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowAce + 1}`]: [...(stateCheck[`quest_${indexRowAce + 1}`] || []), "Yes"],
          };
        }
        // chantix && Wellbutrin
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Chantix"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowCW + 1}`]: [...(stateCheck[`quest_${indexRowCW + 1}`] || []), "Varenicline"],
          };
        }
        // none smoke
        if (resource?.smoking_ppd == 0) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowCW + 1}`]: [...(stateCheck[`quest_${indexRowCW + 1}`] || []), "Non-Smoker"],
          };
        }
        //
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Wellbutrin"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowCW + 1}`]: [...(stateCheck[`quest_${indexRowCW + 1}`] || []), "Bupropion"],
          };
        }
        // Cilostazol
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Cilostazol"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowC + 1}`]: [...(stateCheck[`quest_${indexRowC + 1}`] || []), "Yes"],
          };
        }
      }
      // previous medication history
      if (resource?.icd_codes && Array.isArray(resource?.icd_codes)) {
        // Benign Hypertension && Essential Hypertension
        if (
          resource?.icd_codes.some(
            (r) =>
              r?.disease?.icd_name &&
              ((checkIndexOf(r?.disease?.icd_name, "Benign") && checkIndexOf(r?.disease?.icd_name, "Hypertension")) ||
                (checkIndexOf(r?.disease?.icd_name, "Essential") && checkIndexOf(r?.disease?.icd_name, "Hypertension")))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowEHBH + 1}`]: [...(stateCheck[`quest_${indexRowEHBH + 1}`] || []), "Yes"],
          };
        } else {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowEHBH + 1}`]: [...(stateCheck[`quest_${indexRowEHBH + 1}`] || []), "No"],
          };
        }
        // Diabetes
        if (resource?.icd_codes.some((r) => r?.disease?.icd_name && checkIndexOf(r?.disease?.icd_name, "Diabetes"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowD + 1}`]: [...(stateCheck[`quest_${indexRowD + 1}`] || []), "Yes"],
          };
        } else {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowD + 1}`]: [...(stateCheck[`quest_${indexRowD + 1}`] || []), "No"],
          };
        }
      }

      setCheckHover2((statePrev) => ({
        ...statePrev,
        ...stateCheck,
      }));
    }
  }, [resource, data]);

  const makeDataChoice = (value, index) => {
    let checked_choiceMix = [];
    if (value && index > -1) {
      const checkedChoiceData = value["questions"][index]["checked_choice"] || "";
      if (!Array.isArray(checkedChoiceData)) {
        checked_choiceMix = checkedChoiceData.split(",");
      } else {
        checked_choiceMix = checkedChoiceData;
      }
    }
    const variance = value?.["questions"]?.[index]?.["variance"];
    if (variance) checked_choiceMix.push(variance);
    return checked_choiceMix;
  };

  useEffect(() => {
    var stateRed = {};
    const objectRow = data.find((r) => r?.condition_name == "Intermittent Claudication");
    const objectRow2 = data.find((r) => r?.condition_name == "Peripheral Artery Disease (PAD)");
    const objectRow3 = data.find((r) => r?.condition_name == "Rest Pain (CLI)" || r?.condition_name == "Rest Pain");
    const objectRow4 = data.find((r) => r?.condition_name == "Acute Limb Ischemia (ALI)" || r?.condition_name == "Acute Limb Ischemia");
    const objectRow5 = data.find((r) => r?.condition_name == "Wound / Gangrene (CLI)" || r?.condition_name == "Wound / Gangrene");
    const objectRow6 = data.find((r) => r?.condition_name == "In-Stent or Restenosis");
    // DK 1 DK2 Peripheral Artery Disease (PAD) Intermittent Claudication
    if (objectRow !== null && typeof objectRow === "object" && objectRow2 !== null && typeof objectRow2 === "object") {
      const rowStruct = objectRow["questions"] || [];
      const rowStruct2 = objectRow2["questions"] || [];
      // index Intermittent Claudication
      const indexRow1 = rowStruct.findIndex(
        (r) =>
          r?.question ==
          "Does the patient have fatigue, discomfort, cramping, or pain of vascular origin in the muscles of the lower extremities that is consistently induced by exercise and consistently relieved by rest (within 10 min)?"
      );
      const indexRow2 = rowStruct.findIndex((r) => r?.question == "The patients RIGHT Lower Extremity claudication symptoms are described?");
      const indexRow4 = rowStruct.findIndex((r) => r?.question == "The patients LEFT Lower Extremity claudication symptoms are described?");
      const indexRow3 = rowStruct.findIndex(
        (r) =>
          r?.question ==
          "Does the patients symptoms affect their ability to perform an occupation, impairs basic activities of daily living, mobility, provide care to a spouse or family member,  loss of the ability to engage in recreational activities, or other QoL issues?"
      );
      const DataChoice1 = makeDataChoice(objectRow, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow, indexRow3);
      const DataChoice4 = makeDataChoice(objectRow, indexRow4);
      // index Peripheral Artery Disease (PAD)
      const indexRowPAD1 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on antiplatelet therapy for at least 12 weeks?");
      const indexRowPAD2 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on statin therapy for at least 12 weeks?");
      const indexRowPAD3 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on a smoking cessation plan for at least 12 weeks?");
      const indexRowPAD4 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on an exercise plan for at least 12 weeks?");
      const indexRowPAD5 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on Cilostazol (Pletal) therapy for at least 12 weeks?");
      const DataChoicePAD1 = makeDataChoice(objectRow2, indexRowPAD1);
      const DataChoicePAD2 = makeDataChoice(objectRow2, indexRowPAD2);
      const DataChoicePAD3 = makeDataChoice(objectRow2, indexRowPAD3);
      const DataChoicePAD4 = makeDataChoice(objectRow2, indexRowPAD4);
      const DataChoicePAD5 = makeDataChoice(objectRow2, indexRowPAD5);
      // Right
      if (
        DataChoice1.indexOf("Right") > -1 &&
        DataChoice3.indexOf("Yes") > -1 &&
        (DataChoice2.indexOf("Moderate") > -1 || DataChoice2.indexOf("Severe") > -1)
      ) {
        if (
          (DataChoicePAD1.indexOf("Plavix") > -1 ||
            DataChoicePAD1.indexOf("Aspirin") > -1 ||
            DataChoicePAD1.indexOf("contraindication") > -1 ||
            DataChoicePAD1.indexOf("side_Effects") > -1 ||
            DataChoicePAD1.indexOf("cost") > -1 ||
            DataChoicePAD1.indexOf("refused") > -1) &&
          (DataChoicePAD2.indexOf("Yes") > -1 ||
            DataChoicePAD2.indexOf("Variance") > -1 ||
            DataChoicePAD2.indexOf("contraindication") > -1 ||
            DataChoicePAD2.indexOf("side_Effects") > -1 ||
            DataChoicePAD2.indexOf("cost") > -1 ||
            DataChoicePAD2.indexOf("refused") > -1) &&
          (DataChoicePAD3.indexOf("Varenicline") > -1 ||
            DataChoicePAD3.indexOf("Bupropion") > -1 ||
            DataChoicePAD3.indexOf("Nicotine Replacement") > -1 ||
            DataChoicePAD3.indexOf("Replacement") > -1 ||
            DataChoicePAD3.indexOf("Non-Smoker") > -1 ||
            DataChoicePAD3.indexOf("Cut Down") > -1) &&
          (DataChoicePAD4.indexOf("Supervised") > -1 ||
            DataChoicePAD4.indexOf("Structured Home") > -1 ||
            DataChoicePAD4.indexOf("Self-Directed") > -1 ||
            DataChoicePAD4.indexOf("Variance Health") > -1 ||
            DataChoicePAD4.indexOf("Variance Resources") > -1 ||
            DataChoicePAD4.indexOf("Variance Pain") > -1) &&
          (DataChoicePAD5.indexOf("Yes") > -1 ||
            DataChoicePAD5.indexOf("Variance") > -1 ||
            DataChoicePAD5.indexOf("contraindication") > -1 ||
            DataChoicePAD5.indexOf("side_Effects") > -1 ||
            DataChoicePAD5.indexOf("cost") > -1 ||
            DataChoicePAD5.indexOf("refused") > -1)
        ) {
          stateRed = {
            ...stateRed,
            "Intermittent Claudication": [
              ...(stateRed["Intermittent Claudication"] || []),
              `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
            ],
          };
        }
      }
      // Left
      if (
        DataChoice1.indexOf("Left") > -1 &&
        DataChoice3.indexOf("Yes") > -1 &&
        (DataChoice4.indexOf("Moderate") > -1 || DataChoice4.indexOf("Severe"))
      ) {
        if (
          (DataChoicePAD1.indexOf("Plavix") > -1 ||
            DataChoicePAD1.indexOf("Aspirin") > -1 ||
            DataChoicePAD1.indexOf("Variance") > -1 ||
            DataChoicePAD1.indexOf("contraindication") > -1 ||
            DataChoicePAD1.indexOf("side_Effects") > -1 ||
            DataChoicePAD1.indexOf("cost") > -1 ||
            DataChoicePAD1.indexOf("refused") > -1) &&
          (DataChoicePAD2.indexOf("Yes") > -1 ||
            DataChoicePAD2.indexOf("Variance") > -1 ||
            DataChoicePAD2.indexOf("contraindication") > -1 ||
            DataChoicePAD2.indexOf("side_Effects") > -1 ||
            DataChoicePAD2.indexOf("cost") > -1 ||
            DataChoicePAD2.indexOf("refused") > -1) &&
          (DataChoicePAD3.indexOf("Varenicline") > -1 ||
            DataChoicePAD3.indexOf("Bupropion") > -1 ||
            DataChoicePAD3.indexOf("Nicotine Replacement") > -1 ||
            DataChoicePAD3.indexOf("Replacement") > -1 ||
            DataChoicePAD3.indexOf("Non-Smoker") > -1 ||
            DataChoicePAD3.indexOf("Cut Down") > -1) &&
          (DataChoicePAD4.indexOf("Supervised") > -1 ||
            DataChoicePAD4.indexOf("Structured Home") > -1 ||
            DataChoicePAD4.indexOf("Self-Directed") > -1 ||
            DataChoicePAD4.indexOf("Variance Health") > -1 ||
            DataChoicePAD4.indexOf("Variance Resources") > -1 ||
            DataChoicePAD4.indexOf("Variance Pain") > -1) &&
          (DataChoicePAD5.indexOf("Yes") > -1 ||
            DataChoicePAD5.indexOf("Variance") > -1 ||
            DataChoicePAD5.indexOf("contraindication") > -1 ||
            DataChoicePAD5.indexOf("side_Effects") > -1 ||
            DataChoicePAD5.indexOf("cost") > -1 ||
            DataChoicePAD5.indexOf("refused") > -1)
        ) {
          stateRed = {
            ...stateRed,
            "Intermittent Claudication": [
              ...(stateRed["Intermittent Claudication"] || []),
              `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
            ],
          };
        }
      }
    }
    // DK 3 Rest Pain
    if (objectRow3 !== null && typeof objectRow3 === "object") {
      const rowStruct = objectRow3["questions"] || [];
      const indexRow1 = rowStruct.findIndex((r) => r?.question == "Does the patient have ischemic rest pain (≥ 2 weeks)?");
      const indexRow2 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the RIGHT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const indexRow3 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the LEFT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const DataChoice1 = makeDataChoice(objectRow3, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow3, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow3, indexRow3);
      // Right
      if (DataChoice1.indexOf("Right") > -1 && DataChoice2.indexOf("Yes") > -1) {
        stateRed = {
          ...stateRed,
          "Rest Pain (CLI)": [...(stateRed["Rest Pain (CLI)"] || []), `Medical necessity criteria met for Lower Extremity Angiography (Right)`],
        };
      }
      // Left
      if (DataChoice1.indexOf("Left") > -1 && DataChoice3.indexOf("Yes") > -1) {
        stateRed = {
          ...stateRed,
          "Rest Pain (CLI)": [...(stateRed["Rest Pain (CLI)"] || []), `Medical necessity criteria met for Lower Extremity Angiography (Left)`],
        };
      }
    }
    // DK 4 Acute Limb Ischemia
    if (objectRow4 !== null && typeof objectRow4 === "object") {
      const rowStruct = objectRow4["questions"] || [];
      const indexRow1 = rowStruct.findIndex(
        (r) =>
          r?.question ==
          "Does the patient have ALI as defined as acute (< 2 weeks), severe hypoperfusion of the limb characterized by: pain, pallor, pulselessness, poikilothermia (cold), paresthesias, and paralysis?"
      );
      const indexRow2 = rowStruct.findIndex((r) => r?.question == "The patients RIGHT Lower Extremity ALI  symptoms are categorized?");
      const indexRow3 = rowStruct.findIndex((r) => r?.question == "The patients LEFT Lower Extremity ALI  symptoms are categorized?");
      const DataChoice1 = makeDataChoice(objectRow4, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow4, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow4, indexRow3);
      // Right
      if (DataChoice1.indexOf("Right") > -1 && (DataChoice2.indexOf("Viable") > -1 || DataChoice2.indexOf("Threatened") > -1)) {
        stateRed = {
          ...stateRed,
          "Acute Limb Ischemia (ALI)": [
            ...(stateRed["Acute Limb Ischemia (ALI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
          ],
        };
      }
      //Left
      if (DataChoice1.indexOf("Left") > -1 && (DataChoice3.indexOf("Viable") > -1 || DataChoice3.indexOf("Threatened") > -1)) {
        stateRed = {
          ...stateRed,
          "Acute Limb Ischemia (ALI)": [
            ...(stateRed["Acute Limb Ischemia (ALI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
          ],
        };
      }
    }
    // DK 5 Wound / Gangrene (CLI)
    if (objectRow5 !== null && typeof objectRow5 === "object") {
      const rowStruct = objectRow5["questions"] || [];
      const indexRow1 = rowStruct.findIndex(
        (r) => r?.question == "The patients RIGHT Lower Extremity wound  has been present ≥ 2 weeks despite local wound care and is located?"
      );
      const indexRow2 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the RIGHT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const indexRow3 = rowStruct.findIndex(
        (r) => r?.question == "The patients LEFT Lower Extremity wound  has been present ≥ 2 weeks despite local wound care and is located?"
      );
      const indexRow4 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the LEFT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const DataChoice1 = makeDataChoice(objectRow5, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow5, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow5, indexRow3);
      const DataChoice4 = makeDataChoice(objectRow5, indexRow4);
      // Right
      if (
        (DataChoice1.indexOf("Leg") > -1 ||
          DataChoice1.indexOf("Foot") > -1 ||
          DataChoice1.indexOf("Digit") > -1 ||
          DataChoice1.indexOf("Stump") > -1) &&
        DataChoice2.indexOf("Yes") > -1
      ) {
        stateRed = {
          ...stateRed,
          "Wound / Gangrene (CLI)": [
            ...(stateRed["Wound / Gangrene (CLI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
          ],
        };
      }
      //Left
      if (
        (DataChoice3.indexOf("Leg") > -1 ||
          DataChoice3.indexOf("Foot") > -1 ||
          DataChoice3.indexOf("Digit") > -1 ||
          DataChoice3.indexOf("Stump") > -1) &&
        DataChoice4.indexOf("Yes") > -1
      ) {
        stateRed = {
          ...stateRed,
          "Wound / Gangrene (CLI)": [
            ...(stateRed["Wound / Gangrene (CLI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
          ],
        };
      }
    }
    // DK 6 In-Stent or Restenosis
    if (objectRow6 !== null && typeof objectRow6 === "object") {
      const rowStruct = objectRow6["questions"] || [];
      const indexRow1 = rowStruct.findIndex((r) => r?.question == "Has the patient had previous endovascular procedure(s)?");
      const indexRow2 = rowStruct.findIndex((r) => r?.question == "Has the patient had any previous open surgical bypass procedure(s)?");
      const indexRow3 = rowStruct.findIndex(
        (r) =>
          r?.question == "Does the risk of non-treatment pose potential harm in the setting of duplex US evidence of hemodynamic significant lesions?"
      );
      const DataChoice1 = makeDataChoice(objectRow6, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow6, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow6, indexRow3);
      // Right
      if ((DataChoice1.indexOf("Right") > -1 || DataChoice2.indexOf("Right") > -1) && DataChoice3.indexOf("Right") > -1) {
        stateRed = {
          ...stateRed,
          "In-Stent or Restenosis": [
            ...(stateRed["In-Stent or Restenosis"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
          ],
        };
      }
      //Left
      if ((DataChoice1.indexOf("Left") > -1 || DataChoice2.indexOf("Left") > -1) && DataChoice3.indexOf("Left") > -1) {
        stateRed = {
          ...stateRed,
          "In-Stent or Restenosis": [
            ...(stateRed["In-Stent or Restenosis"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
          ],
        };
      }
    }
    setStateShowRed(stateRed);
  }, [data]);

  useEffect(() => {
    if (indexesLeft.length === 0) {
      let stateCheck = {};
      if (leftRight && leftRight.left && leftRight?.n_choice) {
        if (leftRight.left.length > 0) {
          /*
          If we have claudication then it's sure it's question 7+8+9
          then we dont need to show other options
           */
          if (leftRight?.n_choice.indexOf("Claudication") > -1 || leftRight?.n_choice.indexOf("Lifestyle Limiting") > -1) {
            /*
            End case that only question 9 is true
           */
            /*
            Case question 7 is true
            question 8 is false
            question 9 is false
             */
            if (!leftRight?.left[7]) {
              if (
                leftRight?.question_8 &&
                leftRight?.question_8?.left &&
                leftRight?.question_8?.left[0] === true &&
                leftRight?.question_8?.left[1] === false &&
                leftRight?.question_8?.left[2] === true
              ) {
                stateCheck = {
                  ...stateCheck,
                  ["quest_2"]: ["Claudication"],
                };
              }

              if (
                leftRight?.question_8 &&
                leftRight?.question_8?.left &&
                leftRight?.question_8?.left[0] === false &&
                leftRight?.question_8?.left[1] === true &&
                leftRight?.question_8?.left[2] === true
              ) {
                stateCheck = {
                  ...stateCheck,
                  ["quest_2"]: ["Lifestyle Limiting"],
                };
              }
            }
            //if claudication and lifestyle limiting are missing then possible is q6 is true
          } else {
            if (leftRight?.left[6] && !leftRight?.left[7] && !leftRight?.left[8]) {
              if (
                leftRight?.question_8 &&
                leftRight?.question_8?.left &&
                leftRight?.question_8?.left[0] === false &&
                leftRight?.question_8?.left[1] === false &&
                leftRight?.question_8?.left[2] === true
              ) {
                stateCheck = {
                  ...stateCheck,
                  ["quest_2"]: [...(stateCheck["quest_2"] || []), "Lifestyle Limiting", "Claudication"],
                };
              }
            } else if (leftRight?.left[6] && !leftRight?.left[7] && leftRight?.left[8]) {
              if (
                leftRight?.question_8 &&
                leftRight?.question_8?.left &&
                leftRight?.question_8?.left[0] === false &&
                leftRight?.question_8?.left[1] === false &&
                leftRight?.question_8?.left[2] === true
              ) {
                stateCheck = {
                  ...stateCheck,
                  ["quest_2"]: [...(stateCheck["quest_2"] || []), "Lifestyle Limiting", "Claudication"],
                };
              }
            }

            if (
              leftRight?.question_1 &&
              leftRight?.question_1?.left &&
              (leftRight?.question_1?.left[0] == true || leftRight?.question_1?.left[1] == true) &&
              leftRight?.question_1?.left[2] == false
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_2"]: [...(stateCheck["quest_2"] || []), "Sudden Pain", "Numbness", "Weakness", "Discoloration", "Coldness"],
              };
            }
            if (
              leftRight?.question_3 &&
              leftRight?.question_3?.left &&
              leftRight?.question_3?.left[0] == false &&
              leftRight?.question_3?.left[1] == true &&
              leftRight?.question_3?.left[2] == true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_2"]: [...(stateCheck["quest_2"] || []), "Claudication"],
              };
            }
            if (
              leftRight?.question_4 &&
              leftRight?.question_4?.left &&
              leftRight?.question_4?.left[0] == false &&
              leftRight?.question_4?.left[1] == true &&
              leftRight?.question_4?.left[2] == true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_2"]: [...(stateCheck["quest_2"] || []), "Rest Pain", "Wound", "Gangrene"],
              };
            }
            if (
              leftRight?.question_5_6_r &&
              leftRight?.question_5_6_r?.left &&
              leftRight?.question_5_6_r?.left[0] == false &&
              leftRight?.question_5_6_r?.left[1] == true &&
              leftRight?.question_5_6_r?.left[2] == true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_2"]: [...(stateCheck["quest_2"] || []), "Rest Pain"],
              };
            }
            if (
              leftRight?.question_5_6_w &&
              leftRight?.question_5_6_w?.left &&
              leftRight?.question_5_6_w?.left[0] == false &&
              leftRight?.question_5_6_w?.left[1] == true &&
              leftRight?.question_5_6_w?.left[2] == true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_2"]: [...(stateCheck["quest_2"] || []), "Wound", "Gangrene"],
              };
            }
          }
        }
      }
      setCheckHover((statePrev) => ({
        ...statePrev,
        ...stateCheck,
      }));
    } else {
      let stateCheck = {};
      stateCheck = {
        ...stateCheck,
        ["quest_2"]: [],
      };
      setCheckHover((statePrev) => ({
        ...statePrev,
        ...stateCheck,
      }));
    }
  }, [indexesLeft, leftRight]);

  useEffect(() => {
    if (indexesRight.length === 0) {
      let stateCheck = { quest_1: [] };
      if (leftRight && leftRight.right && leftRight?.m_choice) {
        if (leftRight.right.length > 0) {
          if (leftRight?.m_choice.indexOf("Claudication") > -1 || leftRight?.m_choice.indexOf("Lifestyle Limiting") > -1) {
            if (!leftRight?.right[7]) {
              if (
                leftRight?.question_8 &&
                leftRight?.question_8?.right &&
                leftRight?.question_8?.right[0] === true &&
                leftRight?.question_8?.right[1] === false &&
                leftRight?.question_8?.right[2] === true
              ) {
                stateCheck = {
                  ...stateCheck,
                  ["quest_1"]: ["Claudication"],
                };
              }

              if (
                leftRight?.question_8 &&
                leftRight?.question_8?.right &&
                leftRight?.question_8?.right[0] === false &&
                leftRight?.question_8?.right[1] === true &&
                leftRight?.question_8?.right[2] === true
              ) {
                stateCheck = {
                  ...stateCheck,
                  ["quest_1"]: ["Lifestyle Limiting"],
                };
              }
            }
          } else {
            //if claudication and lifestyle limiting are missing
            if (leftRight?.right[6] && !leftRight?.right[7] && !leftRight?.right[8]) {
              if (
                leftRight?.question_8 &&
                leftRight?.question_8?.right &&
                leftRight?.question_8?.right[0] === false &&
                leftRight?.question_8?.right[1] === false &&
                leftRight?.question_8?.right[2] === true
              ) {
                stateCheck = {
                  ...stateCheck,
                  ["quest_1"]: [...(stateCheck["quest_1"] || []), "Lifestyle Limiting", "Claudication"],
                };
              }
            }

            if (
              leftRight?.question_1 &&
              leftRight?.question_1?.right &&
              (leftRight?.question_1?.right[0] == true || leftRight?.question_1?.right[1] == true) &&
              leftRight?.question_1?.right[2] == false
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_1"]: [...(stateCheck["quest_1"] || []), "Sudden Pain", "Numbness", "Weakness", "Discoloration", "Coldness"],
              };
              // eslint-disable-next-line no-fallthrough
            }
            if (
              leftRight?.question_3 &&
              leftRight?.question_3?.right &&
              leftRight?.question_3?.right[0] === false &&
              leftRight?.question_3?.right[1] === true &&
              leftRight?.question_3?.right[2] === true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_1"]: [...(stateCheck["quest_1"] || []), "Claudication"],
              };
              // eslint-disable-next-line no-fallthrough
            }
            if (
              leftRight?.question_4 &&
              leftRight?.question_4?.right &&
              leftRight?.question_4?.right[0] == false &&
              leftRight?.question_4?.right[1] == true &&
              leftRight?.question_4?.right[2] == true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_1"]: [...(stateCheck["quest_1"] || []), "Rest Pain", "Wound", "Gangrene"],
              };
            }
            if (
              leftRight?.question_5_6_r &&
              leftRight?.question_5_6_r?.right &&
              leftRight?.question_5_6_r?.right[0] == false &&
              leftRight?.question_5_6_r?.right[1] == true &&
              leftRight?.question_5_6_r?.right[2] == true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_1"]: [...(stateCheck["quest_1"] || []), "Rest Pain"],
              };
            }
            if (
              leftRight?.question_5_6_w &&
              leftRight?.question_5_6_w?.right &&
              leftRight?.question_5_6_w?.right[0] == false &&
              leftRight?.question_5_6_w?.right[1] == true &&
              leftRight?.question_5_6_w?.right[2] == true
            ) {
              stateCheck = {
                ...stateCheck,
                ["quest_1"]: [...(stateCheck["quest_1"] || []), "Wound", "Gangrene"],
              };
            }
          }
        } //here
      }
      setCheckHover((statePrev) => ({
        ...statePrev,
        ...stateCheck,
      }));
    } else {
      let stateCheck = {};
      stateCheck = {
        ...stateCheck,
        ["quest_1"]: [],
      };
      setCheckHover((statePrev) => ({
        ...statePrev,
        ...stateCheck,
      }));
    }
  }, [indexesRight, leftRight]);

  useEffect(() => {
    // check carotid
    if (leftRight && data && data.length > 0) {
      // check left
      if (leftRight?.left_stenosis == 50) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition_name === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid?.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesLeftCarotidS(true);
        } else {
          setIndexesLeftCarotidS(false);
        }
      } else if (leftRight?.left_stenosis == 70) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition_name === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid?.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesLeftCarotidS(true);
          setIndexesLeftCarotidA(false);
        } else {
          setIndexesLeftCarotidS(false);
          setIndexesLeftCarotidA(true);
        }
      }
      // check right
      if (leftRight?.right_stenosis == 50) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition_name === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesRightCarotidS(true);
        } else {
          setIndexesRightCarotidS(false);
        }
      } else if (leftRight?.right_stenosis == 70) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition_name === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesRightCarotidS(true);
          setIndexesRightCarotidA(false);
        } else {
          setIndexesRightCarotidS(false);
          setIndexesRightCarotidA(true);
        }
      }
    }
  }, [leftRight, data]);

  useEffect(() => {
    if (intra) {
      const fixArr = ([...intra] || []).map((r) => {
        return {
          ...r,
          enable_icd: r?.enable_icd || 0,
          questions: (r?.questions || []).map((q) => {
            return {
              ...q,
              comments: ReplaceN(q?.comments),
            };
          }),
        };
      });
      setData(fixArr);
    } else {
      setData([]);
    }
  }, [intra]);

  useEffect(() => {
    dispatch(load_intra_operative(id_apoinment));
  }, []);

  const handleSearch = (search) => {
    setSearch(search);
    const array_filter_search = (referralConditions || []).filter((r) => r["value"] && r["value"].toLowerCase().includes(search.toLowerCase()));
    setFilteredOptions([...array_filter_search]);
  };

  const onSelectOption = async (option) => {
    setSearch(option["value"]);
    let params = {};
    const newCondition = await dispatch(HPIGetConditions(id_apoinment));
    if (newCondition && newCondition?.allConditions) {
      const fill = (newCondition.allConditions || []).find((r) => r?.condition == option["value"] || r?.condition_name == option["value"]);
      if (fill && option) {
        params = {
          ...option,
          ...fill,
        };
        setShowOptions(false);
        setSelectedOption(params);
      } else {
        setShowOptions(false);
        setSelectedOption(undefined);
      }
    }
  };

  const onAddCondition = async () => {
    if (!selectedOption) return;
    const data_ = [...data];
    const temp_obj = {
      condition_id: selectedOption?.id,
      condition_name: selectedOption?.value,
      questions: selectedOption.questions,
    };
    if (selectedOption.id === 2) {
      setMasterAaa(true);
    }
    data_.push(temp_obj);
    setSearch("");
    setSelectedOption(undefined);
    setData(data_);
    updateData(data_);
  };

  const updateDataDeleteCheck = (data) => {
    const indexCheck = data.findIndex((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
    if (indexCheck == -1) {
      const fill = data.filter(
        (r) =>
          r?.condition_name !== "Acute Limb Ischemia (ALI)" &&
          r?.condition_name !== "Intermittent Claudication" &&
          r?.condition_name !== "Rest Pain (CLI)" &&
          r?.condition_name !== "In-Stent or Restenosis" &&
          r?.condition_name !== "Wound / Gangrene (CLI)"
      );
      setData([...fill]);
      updateData([...fill]);
    } else {
      setData(data);
      updateData(data);
    }
  };

  const onRemoveCondition = (conditionIndex) => {
    if (conditionIndex !== isDelete) {
      return setIsDelete(conditionIndex);
    }
    if (conditionIndex == 0) {
      const data_ = [...data];
      data_template_review.splice(conditionIndex, 1);
      dispatch(set_data_template_review([...data_template_review]));
      data_.splice(conditionIndex, 1);
      clearTimeout(timer);
      updateDataDeleteCheck(data_);
    } else {
      const data_ = [...data];
      data_.splice(conditionIndex, 1);
      clearTimeout(timer);
      data_template_review.splice(conditionIndex, 1);
      dispatch(set_data_template_review([...data_template_review]));
      updateDataDeleteCheck(data_);
    }
    setIsDelete(-1);
  };

  const onChangeComments = (conditionIndex, questionIndex, comments) => {
    const data_ = [...data];
    data_[conditionIndex]["questions"][questionIndex]["comments"] = comments;
    updateDataDeleteCheck(data_);
  };

  const importCondition = (value, dataState, valueRight, valueLeft) => {
    if (!(dataState || []).some((r) => r?.condition_name === value || r?.condition === value)) {
      const detailCondition = (options || []).find((row) => row?.condition === value || row?.condition_name === value);
      if (detailCondition && (detailCondition?.id || detailCondition?.condition_id)) {
        // eslint-disable-next-line default-case
        switch (value) {
          case "Acute Limb Ischemia (ALI)":
            if (valueRight && valueRight.indexOf("Sudden Pain") > -1) {
              detailCondition["questions"][0]["checked_choice"] = ["Right"];
            }
            if (valueLeft && valueLeft.indexOf("Sudden Pain") > -1) {
              detailCondition["questions"][0]["checked_choice"] = ["Left"];
            }
          case "Rest Pain (CLI)":
            if (valueRight && valueRight.indexOf("Rest Pain") > -1) {
              detailCondition["questions"][0]["checked_choice"] = ["Right"];
            }
            if (valueLeft && valueLeft.indexOf("Rest Pain") > -1) {
              detailCondition["questions"][0]["checked_choice"] = ["Left"];
            }
            break;
          case "Intermittent Claudication":
            if (valueRight && valueRight.indexOf("Claudication") > -1) {
              detailCondition["questions"][0]["checked_choice"] = ["Right"];
            }
            if (valueLeft && valueLeft.indexOf("Claudication") > -1) {
              detailCondition["questions"][0]["checked_choice"] = ["Left"];
            }
            break;
        }
        dataState.push({
          ...detailCondition,
          condition_name: detailCondition?.condition,
          enable_icd: 0,
          condition_id: detailCondition?.id,
        });
        setData([...dataState]);
        updateData([...dataState]);
      }
    } else {
      const dataMix = [...dataState];
      const indexData = (dataState || []).findIndex((row) => row?.condition === value || row?.condition_name === value);
      if (indexData != -1) {
        // eslint-disable-next-line default-case
        switch (value) {
          case "Acute Limb Ischemia (ALI)":
            if (valueRight && valueRight.indexOf("Sudden Pain") > -1 && valueLeft && valueLeft.indexOf("Sudden Pain") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left", "Right"];
            } else if (valueRight && valueRight.indexOf("Sudden Pain") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Right"];
            } else if (valueLeft && valueLeft.indexOf("Sudden Pain") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left"];
            }
          // eslint-disable-next-line no-fallthrough
          case "Rest Pain (CLI)":
            if (valueRight && valueRight.indexOf("Rest Pain") > -1 && valueLeft && valueLeft.indexOf("Rest Pain") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left", "Right"];
            } else if (valueRight && valueRight.indexOf("Rest Pain") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Right"];
            } else if (valueLeft && valueLeft.indexOf("Rest Pain") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left"];
            }
            break;
          case "Intermittent Claudication":
            if (valueRight && valueRight.indexOf("Claudication") > -1 && valueLeft && valueLeft.indexOf("Claudication") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left", "Right"];
            } else if (valueRight && valueRight.indexOf("Claudication") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Right"];
            } else if (valueLeft && valueLeft.indexOf("Claudication") > -1) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left"];
            }
            break;
        }
        setData([...dataMix]);
        updateData([...dataMix]);
      } else {
        setData([...dataState]);
        updateData([...dataState]);
      }
    }
  };

  const removeCondition = (value, dataState) => {
    if (value === "Abdominal Aortic Aneurysm (AAA) Disease" && masterAaa) return false;
    const indexData = dataState.findIndex((r) => r?.condition_name === value);
    if (indexData !== -1) {
      dataState.splice(indexData, 1);
      setData([...dataState]);
      updateData([...dataState]);
    }
  };

  const checkAAA = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
    const index9 = mixArr["questions"].findIndex((r) => r?.question == "Has the patient been on statin therapy for at least 12 weeks?");
    if (mixArr && index9 != -1 && mixArr["questions"] && mixArr["questions"][index9]) {
      if (mixArr["questions"][index9] && mixArr["questions"][index9]["checked_choice"] && mixArr["questions"][index9]["checked_choice"] === "No") {
        importCondition("Abdominal Aortic Aneurysm (AAA) Disease", dataState);
      }
    }
  };

  const checkAddAAA = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
    const index0 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
    const index1 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
    if (mixArr && index0 != -1 && index1 != -1 && mixArr["questions"] && mixArr["questions"][index0] && mixArr["questions"][index1]) {
      if (
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Wound/Gangrene") > -1) ||
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Rest Pain") > -1) ||
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Sudden Pain") > -1) ||
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Claudication") > -1) ||
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Restenosis") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Wound/Gangrene") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Rest Pain") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Sudden Pain") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Claudication") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Restenosis") > -1)
      ) {
        importCondition("Abdominal Aortic Aneurysm (AAA) Disease", dataState);
      }
    }
  };

  const checkRemoveAAA = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
    const index0 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
    const index1 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
    if (mixArr && index0 != -1 && index1 != -1 && mixArr["questions"] && mixArr["questions"][index0] && mixArr["questions"][index1]) {
      if (
        mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Wound/Gangrene") === -1 &&
        mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Rest Pain") === -1 &&
        mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Sudden Pain") === -1 &&
        mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Claudication") === -1 &&
        mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Restenosis") === -1 &&
        mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Wound/Gangrene") === -1 &&
        mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Rest Pain") === -1 &&
        mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Sudden Pain") === -1 &&
        mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Claudication") === -1 &&
        mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Restenosis") === -1
      ) {
        removeCondition("Abdominal Aortic Aneurysm (AAA) Disease", dataState);
      }
    }
  };

  const checkCondition = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
    const index0 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
    const index1 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
    if (mixArr && index0 != -1 && index1 != -1 && mixArr["questions"] && mixArr["questions"][index0] && mixArr["questions"][index1]) {
      if (
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Wound/Gangrene") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Wound/Gangrene") > -1)
      ) {
        checkAAA(dataState);
        importCondition("Wound / Gangrene (CLI)", dataState, null, null);
      } else {
        checkRemoveAAA(dataState);
        removeCondition("Wound / Gangrene (CLI)", dataState);
      }
      //
      if (
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Sudden Pain") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Sudden Pain") > -1)
      ) {
        checkAAA(dataState);
        importCondition(
          "Acute Limb Ischemia (ALI)",
          dataState,
          mixArr?.["questions"]?.[index0]?.["checked_choice"],
          mixArr?.["questions"]?.[index1]?.["checked_choice"]
        );
      } else {
        checkRemoveAAA(dataState);
        removeCondition("Acute Limb Ischemia (ALI)", dataState);
      }
      //
      if (
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Restenosis") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Restenosis") > -1)
      ) {
        checkAAA(dataState);
        importCondition("In-Stent or Restenosis", dataState, null, null);
      } else {
        checkRemoveAAA(dataState);
        removeCondition("In-Stent or Restenosis", dataState);
      }
      //
      if (
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Rest Pain") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Rest Pain") > -1)
      ) {
        checkAAA(dataState);
        importCondition(
          "Rest Pain (CLI)",
          dataState,
          mixArr?.["questions"]?.[index0]?.["checked_choice"],
          mixArr?.["questions"]?.[index1]?.["checked_choice"]
        );
      } else {
        checkRemoveAAA(dataState);
        removeCondition("Rest Pain (CLI)", dataState);
      }
      //
      if (
        (mixArr["questions"][index0] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index0]?.["checked_choice"].indexOf("Claudication") > -1) ||
        (mixArr["questions"][index1] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"] &&
          mixArr?.["questions"]?.[index1]?.["checked_choice"].indexOf("Claudication") > -1)
      ) {
        checkAAA(dataState);
        importCondition(
          "Intermittent Claudication",
          dataState,
          mixArr?.["questions"]?.[index0]?.["checked_choice"],
          mixArr?.["questions"]?.[index1]?.["checked_choice"]
        );
      } else {
        checkRemoveAAA(dataState);
        removeCondition("Intermittent Claudication", dataState);
      }
    }
  };

  const onSelectChoice = (conditionIndex, questionIndex, checked_choice, type) => {
    let data_ = [...data];
    if (!type) {
      if (data_[conditionIndex]["questions"][questionIndex]["checked_choice"] != checked_choice) {
        data_[conditionIndex]["questions"][questionIndex]["checked_choice"] = checked_choice;
        data_[conditionIndex]["questions"][questionIndex]["variance"] = "";
      } else if (data_[conditionIndex]["questions"][questionIndex]["checked_choice"] == checked_choice) {
        data_[conditionIndex]["questions"][questionIndex]["checked_choice"] = "";
      }
      if (data_[conditionIndex]["condition_name"] == "Peripheral Artery Disease (PAD)") {
        if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
        ) {
          const mixArr = (data_ || []).find((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
          const index0 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
          const index1 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
          if (mixArr && mixArr["questions"] && mixArr["questions"][index0] && mixArr["questions"][index1]) {
            if (checked_choice == "No") {
              checkAddAAA(data_);
            } else {
              setData([...data_]);
              updateData([...data_]);
              removeCondition("Abdominal Aortic Aneurysm (AAA) Disease", data_);
            }
          }
        } else if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from RIGHT Lower Extremity symptoms?" ||
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from LEFT Lower Extremity symptoms?"
        ) {
          checkCondition(data_);
        } else {
          setData([...data_]);
          updateData([...data_]);
        }
      } else {
        setData([...data_]);
        updateData([...data_]);
      }
    } else {
      data_[conditionIndex]["questions"][questionIndex]["checked_choice"] = checked_choice;
      data_[conditionIndex]["questions"][questionIndex]["variance"] = "";
      if (data_[conditionIndex]["condition_name"] == "Peripheral Artery Disease (PAD)") {
        if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
        ) {
          const mixArr = (data_ || []).find((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
          const index0 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
          const index1 = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
          if (mixArr && mixArr["questions"] && mixArr["questions"][index0] && mixArr["questions"][index1]) {
            if (checked_choice == "No") {
              checkAddAAA(data_);
            } else {
              removeCondition("Abdominal Aortic Aneurysm (AAA) Disease", data_);
            }
          }
        } else if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from RIGHT Lower Extremity symptoms?" ||
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from LEFT Lower Extremity symptoms?"
        ) {
          checkCondition(data_);
        } else {
          setData([...data_]);
          updateData([...data_]);
        }
      } else {
        setData([...data_]);
        updateData([...data_]);
      }
    }
  };

  const checkAction = async () => {
    if (selectedOption && selectedOption?.id) {
      const value = await dispatch(detail_angio_type(selectedOption?.id));
      await dispatch(set_data_template_review([...data_template_review, value]));
    }
  };

  const PanelToolbar = ({ index, row }) => (
    <div className="atuoAccordion__result-item-close_btn" style={{ position: "absolute", right: "16px", width: 200 }}>
      <div
        className="HPIItem"
        style={{
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div className="HPIItem__label">Enable ICD</div>
        <div
          id={`enable_icd_${index}`}
          onClick={(e) => {
            e.stopPropagation();
            handleEnableICD(!row?.enable_icd, index);
          }}
        >
          <Checkbox disabled={is_locked} checked={row?.enable_icd}></Checkbox>
        </div>

        <DeleteIconButton
          handleClick={(e) => {
            e.stopPropagation();
            onRemoveCondition(index);
          }}
          selected={isDelete === index}
          icon={"can"}
          extraStyle={{
            display: "inline-block",
            right: "0.75rem",
            marginLeft: 20,
            marginRight: 20,
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );

  const handleEnableICD = async (status, index) => {
    const dataMock = [...data];
    if (dataMock[index]) {
      dataMock[index]["enable_icd"] = status ? 1 : 0;
      setData([...dataMock]);
      updateData([...dataMock]);
      await dispatch(
        save_intra_operative(id_apoinment, {
          patient_id: id_patient,
          conditions: [...dataMock],
        })
      );
    }
  };

  const handleVariAPI = useCallback(
    (value, index, question, indexKey) => {
      const dataCheck = [...data];
      if (dataCheck?.[index]?.["questions"]?.[indexKey] && Object.keys(dataCheck[index]["questions"][indexKey]).length > 0) {
        dataCheck[index]["questions"][indexKey]["checked_choice"] = "";
        dataCheck[index]["questions"][indexKey] = {
          ...dataCheck[index]["questions"][indexKey],
          variance: value,
        };
        setData([...dataCheck]);
        updateData([...dataCheck]);
      }
    },
    [data]
  );

  const handleVariExtraApi = useCallback(
    (value, index, question, indexKey) => {
      const dataCheck = [...data];
      if (dataCheck?.[index]?.["questions"]?.[indexKey] && Object.keys(dataCheck[index]["questions"][indexKey]).length > 0) {
        dataCheck[index]["questions"][indexKey]["checked_choice"] = "";
        dataCheck[index]["questions"][indexKey] = {
          ...dataCheck[index]["questions"][indexKey],
          variance_extra: value,
        };
        setData([...dataCheck]);
        updateData([...dataCheck]);
      }
    },
    [data]
  );

  return (
    <div className="atuoAccordion">
      <div className="atuoAccordion__filter">
        <div className="atuoAccordion__filter-search white">
          <SearchInput searchInput={searchInput} setSearch={handleSearch} setShowOptions={setShowOptions} disabled={is_locked} />
        </div>
        <div
          style={{
            height: "39px",
          }}
          className={`atuoComplete__filter-action ${is_locked === true || is_locked === 1 ? "disabled" : ""}`}
        >
          <button
            className="atuoAccordion__filter-add"
            style={{
              backgroundColor: is_locked === true ? "#c6c6c6" : "#005ef6",
            }}
            onClick={() => {
              onAddCondition();
              checkAction();
            }}
            disabled={is_locked}
          >
            <span>Add </span>
            <span>+</span>
          </button>
        </div>
        {showOptions && searchInput && (
          <div className="atuoAccordion__filter-result">
            <div className="atuoAccordion__filter-result-list">
              <ScrollWrapper css="no-padding">
                {!!filteredOptions.length &&
                  filteredOptions.map((row, index) => (
                    <div className="atuoComplete__result-item" key={`filter-option-${index}-${row?.name}`} onClick={() => onSelectOption(row)}>
                      <p>{row["value"]}</p>
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
      <div className="atuoAccordion__result">
        <div className="atuoAccordion__result-list">
          <Collapse accordion>
            {(data || []).map((row, index) => (
              <Collapse.Panel
                className="atuoAccordion__result-item"
                key={`collapse-${index}`}
                header={row?.value || row?.condition_name}
                extra={<PanelToolbar index={index} row={row} />}
              >
                <div className="content">
                  <div className="hpiList">
                    {(row?.questions || []).map((question, index2) => (
                      <HPIConditionItem
                        handleVari={handleVariAPI}
                        handleVariExtra={handleVariExtraApi}
                        varianceExtra={question?.variance_extra || ""}
                        checkHover={checkHover}
                        checkHover2={checkHover2}
                        is_multiple={question?.is_multiple == 1}
                        indexKey={index2}
                        isArtery={row?.condition_name && row?.condition_name.indexOf("Peripheral Artery") > -1}
                        key={`questionList-${index}-${index2}`}
                        data={question}
                        variance={question?.variance}
                        condition={row?.condition_name}
                        indexRow={index}
                        onChangeComments={(comments) => onChangeComments(index, index2, comments)}
                        onSelectChoice={(checked_choice) => onSelectChoice(index, index2, checked_choice, question?.is_multiple == 1)}
                        isLocked={is_locked}
                      />
                    ))}
                    {/* show Red Info */}
                    {(stateShowRed[row.condition_name] || []).map((r, index) => {
                      return (
                        <div key={index} style={{ width: "100%", marginTop: "10px", color: "#317CFE", fontWeight: "bold" }}>
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} /> {r}
                          <br />
                        </div>
                      );
                    })}
                    {row.condition_name === "Carotid Disease" && indexesLeftCarotidS ? (
                      <div style={{ width: "100%", marginTop: "10px", color: "#317CFE", fontWeight: "bold" }}>
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                        {`Medical necessity criteria met for Carotid Endarterectomy (Left) - Symptomatic per SVS 2022 Guidelines`}
                        <br />
                      </div>
                    ) : null}
                    {row.condition_name === "Carotid Disease" && indexesRightCarotidS ? (
                      <div style={{ width: "100%", marginTop: "10px", color: "#317CFE", fontWeight: "bold" }}>
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                        {`Medical necessity criteria met for Carotid Endarterectomy (Right) - Symptomatic per SVS 2022 Guidelines`}
                        <br />
                      </div>
                    ) : null}
                    {row.condition_name === "Carotid Disease" && indexesLeftCarotidA ? (
                      <div style={{ width: "100%", marginTop: "10px", color: "#317CFE", fontWeight: "bold" }}>
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                        {`Medical necessity criteria met for Carotid Endarterectomy (Left) - Asymptomatic per SVS 2022 Guidelines`}
                        <br />
                      </div>
                    ) : null}
                    {row.condition_name === "Carotid Disease" && indexesRightCarotidA ? (
                      <div style={{ width: "100%", marginTop: "10px", color: "#317CFE", fontWeight: "bold" }}>
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                        {`Medical necessity criteria met for Carotid Endarterectomy (Right) - Asymptomatic per SVS 2022 Guidelines`}
                        <br />
                      </div>
                    ) : null}
                  </div>
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

const SearchInput = (props) => {
  const [searchInput, setSearch] = useState("");
  const debouncedSearch = useDebounce(searchInput, 400);
  useEffect(() => {
    setSearch(props.searchInput);
  }, [props.searchInput]);

  useEffect(() => {
    props.setSearch(searchInput);
  }, [debouncedSearch]);

  const handleSearch = (search) => {
    setSearch(search);
  };

  return (
    <Input.Search
      value={searchInput}
      disabled={props.disabled}
      placeholder="Search Condition"
      onChange={(e) => handleSearch(e.target?.value)}
      style={{ width: "100%" }}
      suffix={<SearchSVG />}
      onFocus={() => props.setShowOptions(true)}
      onBlur={() =>
        setTimeout(() => {
          props.setShowOptions(false);
        }, 250)
      }
    />
  );
};

const HPIConditionItem = ({
  data,
  onChangeComments,
  onSelectChoice,
  isLocked,
  is_multiple,
  variance,
  isArtery,
  checkHover,
  handleVari,
  indexRow,
  checkHover2,
  condition,
  indexKey,
  varianceExtra,
  handleVariExtra,
}) => {
  const [isOpenedBox, setOpenBox] = useState(false);
  const [question, setQuestion] = useState("");
  const [checkedChoice, setCheckedChoice] = useState(null);
  const [change, setChange] = useState(null);
  const [checkedChoiceMix, setCheckedChoiceMix] = useState([]);
  const [comments, setComments] = useState("");
  const [isVari, setIsVari] = useState(false);
  const [choices, setChoices] = useState([]);
  const [iODocState, setIoDocState] = useState("");
  const [hoverDelete, setHoverDelete] = useState({
    index: 0,
    status: false,
  });

  const handleInsideMix = (choice, mixArray) => {
    if (choice !== "") {
      if (!mixArray.some((r) => r === choice.option)) {
        if (choice?.option === "None") {
          mixArray = ["None"];
        } else {
          const noneIndex = mixArray?.indexOf("None");
          if (noneIndex !== -1) {
            mixArray.splice(noneIndex, 1);
            mixArray.push(choice.option);
          } else {
            mixArray.push(choice.option);
          }
        }
      } else {
        mixArray = mixArray.filter((r) => r !== choice.option);
      }
      setCheckedChoiceMix([...mixArray]);
      onSelectChoice([...mixArray]);
    }
  };

  const handleMix = (choice, index, condition, question) => {
    setChange(Math.random());
    let checked_choiceMix = [];
    const checkedChoiceData = data?.checked_choice || "";
    if (!Array.isArray(checkedChoiceData)) {
      checked_choiceMix = checkedChoiceData.split(",");
    } else {
      checked_choiceMix = checkedChoiceData;
    }
    let mixArray = [...checked_choiceMix];
    const valueCheck = ["Sudden Pain", "Claudication", "Rest Pain", "Wound/Gangrene", "Restenosis"];
    if (
      condition &&
      condition === "Peripheral Artery Disease (PAD)" &&
      (question == "Does the patient suffer from RIGHT Lower Extremity symptoms?" ||
        question == "Does the patient suffer from LEFT Lower Extremity symptoms?" ||
        question == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?")
    ) {
      if (hoverDelete?.status && hoverDelete?.index === index) {
        handleInsideMix(choice, mixArray);
        setHoverDelete(null);
      } else if (valueCheck.some((r) => r === choice?.option) && checked_choiceMix.indexOf(choice?.option) !== -1) {
        setHoverDelete({
          index: index,
          status: true,
        });
      } else {
        handleInsideMix(choice, mixArray);
      }
    } else {
      handleInsideMix(choice, mixArray);
    }
  };

  const update = useCallback((data) => updateCallBack(data), [updateCallBack]);

  const updateCallBack = (data) => {
    if (is_multiple) {
      let question = "";
      let choices = [];
      let comments = "";
      let checked_choiceMix = [];
      question = data?.question;
      choices = data?.choices;
      const checkedChoiceData = data?.checked_choice || "";
      if (!Array.isArray(checkedChoiceData)) {
        if (checkedChoiceData) {
          checked_choiceMix = checkedChoiceData.split(",").filter((r) => r != "");
        }
      } else {
        checked_choiceMix = (checkedChoiceData || []).filter((r) => r != "");
      }

      comments = "";
      if (data) {
        (data?.choices || []).map((r) => {
          if (r.option && checked_choiceMix.indexOf(r.option) > -1) {
            comments += r.text_template;
          } else if (r.option == "" || data?.checked_choice == "") {
            comments = "";
          }
        });
      }
      setCheckedChoiceMix(checked_choiceMix);
      setChoices(choices);
      setQuestion(question);
      setComments(comments);
    } else {
      let question = "";
      let choices = [];
      let comments = "";
      let checked_choice = "";
      question = data?.question;
      choices = data?.choices;
      comments = data?.comments;
      checked_choice = data?.checked_choice;
      setQuestion(question);
      setChoices(choices);
      setComments(comments);
      setCheckedChoice(checked_choice);
    }
  };

  useEffect(() => {
    update(data);
  }, [JSON.stringify(data), change]);

  useEffect(() => {
    if (
      question === "Has the patient been on Cilostazol (Pletal) therapy for at least 12 weeks?" ||
      question === "Has the patient been on statin therapy for at least 12 weeks?" ||
      question === "Has the patient been on ACE inhibitor or Angiotensin Receptor Blocker?"
    ) {
      setIsVari(true);
    } else if (
      question == "Has the patient been on antiplatelet therapy for at least 12 weeks?" ||
      JSON.stringify(question) == "Has the patient been on antiplatelet therapy for at least 12 weeks?"
    ) {
      setIsVari(true);
    } else {
      setIsVari(false);
    }
  }, [question]);

  const { Option } = Select;

  return (
    <div className="HPIItem">
      <div className="HPIItem__label">{question}</div>
      <div className={`HPIItem__icon ${isOpenedBox ? "open" : ""}`} onClick={() => setOpenBox(!isOpenedBox)}>
        <Icon type="edit" />
      </div>
      {is_multiple ? (
        <div
          className={`HPIItem__options`}
          style={{
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {choices &&
            choices.map((choice, index) => {
              return isVari && choice.option.toLowerCase() === "Variance".toLowerCase() ? (
                <>
                  <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                    <Select
                      placeholder={choice?.option}
                      className="varianceSelect"
                      style={
                        data?.checked_choice == "" && !variance
                          ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                          : !variance
                          ? { width: "100%", border: "1px solid #dfe3e6", backgroundColor: "#f4f7fb" }
                          : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                      }
                      onChange={(e) => {
                        setIoDocState(e);
                        handleVari && handleVari(e, indexRow, question, indexKey);
                      }}
                      value={variance || iODocState}
                      optionKey={"value"}
                      valueKey={"label"}
                    >
                      {(SelectOptions || [])?.map((condition, index) => (
                        <Option
                          style={{
                            textTransform: "capitalize",
                          }}
                          key={`condition-${condition?.value} - ${index}`}
                          value={condition.value}
                        >
                          {condition?.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {variance && variance == "contraindication" ? (
                    <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                      <Select
                        placeholder={choice?.option}
                        style={
                          !varianceExtra
                            ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                            : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                        }
                        onChange={(e) => {
                          handleVariExtra && handleVariExtra(e, indexRow, question, indexKey);
                        }}
                        value={varianceExtra}
                        optionKey={"value"}
                        valueKey={"label"}
                      >
                        {(SelectOptionsExtra[question] || [])?.map((condition, index) => (
                          <Option
                            style={{
                              textTransform: "capitalize",
                            }}
                            key={`condition-${condition?.value} - ${index}`}
                            value={condition.value}
                          >
                            {condition?.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ) : null}
                </>
              ) : (
                <button
                  key={`question-choice-${index}`}
                  className={`HPIItem__option ${isLocked == 0 && "not-locked"}
                  ${
                    checkedChoiceMix.indexOf(choice.option) > -1
                      ? hoverDelete?.status && hoverDelete?.index === index
                        ? "hover-delete"
                        : "active"
                      : checkHover &&
                        checkHover[`quest_${indexKey + 1}`] &&
                        checkHover[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                        isArtery
                      ? "" // "hover-show" will turn on color yellow warning
                      : checkHover2 &&
                        checkHover2[`quest_${indexKey + 1}`] &&
                        checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                        isArtery
                      ? "hover-green-show"
                      : "non-active"
                  }
                  `}
                  onClick={() => {
                    handleMix(choice, index, condition, question);
                    setIoDocState("");
                    // handleVari && handleVari("", indexRow, question, indexKey);
                    // handleVariExtra && handleVariExtra("", indexRow, question, indexKey);
                  }}
                  disabled={isLocked}
                  style={
                    checkHover2 &&
                    checkHover2[`quest_${indexKey + 1}`] &&
                    checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                    isArtery
                      ? {}
                      : data?.checked_choice == "" && !variance
                      ? {
                          border: "1px solid red",
                          backgroundColor: "#ff000010",
                        }
                      : {}
                  }
                >
                  {choice.option}
                </button>
              );
            })}
        </div>
      ) : (
        <div className={`HPIItem__options`}>
          {choices &&
            choices.map((choice, index) => {
              return isVari && choice.option.toLowerCase() === "Variance".toLowerCase() ? (
                <>
                  <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                    <Select
                      placeholder={choice?.option}
                      style={
                        data?.checked_choice == "" && !variance
                          ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                          : !variance
                          ? { width: "100%", border: "1px solid #dfe3e6", backgroundColor: "#f4f7fb" }
                          : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                      }
                      onChange={(e) => {
                        setIoDocState(e);
                        handleVari && handleVari(e, indexRow, question, indexKey);
                      }}
                      value={variance || iODocState}
                      optionKey={"value"}
                      valueKey={"label"}
                    >
                      {(SelectOptions || [])?.map((condition, index) => (
                        <Option
                          style={{
                            textTransform: "capitalize",
                          }}
                          key={`condition-${condition?.value} - ${index}`}
                          value={condition.value}
                        >
                          {condition?.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {variance && variance == "contraindication" ? (
                    <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                      <Select
                        placeholder={choice?.option}
                        style={
                          !varianceExtra
                            ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                            : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                        }
                        onChange={(e) => {
                          handleVariExtra && handleVariExtra(e, indexRow, question, indexKey);
                        }}
                        value={varianceExtra}
                        optionKey={"value"}
                        valueKey={"label"}
                      >
                        {(SelectOptionsExtra[question] || [])?.map((condition, index) => (
                          <Option
                            style={{
                              textTransform: "capitalize",
                            }}
                            key={`condition-${condition?.value} - ${index}`}
                            value={condition.value}
                          >
                            {condition?.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ) : null}
                </>
              ) : (
                <button
                  key={`question-choice-${index}`}
                  className={`HPIItem__option ${(isLocked === 0 || isLocked === false) && "not-locked"}
                ${
                  choice.option == data?.checked_choice
                    ? hoverDelete?.status && hoverDelete?.index === index
                      ? "hover-delete"
                      : "active"
                    : checkHover &&
                      checkHover[`quest_${indexKey + 1}`] &&
                      checkHover[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                      isArtery
                    ? "" // "hover-show" will turn on color yellow warning
                    : checkHover2 &&
                      checkHover2[`quest_${indexKey + 1}`] &&
                      checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                      isArtery
                    ? "hover-green-show"
                    : "non-active"
                }`}
                  style={
                    checkHover2 &&
                    checkHover2[`quest_${indexKey + 1}`] &&
                    checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                    isArtery
                      ? {}
                      : data?.checked_choice == "" && !variance
                      ? {
                          border: "1px solid red",
                          backgroundColor: "#ff000010",
                        }
                      : {}
                  }
                  onClick={() => {
                    if (
                      hoverDelete?.status &&
                      hoverDelete?.index === index &&
                      question == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
                    ) {
                      setCheckedChoice(null);
                      onSelectChoice(null);
                      setHoverDelete(null);
                    } else if (
                      choice.option == "No" &&
                      checkedChoice == "No" &&
                      question == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
                    ) {
                      setHoverDelete({
                        index: index,
                        status: true,
                      });
                    } else {
                      setIoDocState("");
                      setCheckedChoice(choice.option);
                      onSelectChoice(choice.option);
                      setHoverDelete(null);
                    }
                  }}
                  disabled={isLocked}
                >
                  {choice.option}
                </button>
              );
            })}
        </div>
      )}
      <div
        className="HPIItem__box"
        style={{
          display: isOpenedBox ? "inline-block" : "none",
        }}
        onBlur={() => {
          onChangeComments(comments);
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "5px",
            top: "5px",
            zIndex: 10,
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
          onClick={() => setOpenBox(!isOpenedBox)}
        >
          <CloseIconSVG />
        </div>
        <Input.TextArea
          rows={4}
          value={comments}
          onChange={(e) => {
            setComments(e.target?.value);
          }}
        />
      </div>
    </div>
  );
};

export default ProcedureSearch;

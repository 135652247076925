import { Icon } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CheckIdAngio from "src/helpers/CheckIdAngio";
import { setLocal } from "src/helpers/Local";
import { angioPatientSnapshotLoadInit, getConditionDetailData, get_cpt_code_review, load_intra_operative } from "src/services/api.services";
import {
  choosePatientOnSchedules,
  chooseSelectedPatientResource,
  clinicProviderReviewLoadInit,
  getDetailUltrasounds,
  getLock,
  get_patient_notes,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  patient_insurances_id_insurances,
  removeDataPatient,
  removeReviewUltrasound,
  remove_patient_on_schedules,
  resetStateResource,
  setActionGoNextTab,
  setDataPatient,
  setErrorHPI,
  setGlobalToastr,
  setNewLoading,
  setPatientSnapshotData,
  set_data_template_review,
  ultrasoundReviewLoadInit,
} from "src/store/actions";
const styleClick = { color: "blue", cursor: "pointer" };

const dataPass = ["id", "patient_id", "ultrasound_id", "appointment_ultrasound_id"];

export default function TableCustom({ dataCompliance, dataLabel }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const renderDate = (date1, date2) => {
    if (date1 && date2) {
      const valueDate1 = moment(date1, "MM/DD/YYYY");
      const valueDate2 = moment(date2, "MM/DD/YYYY");
      const duration = moment.duration(valueDate1.diff(valueDate2));
      const days = Math.round(duration.asDays());
      const months = Math.floor(duration.asMonths());
      return `${days ? `${days > 1 ? `${days} Days` : `${days} Day`}` : ""}`;
    }
    return null;
  };

  const onSelectResource = async (patient_id, appointment_id, type, ultrasound_id, appointment_ultrasound_id) => {
    if (type == "Ultrasound") {
      dispatch(setNewLoading(true));
      setLocal("current_patient_id", patient_id);
      setLocal("appointment_ultrasound_id", appointment_ultrasound_id);
      setLocal("current_ultrasound_id", ultrasound_id);
      dispatch(ultrasoundReviewLoadInit(appointment_ultrasound_id));
      const current_ultrasound_id = ultrasound_id;
      const result = await dispatch(chooseSelectedPatientResource(patient_id));
      if (result) {
        setDataPatient(result);
      }
      dispatch(get_patient_notes(patient_id));
      dispatch(get_ultrasound_finding_value(appointment_ultrasound_id));
      dispatch(get_ultrasound_type(current_ultrasound_id)); // get ultrasound finding names per ultrasound type
      dispatch(getDetailUltrasounds(current_ultrasound_id)); // get detail about ultrasound (header, logic, etc)
      history.push("/clinic-workflow/ultrasound");
      dispatch(setActionGoNextTab(true));
      dispatch(setNewLoading(false));
      return;
    }
    if (type == "Clinic") {
      setLocal("current_patient_id", patient_id);
      setLocal("current_appointment_id", appointment_id);
      await dispatch(remove_patient_on_schedules());
      const locked = await dispatch(getLock(appointment_id));
      await dispatch(resetStateResource());
      if (!locked?.data?.locked) {
        dispatch(setNewLoading(true));
        const result = await dispatch(choosePatientOnSchedules(patient_id));
        if (result) {
          dispatch(setDataPatient(result));
        }
        await dispatch(removeDataPatient());
        await dispatch(get_patient_notes(patient_id));
        await dispatch(setPatientSnapshotData("resource", { ...result }));
        await dispatch(patient_insurances_id_insurances(patient_id));
        await dispatch(removeReviewUltrasound());
        //
        dispatch(clinicProviderReviewLoadInit(appointment_id, 0));
        dispatch(setErrorHPI(false));
        history.push("/clinic-workflow/provider");
        dispatch(setActionGoNextTab(true));
        dispatch(setNewLoading(false));
      } else {
        await dispatch(
          setGlobalToastr({
            header: "Clinic Workflow",
            message: "This procedure has been locked by " + locked?.data?.user,
            type: "failed",
            link: "Notify " + locked?.data?.user,
            exlink: "Force release " + locked?.data?.user,
          })
        );
      }
      // const { goNextTab } =
    } else {
      dispatch(setNewLoading(true));
      const lock = await getLock(appointment_id);
      setLocal("current_patient_id", patient_id);
      setLocal("current_appointment_id", appointment_id);
      if (lock?.data?.locked === 1) {
        setGlobalToastr({
          header: "OBL Workflow",
          message: "This procedure has been locked by " + lock?.data?.user,
          type: "failed",
          link: "Notify " + lock?.data?.user,
          exlink: "Force release " + lock?.data?.user,
        });
        dispatch(setNewLoading(false));
      } else {
        await resetStateResource();
        await remove_patient_on_schedules();
        await get_patient_notes(patient_id);
        const result = await choosePatientOnSchedules(patient_id);
        if (result) {
          await setDataPatient(result);
          await setPatientSnapshotData("resource", { ...result });
        }
        // goNextTab();
        //
        let data = [];
        const checkid = await load_intra_operative(appointment_id);
        const appointment_type = await get_cpt_code_review(appointment_id);
        if (appointment_type?.appointment_type_id && CheckIdAngio(appointment_type?.appointment_type_id) == true) {
          await setLocal("checkActiveAppointmentType", "active");
        } else {
          await setLocal("checkActiveAppointmentType", "nonActive");
        }
        await set_data_template_review([]);
        if (checkid && checkid.angio_procedures) {
          checkid.angio_procedures.map(async (r) => {
            const value = await getConditionDetailData(r.condition_id);
            data = [...data, value];
            await set_data_template_review([...data]);
          });
        }
        await angioPatientSnapshotLoadInit(appointment_id);
        history.push("/obl-workflow/obl");
        dispatch(setActionGoNextTab(true));
        dispatch(setNewLoading(false));
      }
    }
  };

  return (
    <div className="content" style={{ border: "1px solid #e6e5e8", width: "100%" }}>
      {/* header */}
      <div style={{ width: "100%", display: "flex", flexDirection: "row", borderBottom: "1px solid #e6e5e8" }}>
        {(dataLabel || []).map((rowLabel, indexLabel) => {
          // type check will not render
          if (rowLabel?.name == "" || rowLabel?.name == "check") {
            return (
              <div
                key={`label-${indexLabel}`}
                style={{
                  width: rowLabel?.width || 0,
                  borderLeft: indexLabel > 0 ? "1px solid #e6e5e8" : "",
                  minHeight: 40,
                  padding: 5,
                  backgroundColor: "#f5f8fd",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: 800,
                }}
              ></div>
            );
          }
          //
          return (
            <div
              key={`label-${indexLabel}`}
              style={{
                width: rowLabel?.width || 0,
                borderLeft: indexLabel > 0 ? "1px solid #e6e5e8" : "",
                minHeight: 40,
                padding: 5,
                backgroundColor: "#f5f8fd",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontWeight: 800,
              }}
            >
              {rowLabel?.["name"] || ""}
            </div>
          );
        })}
      </div>
      {/* content */}
      <div style={{ width: "100%" }}>
        {(dataCompliance || []).map((rowTable, indexTable) => (
          <div key={`content-${indexTable}`} style={{ width: "100%" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", borderBottom: "1px solid #e6e5e8" }}>
              {/* map col  */}
              {Object.keys(rowTable).map((rowKey, indexKey) => {
                // key pass here will hide
                if ((dataPass || []).some((rowPass) => rowPass == rowKey)) {
                  return null;
                }
                // key is icon
                if (dataLabel?.[indexKey]?.type == "" || dataLabel?.[indexKey]?.type == "status") {
                  return (
                    <div
                      key={`item-${indexKey}`}
                      style={{
                        width: dataLabel?.[indexKey]?.["width"] || 0,
                        minHeight: 40,
                        padding: "8px 5px 5px 5px",
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {console.log(rowTable, "rowTable")}
                      {rowTable["status"] == "billed" ? (
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                      ) : (
                        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                      )}
                    </div>
                  );
                }
                //
                return (
                  <div
                    key={`item-${indexKey}`}
                    style={{
                      width: dataLabel?.[indexKey]?.["width"] || 0,
                      minHeight: 40,
                      padding: 5,
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {rowTable?.[rowKey] && typeof rowTable?.[rowKey] === "string"
                      ? rowTable?.[rowKey].split("\n").map((i, key) => {
                          // make space multiline have \n
                          return (
                            <p
                              onClick={() => {
                                // if colum is service and have ultrasound
                                if (rowKey == "service" && i == "Ultrasound") {
                                  onSelectResource(
                                    rowTable?.patient_id,
                                    rowTable?.id,
                                    [i],
                                    rowTable["ultrasound_id"],
                                    rowTable["appointment_ultrasound_id"]
                                  );
                                } else {
                                  // else
                                  if (rowKey == "service" && i !== "Ultrasound") {
                                    onSelectResource(rowTable?.patient_id, rowTable?.id, [i]);
                                  }
                                }
                              }}
                              style={{ width: "100%", minHeight: 12, ...(rowKey == "service" ? styleClick : {}) }}
                              key={`multiline-${key}`}
                            >
                              {i.includes("<b>") ? <b>{i.replace(/<\/?b>/g, "")}</b> : i}
                            </p>
                          );
                        })
                      : // make multiline have array
                      rowKey == "compliance" && Array.isArray(rowTable?.[rowKey]) && rowTable?.[rowKey].length > 0
                      ? rowTable?.[rowKey].map((i, key) => {
                          return (
                            <div style={{ width: "100%", minHeight: 12, display: "flex", gap: 10, marginBottom: 4 }} key={`multiline-${key}`}>
                              <div style={{ marginTop: 3 }}>
                                <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                              </div>
                              <p>{i}</p>
                            </div>
                          );
                        })
                      : rowTable?.[rowKey]}
                  </div>
                );
              })}
            </div>
            {/* show date center */}
            {(indexTable % 1 === 0 || indexTable == 1) && indexTable + 1 < dataCompliance.length ? (
              <div
                style={{
                  width: "100%",
                  minHeight: 40,
                  padding: 5,
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid #e6e5e8",
                }}
              >
                <p style={{ fontWeight: 800 }}>
                  {renderDate(dataCompliance?.[indexTable]?.service_date, dataCompliance?.[indexTable + 1]?.service_date)}
                </p>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

import { Drawer, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CptReview from "../../../../common/components/CptReview/index";
import { default as EditableFormInput, default as EditableInput } from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { getLocal } from "../../../../helpers/Local";
import * as API from "../../../../services/api.services";
import {
  get_cpt_code_review,
  get_plan_patient,
  lockClinicProcedure,
  search_cpt_code,
  setGlobalLoading,
  setGlobalToastr,
  set_cpt_code_review,
  unlockClinicProcedure,
  unlock_finalize,
  getPatientSchedules,
} from "../../../../store/actions";
import ReviewIcdCode from "./ReviewIcdCode";
import moment from "moment";

let timer = null;

const ReviewSideBar = React.forwardRef(
  (
    {
      isShow,
      sidebarIcon,
      handleCloseSidebar,
      valueText,
      onChangeCPT,
      resource,
      setNameUltrasound,
      setNameSignal,
      handleChangeTime,
      handleChangeProvider,
    },
    ref
  ) => {
    const showBack = useSelector((state) => state.common?.showBack);
    const usersCheck = useSelector((state) => state.common.currentUserInfo);
    const detailUltrasounds = useSelector((state) => state.common.detailUltrasounds);

    const only_providers = useSelector((state) => state.common.only_providers);
    const providers = useSelector((state) => state.common.providers);
    const [dataSearch, setDataSearch] = useState([]);
    const [cptData, setCptData] = useState([]);
    const [isResult, setIsResult] = useState(false);
    const [valueSono, setValueSono] = useState("");
    const [sonographerId, setSonographerId] = useState(0);
    const [searchInput, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const current_patient_id = getLocal("current_patient_id");
    const auth = getLocal("cvai-current-user-provider-name");
    const scheduleParams = JSON.parse(getLocal("scheduleParams"));
    const current_appointment_id = getLocal("current_appointment_id");
    const dispatch = useDispatch();
    const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");
    const CPT = useSelector((state) => state.common.CPT);
    const searchData = useSelector((state) => state.common.searchCPT);
    const [loadingButton, setLoadingButton] = useState(true);
    const [isLock, setIsLock] = useState(0);
    const [readingPhysicianId, setReadingPhysicianId] = useState(auth);
    const [readingPhysicianName, setReadingPhysicianName] = useState("");
    const history = useHistory();
    const [localProviderId, setLocalProviderId] = useState(0);
    const [localFinalizedDate, setLocalFinalizedDate] = useState("");

    const reading_physician_id = useSelector((state) => state.clinicProviderReview.reading_physician_id);

    useEffect(() => {
      setNameSignal && setNameSignal(readingPhysicianName);
    }, [readingPhysicianName]);

    // check name if reading ID not empty and empty

    useEffect(() => {
      if (only_providers && only_providers.length > 0 && readingPhysicianId) {
        const detailFind = only_providers.find((r) => r?.id === readingPhysicianId);
        if (detailFind && detailFind?.value) {
          setReadingPhysicianName(detailFind?.value || "");
        } else {
          setReadingPhysicianName(auth);
        }
      } else {
        setReadingPhysicianName(auth);
      }
    }, [reading_physician_id, only_providers, auth, readingPhysicianId]);

    const onChange = (type) => (value, name) => {
      if (type == "valueSono") {
        setValueSono(value);
        setNameUltrasound && setNameUltrasound(name?.value || null);
        API.update_reading_physician(appointment_ultrasound_id, {
          reading_physician_id: readingPhysicianId,
          ultrasound_sonographer: value,
        });
      } else {
        setReadingPhysicianId(value);
        API.update_reading_physician(appointment_ultrasound_id, {
          reading_physician_id: value,
          ultrasound_sonographer: valueSono,
        });
      }
    };

    const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
    const required_abi_exercise_left = useSelector((state) => state.clinicProviderReview.required_abi_exercise_left);
    const required_abi_exercise_right = useSelector((state) => state.clinicProviderReview.required_abi_exercise_right);
    const required_tbi_right = useSelector((state) => state.clinicProviderReview.required_tbi_right);
    const required_tbi_left = useSelector((state) => state.clinicProviderReview.required_tbi_left);

    const checkIslock = () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        setIsLock(is_locked);
        setLoadingButton(false);
      }, 1000);
    };

    useEffect(() => {
      checkIslock();
    }, [is_locked]);

    useEffect(() => {
      setReadingPhysicianId(reading_physician_id);
    }, [reading_physician_id]);

    useEffect(() => {
      dispatch(get_plan_patient(current_patient_id, current_appointment_id));
    }, []);
    const removeRow = (index) => {
      let newRows = [];
      newRows = [...cptData];
      newRows.splice(index, 1);
      onChangeCPT([...newRows]);
      setCptData([...newRows]);
    };

    const selectItem = (itemcheck, item, type) => {
      const newRows = cptData;
      let checkExist = false;
      (cptData || []).some((r) => {
        if (r.cpt_code === item) {
          checkExist = true;
        }
      });
      if (!checkExist) {
        newRows.push({ cpt_code: item, short_desc: type });
        setCptData(newRows);
        onChangeCPT([...newRows]);
        setIsResult(false);
        setInput("");
      } else {
        setIsResult(false);
        setInput("");
      }
    };

    const handleSearch = async (search) => {
      await setInput(search);
      clearTimeout(timer);
      setLoading(true);
      timer = setTimeout(async () => {
        await dispatch(search_cpt_code(search));
        setLoading(false);
      }, 1000);
    };

    const saveAll = async () => {
      if (required_abi_exercise_right === true && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Right side ABI exercise is required",
            type: "failed",
          })
        );
      } else if (required_abi_exercise_left === true && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Left side ABI exercise is required",
            type: "failed",
          })
        );
      } else if (required_tbi_right === true && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Right side TBI is required",
            type: "failed",
          })
        );
      } else if (required_tbi_left === true && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Left side TBI is required",
            type: "failed",
          })
        );
      } else {
        await dispatch(setGlobalLoading(true));
        setLoading(true);
        const param = {
          text_template: valueText,
          cpt_codes: (cptData || []).map((r) => {
            return r.cpt_code;
          }),
          medical_decision_making: null,
          reading_physician_id: readingPhysicianId,
          finalized_date: localFinalizedDate,
          provider_id: sonographerId,
        };

        if (isLock == 1) {
          await dispatch(unlock_finalize(appointment_ultrasound_id));
          setIsLock(0);
          await dispatch(unlockClinicProcedure());
        } else {
          await dispatch(set_cpt_code_review(appointment_ultrasound_id, param));
          await dispatch(lockClinicProcedure());
          setIsLock(1);
          await dispatch(
            setGlobalToastr({
              message: "The procedure is finalized",
            })
          );
        }
        await dispatch(get_cpt_code_review(appointment_ultrasound_id));
        await dispatch(
          getPatientSchedules({
            date: scheduleParams?.date || moment().format("MM/DD/YYYY"),
            location: scheduleParams?.location || "",
            provider_id: scheduleParams?.provider_id || "",
            patient_id: "",
            only_clinics: 0,
            only_procedures: 0,
            only_us: 1,
            only_angio: 0,
          })
        );
        setLoading(false);
        await dispatch(setGlobalLoading(false));
      }
    };

    useEffect(() => {
      if (searchData.length > 0) {
        const checkedArray = (searchData || []).map((item) => {
          return {
            id: item?.id,
            cpt_code: item.code,
            short_desc: item.short_desc,
            created_at: item.created_at,
            updated_at: item.updated_at,
            fee: item.fee,
            cpt_code_name: item.cpt_code_name,
          };
        });
        setDataSearch(checkedArray);
      }
    }, [searchData]);

    useEffect(() => {
      if (CPT && CPT.cpt_codes && CPT.cpt_codes.length > 0) {
        const checkedArray = CPT.cpt_codes.map((r) => {
          return {
            cpt_code: r.code,
            short_desc: r.short_desc,
          };
        });
        onChangeCPT([...checkedArray]);
        setCptData(checkedArray);
        setIsLock(CPT.is_locked);
      }
      setValueSono((CPT?.provider && CPT?.provider?.value) || "");
      setSonographerId(CPT?.provider_id && CPT?.provider_id);

      if (CPT.finalized_date) {
        setLocalFinalizedDate(CPT?.finalized_date);
      } else {
        setLocalFinalizedDate(moment().format("MM/DD/YYYY hh:mm:ss A"));
      }

      if (CPT?.reading_physician_id) {
        setLocalProviderId(CPT?.reading_physician_id);
      }
    }, [CPT]);

    const handleGoBackReview = (e) => {
      e.stopPropagation();
      e.isPropagationStopped();
      history.push("/administrator/records-review");
    };

    const handleDate = (value) => {
      setLocalFinalizedDate(value);
      handleChangeTime(value);
    };

    const handleTime = (value) => {
      let temp = moment(localFinalizedDate).hours(value.hours()).minutes(value.minutes()).seconds(value.seconds()).format("MM/DD/YYYY hh:mm:ss A");
      setLocalFinalizedDate(temp);
      handleChangeTime(temp);
    };

    return (
      <Drawer
        title={detailUltrasounds && detailUltrasounds?.name ? detailUltrasounds?.name.toUpperCase() : "Summary Dashboard"}
        placement="right"
        mask={false}
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
        key="right"
      >
        <div ref={ref} className="resourceContainer new">
          <ScrollWrapper>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "8px",
                paddingBottom: "1rem",
                minHeight: "45vh",
              }}
            >
              <div className="resourceInfo" style={{ marginTop: "1rem" }}>
                <ReviewIcdCode />
              </div>

              <div className="resourceInfo between" style={{ marginTop: "1rem" }}>
                <CptReview
                  DataOpSearch={dataSearch}
                  keySearch="cpt_code_name"
                  keyType="short_desc"
                  title="cpt_review"
                  keyValue="cpt_code"
                  rows={cptData}
                  removeItem={removeRow}
                  isResult={isResult}
                  query={searchInput}
                  selectItem={selectItem}
                  handleSearch={handleSearch}
                  isLocked={isLock}
                />
              </div>

              <div className="resourceInfo d-flex required-field">
                <EditableFormInput
                  id={`Reading_Physician`}
                  label={"Reading Physician"}
                  value={readingPhysicianId || undefined}
                  handleChange={onChange("reading_physician_id")}
                  type={"check"}
                  optionKey={"id"}
                  valueKey={"value"}
                  options={only_providers || []}
                  disabled={is_locked}
                />
              </div>

              <div className="resourceInfo d-flex required-field">
                <EditableFormInput
                  label={"Ultrasound Sonographer"}
                  value={valueSono || undefined}
                  handleChange={onChange("valueSono")}
                  type={"check"}
                  optionKey={"id"}
                  valueKey={"value"}
                  options={providers || []}
                  disabled={is_locked}
                />
              </div>
              {usersCheck && usersCheck.id == 5 ? (
                <>
                  <div className="resourceInfo d-flex align-items-end required-field">
                    <EditableInput
                      label={"Provider"}
                      type={"check"}
                      options={only_providers}
                      value={localProviderId}
                      handleChange={(value) => {
                        setLocalProviderId(value);
                        handleChangeProvider(value);
                      }}
                      optionKey={"id"}
                      valueKey={"value"}
                      required={true}
                      helperText={"Provider is required"}
                      disabled={is_locked}
                    />
                  </div>
                  <div className="resourceInfo d-flex required-field">
                    <EditableFormInput
                      label={"Finalized Date"}
                      value={localFinalizedDate ? localFinalizedDate : null}
                      type="date"
                      handleChange={handleDate}
                      disabled={is_locked}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <div className="editableText material">
                      <div className={`MuiFormControl-root MuiTextField-root date-picker`}>
                        <TimePicker
                          disabled={is_locked}
                          format={"h:mm:ss A"}
                          use12Hours
                          placeholder="Time"
                          onChange={handleTime}
                          value={localFinalizedDate ? moment(localFinalizedDate) : null}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {usersCheck && (usersCheck?.is_provider == 1 || usersCheck?.is_admin == 1) ? (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    {usersCheck?.is_admin == 1 && showBack && (
                      <button
                        style={{
                          marginRight: 10,
                        }}
                        onClick={handleGoBackReview}
                      >
                        Go back to review
                      </button>
                    )}

                    <button
                      id="Finalize"
                      style={{
                        backgroundColor: "#1164F2",
                      }}
                      onClick={saveAll}
                    >
                      {isLock == 1 ? "Unfinalize" : "Finalize +"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: isLock == 1 ? "#C6C6C6" : "#1164F2",
                      }}
                      onClick={saveAll}
                    >
                      {"Save for Review"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default ReviewSideBar;

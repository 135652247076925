function removeEscapeCodes(inputString) {
  // Use regular expressions to find and replace escape codes
  if (inputString) {
    const escapedString = inputString.replace(/\\./g, "").trim();
    return escapedString;
  }
  return "";
}

export default removeEscapeCodes;

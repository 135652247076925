import { Icon, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from "../../../common/components/DataTable/DataTable";
import TopBar from "../../../common/components/SubTopBar";
import ToastMessage from "../../../common/components/Toast";
import CheckIdAngio from "../../../helpers/CheckIdAngio";
import { getLocal, setLocal } from "../../../helpers/Local";
import { getClinicWorkflowAppointmentsLocalApi, getPatients, getReviewStat } from "../../../services/api.services";
import {
  angioPatientSnapshotLoadInit,
  choosePatientOnSchedules,
  clinicProviderReviewLoadInit,
  getDetailUltrasounds,
  getLock,
  getOnlyProviders,
  get_cpt_code_review,
  get_patient_notes,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  load_procedurals,
  patientExaminationLoadInit,
  patient_insurances_id_insurances,
  releaseCharge,
  removeDataPatient,
  remove_patient_on_schedules,
  resetStateResource,
  setDataLocalChangeRecord,
  setDataPatient,
  setFirstLoadingActive,
  setGlobalLoading,
  setPatientSnapshotData,
  showButtonBack,
} from "../../../store/actions";
import RecordReviewFilter from "./RecordReviewFilter";
import ReplaceUnicodeEscapes from "src/helpers/ReplaceUnicodeEscapes";

let timer;

const RecordsReview = ({ data, title, display, sidebarIcon }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.common.only_providers);
  const locations = useSelector((state) => state.common.locations);
  // state data arr when go to review data will keep
  const dataLocalChangeRecord = useSelector((state) => state.common.dataLocalChangeRecord);
  const [messageToast, setMessageToast] = useState({
    status: true,
    message: "Release charge Successfully!",
  });
  const [stat, setStat] = useState({});
  const history = useHistory();

  const fetchAPI = async () => {
    dispatch(setGlobalLoading(true));
    const params = getLocal("prams_records_review");
    const valueParams =
      params && Object.keys(params).length > 0
        ? params
        : {
            types: "clinic,ultrasound,angio",
            appointment_status: "review",
            paging: page || 1,
            search: search || "",
            location_id: filterLocation || undefined,
            provider_id: filterProvider || undefined,
            start_date: startDateFilter || undefined,
            end_date: endDateFilter || null,
            with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
          };
    setSearch(params?.search || "");
    setPage(params?.paging || 1);
    setFilterLocation(params?.location_id || undefined);
    setFilterProvider(params?.provider_id || undefined);
    setStartDateFilter(params?.start_date || null);
    setEndDateFilter(params?.end_date || null);
    setSortOption(params?.stateSort || {});
    // call when go to review we will take data old
    if (params && Object.keys(params).length > 0 && dataLocalChangeRecord.length > 0) {
      setTableLoads(dataLocalChangeRecord);
    } else {
      // call first data
      dispatch(setDataLocalChangeRecord(null));
      const dataSuccess = await getClinicWorkflowAppointmentsLocalApi(valueParams);
      setTableLoads(dataSuccess || []);
    }
    dispatch(setGlobalLoading(false));
    setLoading(false);
    getReviewStat().then(function (result) {
      setStat(result);
    });
  };

  const [tableLoad, setTableLoads] = useState([]);
  const [rowSearch, setRowSearch] = useState([]);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isToastr, setIsToastr] = useState(false);
  const [sortOption, setSortOption] = useState({});
  const [forFilter, setForFilter] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [filterProvider, setFilterProvider] = useState(undefined);
  const [filterLocation, setFilterLocation] = useState(undefined);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    dispatch(showButtonBack(false));
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const loadMore = async (values) => {
    if (tableLoad && tableLoad.length > 0) {
      const { scrollTop, scrollHeight, clientHeight } = values;
      const pad = 30; // px of the bottom
      const t = (scrollTop + pad) / (scrollHeight - clientHeight);

      const sortColumn = [];
      if (!!sortOption?.first_name) {
        sortColumn.push({
          name: "first_name",
          direction: sortOption?.first_name,
        });
      }
      if (!!sortOption?.last_name) {
        sortColumn.push({
          name: "last_name",
          direction: sortOption?.last_name,
        });
      }
      if (!!sortOption?.dos) {
        sortColumn.push({ name: "dos", direction: sortOption?.dos });
      }
      if (!!sortOption?.provider) {
        sortColumn.push({ name: "provider", direction: sortOption?.provider });
      }
      if (!!sortOption?.location) {
        sortColumn.push({ name: "location", direction: sortOption?.location });
      }

      if (t >= 1 && Number.isFinite(t) && !loading) {
        if (offLoad) {
          setLoading(true);
          let listUpdate = null;
          if (!forFilter) {
            listUpdate = await getClinicWorkflowAppointmentsLocalApi({
              types: "clinic,ultrasound,angio",
              appointment_status: "review",
              paging: page + 1,
              with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
            });
          } else {
            listUpdate = await getClinicWorkflowAppointmentsLocalApi({
              types: "clinic,ultrasound,angio",
              appointment_status: "review",
              paging: page + 1,
              provider_id: filterProvider,
              location_id: filterLocation,
              search: search,
              start_date: startDateFilter,
              end_date: endDateFilter,
              columns: sortColumn,
              with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
            });
          }
          if (listUpdate) {
            if (listUpdate && listUpdate.length > 0) {
              dispatch(setDataLocalChangeRecord([...tableLoad, ...listUpdate]));
              setTableLoads([...tableLoad, ...listUpdate]);
              setPage(page + 1);
              updateLocalStorageParams("paging", page + 1);
              updateLocalStorageParams("stateSort", sortOption);

              setLoading(false);
            } else {
              for (let i = 0; i < 7; i++) {
                tableLoad.push({});
              }
              dispatch(setDataLocalChangeRecord([...tableLoad]));
              setTableLoads([...tableLoad]);
              setLoading(false);
              setOffLoad(false);
            }
          } else {
            setLoading(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    setTableLoads([]);
    fetchAPI();
  }, []);

  const handleReleaseCharge = async (row, index) => {
    if (row?.id) {
      const sortColumn = [];
      if (!!sortOption?.first_name) {
        sortColumn.push({
          name: "first_name",
          direction: sortOption?.first_name,
        });
      }
      if (!!sortOption?.last_name) {
        sortColumn.push({
          name: "last_name",
          direction: sortOption?.last_name,
        });
      }
      if (!!sortOption?.dos) {
        sortColumn.push({ name: "dos", direction: sortOption?.dos });
      }
      if (!!sortOption?.provider) {
        sortColumn.push({ name: "provider", direction: sortOption?.provider });
      }
      if (!!sortOption?.location) {
        sortColumn.push({ name: "location", direction: sortOption?.location });
      }

      await dispatch(setGlobalLoading(true));
      // update message form api
      const releaseAPI = await dispatch(releaseCharge(row?.id));
      setMessageToast({
        ...(releaseAPI || {}),
        status: releaseAPI?.status == "success" ? true : false,
      });
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
      getReviewStat().then(function (result) {
        setStat(result);
      });
      const arrCheck = [...tableLoad];
      arrCheck.splice(index, 1);
      dispatch(setDataLocalChangeRecord([...arrCheck]));
      setTableLoads([...arrCheck]);
      await dispatch(setGlobalLoading(false));
    }
  };

  const handleView = async (row, index) => {
    if (row?.rowAll?.type == "clinic" && row?.id) {
      const appointment_id = row?.id;
      const patient_id = row?.rowAll?.patient?.id;
      dispatch(setGlobalLoading(true));
      await dispatch(removeDataPatient());
      await dispatch(resetStateResource());
      await dispatch(remove_patient_on_schedules());
      await setLocal("current_appointment_id", appointment_id);
      await setLocal("current_patient_id", patient_id);
      if (patient_id) {
        const result = await dispatch(choosePatientOnSchedules(patient_id));
        if (result) {
          await dispatch(patient_insurances_id_insurances(patient_id));
          dispatch(setDataPatient(result));
          await dispatch(get_patient_notes(patient_id));
          await dispatch(setPatientSnapshotData("resource", { ...result }));
        }
      }
      if (appointment_id) {
        await dispatch(clinicProviderReviewLoadInit(appointment_id, 0));
        await dispatch(getLock(appointment_id));
      }
      history.push("/clinic-workflow/provider");
      dispatch(setFirstLoadingActive(true));
      dispatch(setGlobalLoading(false));
    } else if (row?.rowAll?.type == "ultrasound" && row?.id) {
      await dispatch(removeDataPatient());
      await dispatch(resetStateResource());
      await dispatch(remove_patient_on_schedules());
      dispatch(setGlobalLoading(true));
      const patient_id = row?.rowAll?.patient?.id;
      const appointment_ultrasound_id = row?.id;
      const current_ultrasound_id = row?.rowAll?.ultrasound_type?.id;
      setLocal("current_patient_id", patient_id);
      setLocal("appointment_ultrasound_id", appointment_ultrasound_id);
      setLocal("current_ultrasound_id", current_ultrasound_id);
      const result = await dispatch(choosePatientOnSchedules(patient_id));
      if (result) {
        dispatch(setDataPatient(result));
      }
      await dispatch(patientExaminationLoadInit(appointment_ultrasound_id));
      await dispatch(clinicProviderReviewLoadInit(appointment_ultrasound_id, 0));
      await dispatch(get_patient_notes(patient_id));
      await dispatch(get_ultrasound_finding_value(appointment_ultrasound_id));
      await dispatch(get_ultrasound_type(current_ultrasound_id));
      await dispatch(getDetailUltrasounds(current_ultrasound_id));
      history.push("/clinic-workflow/ultrasound");
      dispatch(setFirstLoadingActive(true));
      dispatch(setGlobalLoading(false));
    } else if (row?.rowAll?.type == "angio" && row?.id) {
      dispatch(setGlobalLoading(true));
      const patient_id = row?.rowAll?.patient?.id;
      const appointment_id = row?.id;
      await dispatch(get_patient_notes(patient_id));
      await setLocal("current_appointment_id", appointment_id);
      const result = await dispatch(choosePatientOnSchedules(patient_id));
      if (result) {
        await dispatch(setDataPatient(result));
        await dispatch(setPatientSnapshotData("resource", { ...result }));
      }
      await dispatch(getLock(appointment_id));
      const appointment_type = await await dispatch(get_cpt_code_review(appointment_id));
      if (appointment_type?.appointment_type_id && CheckIdAngio(appointment_type?.appointment_type_id) == true) {
        await setLocal("checkActiveAppointmentType", "active");
      } else {
        await setLocal("checkActiveAppointmentType", "nonActive");
      }
      await dispatch(angioPatientSnapshotLoadInit(appointment_id));
      await dispatch(load_procedurals(appointment_id));
      dispatch(setFirstLoadingActive(true));
      history.push("/obl-workflow/obl");
      dispatch(setGlobalLoading(false));
    }
    dispatch(showButtonBack(true));
  };

  const sortList = async (sortValue) => {
    const sortColumn = [];
    if (!!sortValue.first_name) {
      sortColumn.push({
        name: "first_name",
        direction: sortValue.first_name,
      });
    }
    if (!!sortValue.last_name) {
      sortColumn.push({
        name: "last_name",
        direction: sortValue.last_name,
      });
    }
    if (!!sortValue.dos) {
      sortColumn.push({ name: "dos", direction: sortValue.dos });
    }
    if (!!sortValue.provider) {
      sortColumn.push({ name: "provider", direction: sortValue.provider });
    }
    if (!!sortValue.location) {
      sortColumn.push({ name: "location", direction: sortValue.location });
    }
    setTableLoads([]);
    setLoading(true);
    setPage(1);
    updateLocalStorageParams("paging", 1);
    updateLocalStorageParams("stateSort", sortValue);
    let listUpdate = [];
    setOffLoad(true);
    if (!forFilter) {
      listUpdate = await getClinicWorkflowAppointmentsLocalApi({
        types: "clinic,ultrasound,angio",
        appointment_status: "review",
        paging: page,
        columns: sortColumn,
        with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
      });
    } else {
      listUpdate = await getClinicWorkflowAppointmentsLocalApi({
        types: "clinic,ultrasound,angio",
        appointment_status: "review",
        paging: page,
        provider_id: filterProvider,
        location_id: filterLocation,
        search: search,
        start_date: startDateFilter,
        end_date: endDateFilter,
        columns: sortColumn,
        with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
      });
    }
    setTableLoads(listUpdate || []);
    dispatch(setDataLocalChangeRecord(listUpdate || []));
    setLoading(false);
  };

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
    const sortColumn = [];
    if (!!sortValue.first_name) {
      sortColumn.push({
        name: "first_name",
        direction: sortValue.first_name,
      });
    }
    if (!!sortValue.last_name) {
      sortColumn.push({
        name: "last_name",
        direction: sortValue.last_name,
      });
    }
    if (!!sortValue.dos) {
      sortColumn.push({ name: "dos", direction: sortValue.dos });
    }
    if (!!sortValue.provider) {
      sortColumn.push({ name: "provider", direction: sortValue.provider });
    }
    if (!!sortValue.location) {
      sortColumn.push({ name: "location", direction: sortValue.location });
    }
    sortList(sortValue);
    updateLocalStorageParams("columns", sortColumn);
    updateLocalStorageParams("paging", page);
    updateLocalStorageParams("stateSort", sortValue);
  };

  const changeFilterProvider = (value) => {
    const valueCheck = value || undefined;
    setFilterProvider(valueCheck);
    updateLocalStorageParams("provider_id", valueCheck);
    updateLocalStorageParams("paging", page);
    updateLocalStorageParams("stateSort", sortOption);

    let dateCreateEnd = endDateFilter;
    let dateCreateStart = startDateFilter;
    callApi(dateCreateEnd, dateCreateStart, valueCheck, filterLocation);
  };

  const changeFilterLocation = (value) => {
    const valueCheck = value || undefined;
    setFilterLocation(valueCheck);
    updateLocalStorageParams("location_id", valueCheck);
    updateLocalStorageParams("paging", page);
    updateLocalStorageParams("stateSort", sortOption);

    let dateCreateEnd = endDateFilter;
    let dateCreateStart = startDateFilter;
    callApi(dateCreateEnd, dateCreateStart, filterProvider, valueCheck);
  };

  const changeDate = (value, type) => {
    let dateCreateEnd = endDateFilter;
    let dateCreateStart = startDateFilter;
    const date = moment(value).format("MM/DD/YYYY");
    if (type === "end") {
      if (value && value !== "Invalid date") {
        setEndDateFilter(date);
        updateLocalStorageParams("end_date", date);
        updateLocalStorageParams("paging", page);
        updateLocalStorageParams("stateSort", sortOption);

        dateCreateEnd = date;
      } else {
        setEndDateFilter(undefined);
        updateLocalStorageParams("end_date", null);
        updateLocalStorageParams("paging", page);
        updateLocalStorageParams("stateSort", sortOption);

        dateCreateEnd = null;
      }
    } else {
      if (value && value !== "Invalid date") {
        setStartDateFilter(date);
        updateLocalStorageParams("start_date", date);
        updateLocalStorageParams("paging", page);
        updateLocalStorageParams("stateSort", sortOption);

        dateCreateStart = date;
      } else {
        setStartDateFilter(undefined);
        updateLocalStorageParams("start_date", null);
        updateLocalStorageParams("paging", page);
        updateLocalStorageParams("stateSort", sortOption);

        dateCreateStart = null;
      }
    }

    callApi(dateCreateEnd, dateCreateStart, filterProvider, filterLocation);
  };

  const callApi = async (dateEnd, dateStart, filterPro, filterLoc) => {
    setLoading(true);
    setForFilter(true);
    setPage(1);
    updateLocalStorageParams("paging", 1);
    updateLocalStorageParams("stateSort", sortOption);

    setOffLoad(true);
    setTableLoads([]);
    const detailSuccess = await getClinicWorkflowAppointmentsLocalApi({
      types: "clinic,ultrasound,angio",
      appointment_status: "review",
      paging: page,
      provider_id: filterPro,
      location_id: filterLoc,
      search: search,
      start_date: dateStart,
      end_date: dateEnd,
      with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
    });
    setTableLoads(detailSuccess || []);
    dispatch(setDataLocalChangeRecord(detailSuccess || []));
    if (detailSuccess) {
      setLoading(false);
    } else {
      setLoading(false);
      alert("error from sever please try again!");
    }
  };

  useEffect(() => {
    if (!providers) {
      dispatch(getOnlyProviders());
    }
  }, []);

  const valueSearch = async (value) => {
    setSearch(value);
    updateLocalStorageParams("search", value);
    updateLocalStorageParams("paging", page);
    updateLocalStorageParams("stateSort", sortOption);
    setLoading(true);
    const dataSearchPatient = await getPatients(value);
    setRowSearch(dataSearchPatient || []);
    setLoading(false);
  };

  const updateLocalStorageParams = (type, value) => {
    const paramsStorage = getLocal("prams_records_review");
    if (paramsStorage) {
      const params = {
        types: "clinic,ultrasound,angio",
        appointment_status: "review",
        ...paramsStorage,
        [type]: value,
      };
      setLocal("prams_records_review", params);
    } else {
      const params = {
        types: "clinic,ultrasound,angio",
        appointment_status: "review",
        [type]: value,
      };
      setLocal("prams_records_review", params);
    }
  };

  const callApiSearch = async (value, pass) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      setForFilter(true);
      setPage(1);
      updateLocalStorageParams("paging", 1);
      updateLocalStorageParams("stateSort", sortOption);
      setOffLoad(true);
      const dataSuccess = await getClinicWorkflowAppointmentsLocalApi({
        types: "clinic,ultrasound,angio",
        appointment_status: "review",
        paging: page,
        search: value,
        location_id: filterLocation,
        provider_id: filterProvider,
        start_date: startDateFilter,
        end_date: endDateFilter,
        with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
      });
      if (pass) {
        setTableLoads(dataSuccess || []);
        dispatch(setDataLocalChangeRecord(dataSuccess || []));
      }
      setLoading(false);
    }, 1000);
  };

  const renderSubStatus = () => {
    let clinic_count = 0;
    let ultrasound_count = 0;
    let angio_count = 0;

    clinic_count = stat?.clinic_review_count;
    ultrasound_count = stat?.ultrasound_review_count;
    angio_count = stat?.angio_review_count;

    return (
      // Green Checkmark, Red Exclamation, Blue Sproket, Green Sprocket, Dollar, Blue Man, Red Circle Line
      <div className="statusHealth">
        <Icon type="container" theme="twoTone" twoToneColor="#0F62FE" />
        {clinic_count}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="filter" theme="twoTone" twoToneColor="#d02670" />
        {ultrasound_count}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="compass" theme="twoTone" twoToneColor="#24A148" />
        {angio_count}
      </div>
    );
  };

  const renderStatusIcon = (row) => {
    if (row?.type === "clinic") {
      return <Icon type="container" theme="twoTone" twoToneColor="#0F62FE" />;
    } else if (row?.type === "ultrasound") {
      return <Icon type="filter" theme="twoTone" twoToneColor="#d02670" />;
    } else if (row?.type === "angio") {
      return <Icon type="compass" theme="twoTone" twoToneColor="#24A148" />;
    }
  };

  const renderTriangleIcon = (row) => {
    if (!row?.compliance) {
      return <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />;
    } else {
      // check if compliance là string thì sẽ bỏ dấu đầu dấu cuối nếu nó là string arr vd :
      // '"["The ABI must be \u2264 0.5 OR the TBI \u2264 0.4 for the rest pain selection of the RIGHT","There in no stenosis >75% stenosis on ultrasound for the rest pain selection of the RIGHT"]"
      const value = row?.compliance && typeof row?.compliance === "string" ? row?.compliance.slice(1, -1) : "";
      // biến string thành array
      const data = value ? value.split(",") : [];
      // chuyển đổi unicode về string bằng ReplaceUnicodeEscapes
      const text = (data || []).map((r, index) => {
        return (
          <div key={index} style={{ display: "flex", gap: 10, marginBottom: 7 }}>
            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
            <p style={{ color: "white" }}>{ReplaceUnicodeEscapes(r).slice(1, -1)}</p>
          </div>
        );
      });
      return (
        <Tooltip placement="bottom" title={text} arrow={true}>
          <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
        </Tooltip>
      );
    }
  };

  const onSelectChoice = (value, id) => {
    if (value && typeof value === "string" && value.includes(" - ")) {
      const name = value.split(" - ")?.[0] || "";
      setSearch(encodeURIComponent(name.toLowerCase()));
      callApiSearch(encodeURIComponent(name.toLowerCase()), true);
    }
  };

  return (
    <div className="clinicWorkflow__main-section">
      <TopBar title="Records Review" subStatus={renderSubStatus()} isBarcode={false} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        {isToastr && <ToastMessage type="Appointment" status={messageToast?.status ? "success" : "failed"} msg={messageToast?.message} />}
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer has-sub-header recordsReviewHeight">
            <div className="fullContainer no-padding-right">
              <RecordReviewFilter
                loading={loading}
                startDate={startDateFilter}
                endDate={endDateFilter}
                changeFilterProvider={changeFilterProvider}
                changeDate={changeDate}
                onSelectChoice={onSelectChoice}
                dataOption={rowSearch}
                searchState={search}
                filterProvider={filterProvider}
                filterLocation={filterLocation}
                providers={providers || []}
                valueSearch={(value) => valueSearch(value)}
                locations={locations || []}
                changeFilterLocation={changeFilterLocation}
              />
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    isBorderThick={true}
                    title="appointment_table"
                    tableWidth={1060}
                    labels={["", "First Name", "Last Name", "DOB", "DOS", "Appointment", "Provider", "Location", "Release Charge"]}
                    widths={["6%", "10%", "11%", "12%", "12%", "12%", "15%", "10%", "12%"]}
                    columns={[
                      { sortable: false, key: "icon" },
                      { sortable: true, key: "first_name" },
                      { sortable: true, key: "last_name" },
                      { sortable: false, key: "dob" },
                      { sortable: true, key: "dos" },
                      { sortable: false, key: "appointment" },
                      { sortable: true, key: "provider" },
                      { sortable: true, key: "location" },
                      { sortable: false, key: "release_charge" },
                    ]}
                    sorts={[sortOption?.first_name, sortOption?.last_name, "", sortOption?.dos, "", sortOption?.provider, sortOption?.location, ""]}
                    rowData={(tableLoad || []).map((row, index) => ({
                      id: row?.id,
                      rowAll: row,
                      appointment: row?.id ? "view_appoiment" : null,
                      icon: (
                        <div
                          style={{
                            display: "flex",
                            paddingLeft: "0.25rem",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div className="has-tooltip" style={{ paddingBottom: "4px" }}>
                            {renderStatusIcon(row)}
                          </div>
                          <div className="has-tooltip" style={{ paddingBottom: "4px", marginLeft: 25 }}>
                            {renderTriangleIcon(row)}
                          </div>
                        </div>
                      ),
                      first_name: (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ textTransform: "capitalize" }}>{row?.patient && row?.patient?.first_name}</p>
                        </div>
                      ),
                      last_name: row?.patient && row?.patient?.last_name,
                      dob: row?.patient && moment(row?.patient?.date_of_birth).format("MM/DD/YYYY"),
                      dos: row?.appointment_date && moment(row?.appointment_date).format("MM/DD/YYYY"),
                      provider: (row.reading_physician && row.reading_physician?.value) || (row.provider && row.provider?.value),
                      location: row.location && row.location?.value,
                      release_charge: row?.id ? "release_charge" : null,
                    }))}
                    fetchList={() => {}}
                    loadMore={loadMore}
                    handleClickView={handleView}
                    handleReleaseCharge={handleReleaseCharge}
                    defaultActive={0}
                    disableDelete={true}
                    handleSort={(value, item) => {
                      handleSortOption(value, item);
                    }}
                    underline={false}
                    bold
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordsReview;

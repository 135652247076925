import { DatePicker, Input, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";

import CardBlock from "../../../common/components/Card";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { CloseIconSVG, ExpandSVG, SearchSVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { useOutsideDetect, useWindowSize } from "../../../common/utils";
import { getPatientAppointment, getPatients } from "../../../services/api.services";
import {
  deleteProcedure,
  getCalendarProcedureTypes,
  getUltrasounds,
  get_angio_type,
  get_location_provider,
  list_clinic_procedure_type,
  saveBulkAppointment,
  setDashboardAppointments,
  setGlobalLoading,
  setGlobalToastr,
} from "../../../store/actions";

const { Search } = Input;
const { Option, OptGroup } = Select;

const AppointmentManager = ({ onCardClick, varient }) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const procedureData = useSelector((state) => state.common.procedureData);
  const untrasound_list = useSelector((state) => state.procedureDetail.untrasound_list);
  const angio_list = useSelector((state) => state.procedureDetail.angio_list);
  const patient = useSelector((state) => state.dashboard.appointments.patient);
  const clinic_list = useSelector((state) => state.procedureDetail.list_clinic_type);
  const dispatch = useDispatch();

  const [openPanel, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [run, setRun] = useState([]);
  const [providers, setProviders] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showSide, setShowSide] = useState({});

  const providersAll = useSelector((state) => state.common.providers);
  const locationsAll = useSelector((state) => state.common.locations);
  const [dataSelect, setDataSelect] = useState([]);
  const [procList, setProcList] = useState([]);
  const [angioList, setAngioList] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [ultrasoundList, setUltrasoundList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isCommentEditing, setCommentEditing] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeSelect, setActiveSelect] = useState(-1);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [activeTitle, setActiveTitle] = useState("");
  const [activeSearchRow, setActiveSearchRecords] = useState(null);
  const [patients, setPatients] = useState([]);
  const [editable, setEditable] = useState(false);
  const [saveAble, setSaveAble] = useState(false);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const fetchAppointmentType = async () => {
    if (run && run[activeSelect] && activeSelect != -1 && editable) {
      if (run[activeSelect]["date"]) {
        const params = {
          date: moment(run[activeSelect]["date"]).format("YYYY-MM-DD"),
        };
        const dataSuccess = await dispatch(getCalendarProcedureTypes(params));
        let clinic = [];
        let ultra = [];
        let proce = [];
        let angio = [];
        setClinicList(clinic);
        setUltrasoundList(ultra);
        setAngioList(angio);
        setProcList(proce);
        setDataSelect([...proce, ...ultra, ...angio, ...clinic]);
        if (dataSuccess && dataSuccess.clinic) {
          clinic = (dataSuccess.clinic || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "clinic",
              label: "Clinic Procedure",
            };
          });
          setClinicList(clinic);
        }
        if (dataSuccess && dataSuccess.ultrasound) {
          ultra = (dataSuccess.ultrasound || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "ultrasound",
              label: "Ultrasound",
            };
          });
          setUltrasoundList(ultra);
        }
        if (dataSuccess && dataSuccess.obl) {
          angio = (dataSuccess.obl || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "angio",
              label: "OBL Procedure",
            };
          });
          setAngioList(angio);
        }
        if (dataSuccess && dataSuccess.hospital) {
          proce = (dataSuccess.hospital || []).map((r) => {
            return {
              ...r,
              value: r?.value,
              type: "or",
              label: "Procedure",
            };
          });
          setProcList(proce);
        }
        setDataSelect([...proce, ...ultra, ...angio, ...clinic]);
      }
    }
  };

  const mixDataSuccess = (dataSuccess) => {
    if (dataSuccess && dataSuccess?.location && dataSuccess?.time && dataSuccess?.provider) {
      run[activeSelect]["location"] = dataSuccess?.location;
      if ((dataSuccess?.location || []).some((r) => r?.id == appointments[activeSelect]["location_id"])) {
        run[activeSelect]["location_id"] = appointments[activeSelect]["location_id"] ?? null;
      } else {
        run[activeSelect]["location_id"] = null;
      }
      if ((dataSuccess?.provider || []).some((r) => r?.id == appointments[activeSelect]["provider_id"])) {
        run[activeSelect]["provider_id"] = appointments[activeSelect]["provider_id"] ?? null;
      } else {
        run[activeSelect]["provider_id"] = null;
      }
      run[activeSelect]["provider"] = dataSuccess?.provider;
      run[activeSelect] = {
        ...run[activeSelect],
        timeDisable: dataSuccess?.time,
      };
      if (run[activeSelect]["time"] && dataSuccess?.time.length > 0) {
        const timeCheck = moment(run[activeSelect]["time"], "HH:mm A").format("HH");
        if (dataSuccess?.time.some((check) => check == timeCheck)) {
          run[activeSelect]["time"] = undefined;
        }
      }
      const checkRun = run.map((r) => {
        return {
          ...r,
          location_name: r?.location && Array.isArray(r?.location) ? (r?.location).find((lc) => lc?.id == r.location_id) : {},
          provider_name: r?.provider && Array.isArray(r?.provider) ? (r?.provider).find((pr) => pr?.id == r.provider_id) : {},
        };
      });
      setAppointments([...checkRun]);
    }
  };

  const fetchApiGetLocationProvider = async () => {
    if (run && run[activeSelect] && activeSelect != -1 && editable) {
      if (
        run[activeSelect]["date"] &&
        run[activeSelect]["appointment_type_id"] &&
        run[activeSelect]["type"] &&
        !Array.isArray(run[activeSelect]["location"])
      ) {
        const params = {
          patient_id: activeSearchRow?.id,
          id: run[activeSelect]["appointment_type_id"],
          date: moment(run[activeSelect]["date"]).format("YYYY-MM-DD"),
          type: run[activeSelect]["type"] == "or" ? "hospital" : run[activeSelect]["type"],
        };
        const dataSuccess = await dispatch(get_location_provider(params));
        mixDataSuccess(dataSuccess);
      } else if (
        run[activeSelect]["date"] &&
        run[activeSelect]["appointment_type_id"] &&
        run[activeSelect]["type"] &&
        run[activeSelect]["location_id"] &&
        Array.isArray(run[activeSelect]["location"])
      ) {
        const params = {
          patient_id: activeSearchRow?.id,
          id: run[activeSelect]["appointment_type_id"],
          date: moment(run[activeSelect]["date"]).format("YYYY-MM-DD"),
          type: run[activeSelect]["type"] == "or" ? "hospital" : run[activeSelect]["type"],
          location_id: run[activeSelect]["location_id"],
        };
        const dataSuccess = await dispatch(get_location_provider(params));

        if (dataSuccess && dataSuccess?.location && dataSuccess?.time) {
          if ((dataSuccess?.provider || []).some((r) => r?.id == appointments[activeSelect]["provider_id"])) {
            run[activeSelect]["provider_id"] = appointments[activeSelect]["provider_id"] ?? null;
          } else {
            run[activeSelect]["provider_id"] = null;
          }
          run[activeSelect]["provider"] = dataSuccess?.provider;
          const checkRun = run.map((r) => {
            return {
              ...r,
              provider_name: r?.provider && Array.isArray(r?.provider) ? (r?.provider).find((pr) => pr?.id == r.provider_id) : {},
            };
          });
          setAppointments([...checkRun]);
        }
      } else {
        run[activeSelect]["location"] = null;
        run[activeSelect]["location_id"] = null;
        run[activeSelect]["provider"] = null;
        run[activeSelect]["provider_id"] = null;
        run[activeSelect] = {
          ...run[activeSelect],
          timeDisable: [],
        };
        setAppointments([...run]);
      }
    }
  };

  useEffect(() => {
    fetchApiGetLocationProvider();
    fetchAppointmentType();
  }, [run, activeSearchRow]);

  const [sortOptions, setSort] = useState([
    { name: "date", direction: "" },
    { name: "time", direction: "" },
    { name: "type", direction: "" },
    { name: "location", direction: "" },
    { name: "provider", direction: "" },
  ]);

  useEffect(() => {
    if (appointments && appointments[activeSelect]) {
      if (
        appointments[activeSelect]["location_id"] &&
        appointments[activeSelect]["location_id"] !== "" &&
        appointments[activeSelect]["type"] === ""
      ) {
        const check = locationsAll.find((r) => r?.id === appointments[activeSelect]["location_id"]);
        if (check && check.providers_unique) {
          if (check.type === "clinic") {
            const procefind = procedureData?.filter((r) => r?.value === "Clinic");
            const proce = (procefind || []).map((r) => {
              return {
                ...r,
                value: r?.value,
                type: "or",
                label: "Procedure",
              };
            });
            setProcList(proce);
            const ultra = (untrasound_list || []).map((r) => {
              return {
                ...r,
                value: r?.name,
                type: "ultrasound",
                label: "Ultrasound",
              };
            });
            setUltrasoundList(ultra);
            setAngioList([]);
          } else if (check.type === "hospital") {
            const procefind = procedureData?.filter((r) => r.type === "or" && r?.value !== "Clinic");
            const proce = (procefind || []).map((r) => {
              return {
                ...r,
                value: r?.value,
                type: "or",
                label: "Procedure",
              };
            });
            setProcList(proce);
            setUltrasoundList([]);
            setAngioList([]);
          } else if (check.type === "vhc") {
            const ultra = (untrasound_list || []).map((r) => {
              return {
                ...r,
                value: r?.name,
                type: "ultrasound",
                label: "Ultrasound",
              };
            });
            setUltrasoundList(ultra);
            const angio = (angio_list || []).map((r) => {
              return {
                ...r,
                value: r?.name,
                type: "angio",
                label: "OBL Procedure",
              };
            });
            const clinic = (clinic_list || []).map((r) => {
              return {
                ...r,
                value: r?.name,
                type: "clinic",
                label: "Clinic Procedure",
              };
            });
            setAngioList(angioList);
            setProcList([]);
            setClinicList(clinicList);
          }
          setProviders(check.providers_unique);
        }
      } else if (
        appointments[activeSelect]["provider_id"] &&
        appointments[activeSelect]["provider_id"] !== "" &&
        appointments[activeSelect]["type"] === ""
      ) {
        const check = providersAll.find((r) => r?.id === appointments[activeSelect]["provider_id"]);

        if (check && check.locations_unique) {
          setLocations(check.locations_unique);
          const angio_find = (check.procedure_types || []).filter((r) => r.type === "angio");
          const angio = (angio_find || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "angio",
              label: "OBL Procedure",
            };
          });
          setAngioList(angio);
          const procefind = (check.procedure_types || []).filter((r) => r.type === "or" || r.type === "clinic");
          const proce = (procefind || []).map((r) => {
            return {
              ...r,
              value: r?.value,
              type: "or",
              label: "Procedure",
            };
          });
          setProcList(proce);
          const ultra = (untrasound_list || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "ultrasound",
              label: "Ultrasound",
            };
          });
          setUltrasoundList(ultra);
          const clinic = (clinic_list || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "clinic",
              label: "Clinic Procedure",
            };
          });
          setClinicList(clinic_list);
        }
      } else if (appointments[activeSelect]["type"] && appointments[activeSelect]["type"] === "or") {
        if (appointments[activeSelect]["appointment_type_id"]) {
          const check = procedureData?.find((r) => r?.id == appointments[activeSelect]["appointment_type_id"]);
          if (check && check["locations"]) {
            setLocations(check["locations"]);
          }
          if (check && check["providers"]) {
            setProviders(check["providers"]);
          }
        }
      } else if (appointments[activeSelect]["type"] && appointments[activeSelect]["type"] == "angio") {
        if (appointments[activeSelect]["appointment_type_id"]) {
          const check = angio_list.find((r) => r?.id == appointments[activeSelect]["appointment_type_id"]);
          if (check && check["locations"]) {
            setLocations(check["locations"]);
          }
          if (check && check["providers"]) {
            setProviders(check["providers"]);
          }
        }
      } else {
        setLocations(locationsAll);
        setProviders(providersAll);
      }
    }
  }, [appointments, procedureData, activeSelect]);

  useEffect(() => {
    const ultra = (untrasound_list || []).map((r) => {
      return {
        ...r,
        value: r?.name,
        type: "ultrasound",
        label: "Ultrasound",
      };
    });
    const angio = (angio_list || []).map((r) => {
      return {
        ...r,
        value: r?.name,
        type: "angio",
        label: "OBL Procedure",
      };
    });
    const proce = (procedureData || []).map((r) => {
      return {
        ...r,
        value: r?.value,
        type: "or",
        label: "Procedure",
      };
    });
    const clinic = (clinic_list || []).map((r) => {
      return {
        ...r,
        value: r?.name,
        type: "clinic",
        label: "Clinic Procedure",
      };
    });
    setDataSelect([...proce, ...ultra, ...angio, ...clinic]);
    setProcList(proce);
    setAngioList(angio);
    setUltrasoundList(ultra);
    setClinicList(clinic);
  }, [untrasound_list, angio_list, procedureData, clinic_list]);

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    dispatch(setDashboardAppointments("patient", null));
    dispatch(setDashboardAppointments("appointments", []));
    // dispatch(getProcedureData());
    // dispatch(getLocations(null, null, "apppddkmf"));
    dispatch(getUltrasounds());
    dispatch(get_angio_type());
    dispatch(list_clinic_procedure_type());
  }, []);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      fetchPatients(search);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (ref2 && ref2.current) {
      const initialSize = {
        width: ref2.current.clientWidth,
        height: ref2.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref2]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const handleSort = (sorts) => {
    const newSorts = [...sortOptions];
    sorts.forEach((sort, index) => (newSorts[index].direction = sort));
    setSort(newSorts);
    fetchAppointments(getRequestParams(patient));
  };

  const saveAppointments = () => {
    // ToDo: Mike should work with backend API
    setTimeout(() => {
      setSaveAble(false);
    }, 1000);
  };

  const toggleEdit = () => {
    if (!saveAble) {
      setEditable(!editable);
    } else {
      saveAppointments();
    }
  };

  const toggleSave = async () => {
    if (
      appointments &&
      Array.isArray(appointments) &&
      appointments.length > 0 &&
      appointments.some(
        (r) =>
          r.appointment_date == null ||
          r.appointment_type_id == null ||
          r.date == null ||
          r.location_id == null ||
          r.patient_id == null ||
          r.provider_id == null ||
          r.time == null ||
          r.type == null
      )
    ) {
    } else {
      setLoading(true);
      const params = appointments.map((r) => {
        return {
          appointment_date: `${r["date"]} ${r["time"]}`,
          date: r["date"] ?? "",
          time: r["time"] ?? "",
          appointment_type_id: r["appointment_type_id"] ?? "",
          location_id: r["location_id"] ?? "",
          provider_id: r["provider_id"] ?? "",
          patient_id: r?.patient_id,
          appointment_id: r?.id,
          type: r["type"],
          comment: "",
          side: r["side"],
        };
      });
      const saveSuccess = await dispatch(saveBulkAppointment({ appointments: params }));
      if (saveSuccess) {
        dispatch(
          setGlobalToastr({
            header: "New Appointment Manager",
            message: `Save Appointment Successfully!`,
            type: "success",
          })
        );
        await fetchAppointments(getRequestParams(activeSearchRow));
        setEditable(false);
        setSaveAble(false);
        setLoading(false);
      }
    }
  };

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      setActiveSelect(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const size = useWindowSize(ref2);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = 5;
  }
  if (size.height) {
    rowCount = Math.floor(size.height / (16 * 2.5));
  } else {
    rowCount = Math.floor(initialSize.height / (16 * 2.5));
  }

  const fetchPatients = async (query = "") => {
    setLoading(true);
    const result = await getPatients(query);

    if (result && result?.length > 0) {
      setPatients(result);
      setLoading(false);
    } else {
      setPatients([]);
      setLoading(false);
    }
  };

  const fetchAppointments = async (params) => {
    const result = await getPatientAppointment(params);
    if (result) {
      const data = result.map((item) => ({
        ...item,
        date: item.appointment_date && moment(item.appointment_date).format("MM/DD/YYYY"),
        time: item.appointment_date && moment(item.appointment_date).format("h:mm A"),
      }));
      setAppointments(data);
      setRun(data);
      dispatch(setDashboardAppointments("appointments", data));
    } else {
      setAppointments([]);
      setRun([]);
      dispatch(setDashboardAppointments("appointments", []));
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target?.value);
    setOpen(true);
  };

  const handleDetele = async (value, index) => {
    if (value?.id) {
      const deleteSuccess = await dispatch(deleteProcedure(value?.id));
      if (deleteSuccess) {
        await fetchAppointments(getRequestParams(activeSearchRow));
      }
    }
  };

  const handleSearchSelect = (row, index) => {
    setActiveSearch(index);
    setSearch(`${row?.first_name || ""} ${row?.last_name || ""} - ${row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}`);
    setOpen(false);
    setActiveSearchRecords(row);
    handleAddHistory(row);
  };

  const getRequestParams = (record) => {
    const request = {
      patient_id: record?.id,
      appointment_date: moment().format("MM/DD/YYYY"),
      columns: sortOptions.filter((opt) => opt.direction),
    };
    return request;
  };

  const handleAddHistory = (searchRow = null) => {
    setActiveRow(0);
    fetchAppointments(getRequestParams(searchRow || activeSearchRow));
    dispatch(setDashboardAppointments("patient", searchRow || activeSearchRow));
    // setActiveSearchRecords(null);
    setActiveTitle(search);
  };

  const handleAddAppointment = () => {
    const newAppointment = {
      key: Date.now(),
      appointment_date: undefined,
      date: undefined,
      time: undefined,
      appointment_type_id: undefined,
      location: null,
      location_id: null,
      provider: null,
      provider_id: null,
      type: "",
      comment: "",
      timeDisable: [],
      patient_id: activeSearchRow?.id,
      side: "",
    };
    setActiveSelect(-1);
    setAppointments([newAppointment, ...appointments]);
    setRun([newAppointment, ...appointments]);
    setEditable(true);
  };

  const updateAppointments = async (type, index, value) => {
    const currentAppointments = [...appointments];
    if (type === "date") {
      currentAppointments[index].date = value;
      currentAppointments[index].appointment_date = value + currentAppointments[index].time ? ` ${currentAppointments[index].time}` : "";
      currentAppointments[index]["appointment_type_id"] = null;
      currentAppointments[index]["location_id"] = null;
      currentAppointments[index]["provider_id"] = null;
      currentAppointments[index]["location"] = null;
      currentAppointments[index]["provider"] = null;
      currentAppointments[index]["procedure_name"] = null;
      setSaveAble(true);
      setAppointments(currentAppointments);
      setRun(currentAppointments);
    } else if (type === "time") {
      currentAppointments[index].time = value;
      currentAppointments[index].appointment_date = currentAppointments[index].date && currentAppointments[index].date + " " + value;
      setSaveAble(true);
      setAppointments(currentAppointments);
    } else if (type === "appointment_type_id") {
      const check = value ? value.split("-") : "";
      currentAppointments[index][type] = check[0];
      currentAppointments[index]["type"] = check[1];
      currentAppointments[index]["location_id"] = null;
      currentAppointments[index]["provider_id"] = null;
      currentAppointments[index]["location"] = null;
      currentAppointments[index]["provider"] = null;
      currentAppointments[index]["procedure_name"] = check[2] || null;
      setSaveAble(true);
      setAppointments(currentAppointments);
      setRun(currentAppointments);
    } else if (type === "side") {
      currentAppointments[index][type] = value;
      setSaveAble(true);
      setAppointments(currentAppointments);
    } else if (type === "location_id") {
      currentAppointments[index][type] = value;
      currentAppointments[index]["provider_id"] = null;
      setSaveAble(true);
      setRun(currentAppointments);
      setAppointments(currentAppointments);
    } else {
      currentAppointments[index][type] = value;
      setSaveAble(true);
      setAppointments(currentAppointments);
      setRun(currentAppointments);
    }
  };

  const removeAppointment = (idx) => {
    const currentAppointments = [...appointments];
    currentAppointments.splice(idx, 1);
    setAppointments(currentAppointments);
    setRun(currentAppointments);
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleKeyPress = async () => {
    if (activeSearchRow) {
      setActiveRow(0);
      fetchAppointments(getRequestParams(activeSearchRow));
      dispatch(setDashboardAppointments("patient", activeSearchRow));
      setActiveTitle(search);
      setSearch(
        `${activeSearchRow?.first_name || ""} ${activeSearchRow?.last_name || ""} - ${
          activeSearchRow?.date_of_birth && moment(activeSearchRow?.date_of_birth).format("MM/DD/YYYY")
        }`
      );
    }
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (rowData && rowData[activeSearch + 1]) {
        setActiveSearchRecords(rowData[activeSearch + 1]);
        setActiveSearch(activeSearch + 1);
      }
    } else {
      setActiveSearch(activeSearch - 1);
      setActiveSearchRecords(rowData[activeSearch - 1]);
    }
  };

  const handleOption = (row, value, index, type) => {
    const apint = [...appointments];
    if (type == "location") {
      apint[index]["location_id"] = value;
      if (row["timeDisable"] && row["timeDisable"][index]) {
        apint[index]["timeDisable"] = row["timeDisable"][index];
      }
      setSaveAble(true);
    } else {
      apint[index]["provider_id"] = value;
      setSaveAble(true);
    }
    setAppointments([...apint]);
  };

  const onCommentChange = (index, value) => {
    const currentAppointments = [...appointments];
    currentAppointments[index]["comment"] = value;
    setAppointments(currentAppointments);
    setRun(currentAppointments);
  };

  const saveComment = async () => {
    setLoading(true);
    const params = appointments.map((r) => {
      return {
        appointment_date: `${r["date"]} ${r["time"]}`,
        date: r["date"] ?? "",
        time: r["time"] ?? "",
        appointment_type_id: r["appointment_type_id"] ?? "",
        location_id: r["location_id"] ?? "",
        provider_id: r["provider_id"] ?? "",
        patient_id: r?.patient_id,
        appointment_id: r?.id,
        type: r["type"],
        comment: r["comment"],
        side: r["side"],
      };
    });
    const saveSuccess = await dispatch(saveBulkAppointment({ appointments: params }));
    if (saveSuccess) {
      await fetchAppointments(getRequestParams(activeSearchRow));
      setLoading(false);
    }
  };

  useEffect(() => {
    let sideCompare = {};
    (appointments || []).map((r, index) => {
      switch (r?.type) {
        case "angio":
          sideCompare = {
            ...sideCompare,
            [index]: (angioList.find((f) => f?.id == r?.appointment_type_id) || {})?.side === 1 ? true : false,
          };
          break;
        case "or":
          sideCompare = {
            ...sideCompare,
            [index]: (procList.find((f) => f?.id == r?.appointment_type_id) || {})?.side === 1 ? true : false,
          };
          break;
        default:
          sideCompare = {
            ...sideCompare,
            [index]: false,
          };
          break;
      }
    });
    setShowSide(sideCompare);
  }, [appointments, procList, angioList]);

  const toCamelCase = (fn, ln) => {
    const fnFtLt = fn?.slice(0, 1).toUpperCase();
    const fnAllLt = fn?.slice(1).toLowerCase();
    const firstName = fnFtLt + fnAllLt;
    const lnFtLt = ln?.slice(0, 1).toUpperCase();
    const lnAllLt = ln?.slice(1).toLowerCase();
    const lastName = lnFtLt + lnAllLt;
    return firstName + " " + lastName;
  };

  return (
    <CardBlock
      title={`Appointment Manager`}
      other="dashboard"
      varient={varient}
      onClick={onCardClick}
      subAction={
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {patient && appointments && appointments.length > 0 && (
            <button
              className="text-btn"
              id={saveAble ? "save-button" : ""}
              style={{
                marginLeft: "1rem",
                color: saveAble || editable ? "red" : "",
              }}
              onClick={() => {
                if (saveAble && editable) {
                  toggleSave();
                } else {
                  toggleEdit();
                }
              }}
            >
              {saveAble && editable ? "Save" : "Edit"}
              {saveAble || editable ? " +" : " -"}
            </button>
          )}
          {patient && appointments && (
            <button className="text-btn" style={{ marginLeft: "1rem" }} onClick={handleAddAppointment} disabled={!activeTitle}>
              Add +
            </button>
          )}
        </div>
      }
    >
      <div className="patientSearch" id="outClick">
        <div className="patientSearch__filter">
          <div ref={ref} className="patientSearch__filter-search">
            <Search
              value={search}
              id="search_appoiment"
              placeholder="Search Patient"
              onChange={handleSearch}
              onKeyDownCapture={(e) => {
                if (e.keyCode == 40) {
                  onKeyDown("down");
                } else if (e.keyCode == 38) {
                  onKeyDown("up");
                }
              }}
              onPressEnter={handleKeyPress}
              style={{ width: "100%" }}
              suffix={<SearchSVG />}
              onFocus={() => setOpen(true)}
            />
          </div>
        </div>
        {openPanel && search && rowCount !== NaN && rowData?.length > 0 && (
          <div ref={ref1} className="fullContainer patientSearch__result" style={rowData?.length <= rowCount - 1 ? { height: "auto" } : {}}>
            <div
              className="tableSection"
              style={{
                height: rowData?.length <= rowCount - 1 ? "auto" : "100%",
                position: "relative",
              }}
            >
              <div className="tableContainer">
                <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll} disableScroll={rowData?.length <= rowCount - 1}>
                  <div className="tableList">
                    {rowData?.map((row, index) => (
                      <div
                        style={{ zIndex: 100 }}
                        className={`tableItem ${index === activeSearch ? "active" : ""}`}
                        key={`resultTable-${index}`}
                        onClick={() => handleSearchSelect(row, index)}
                      >
                        <div
                          className="td with-icon"
                          style={{
                            width: "100%",
                            paddingLeft: "calc(1.75rem - 2px)",
                          }}
                        >
                          <p>{`${toCamelCase(row?.first_name || "", row?.last_name || "")} - ${
                            row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                          }`}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </ScrollWrapper>
              </div>
            </div>
          </div>
        )}
        <div className="fullContainer" style={{ padding: "1rem 0 0 0", position: "relative", flex: 1 }}>
          {appointments && appointments.length > 0 && (
            <DataTable
              title="appointment_manager"
              labels={["Date", "Appointment Type", "Side", "Location", "Provider", "Time"]}
              widths={["12%", "22%", "13%", "17%", "17%", "19%"]}
              isNormal
              columns={[
                { sortable: true, key: "date" },
                { sortable: true, key: "appointment_type" },
                { sortable: false, key: "side" },
                { sortable: true, key: "location" },
                { sortable: true, key: "provider" },
                { sortable: true, key: "time" },
                { sortable: false, key: "commentBox" },
              ]}
              handleClickRow={(value, index) => {
                setActiveSelect(index);
              }}
              handleDelete={(value, index) => {
                handleDetele(value, index);
                removeAppointment(index);
              }}
              handleSort={handleSort}
              rowData={(appointments || []).map((item, index) => {
                return {
                  key: item.key,
                  id: item?.id,
                  side: editable ? (
                    <React.Fragment>
                      {showSide[index] ? (
                        <div
                          style={{
                            padding: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Select
                            disabled={!item?.date || (!item.appointment_type_id && !item.type)}
                            placeholder="Side"
                            id="side_appoiment"
                            suffixIcon={<ExpandSVG />}
                            value={item?.side || undefined}
                            allowClear={true}
                            onChange={(value) => {
                              if (value) {
                                updateAppointments("side", index, value);
                              } else {
                                updateAppointments("side", index, "");
                              }
                            }}
                            className={(!item.appointment_type_id && !item.type) || !item.date || item?.location_id ? "" : "input-error"}
                            style={{
                              border: (!item.appointment_type_id && !item.type) || !item.date || item?.location_id ? "0px" : "2px solid #FC0D1B",
                              width: "100%",
                              height: "2rem",
                            }}
                          >
                            <Option value={""}>None</Option>
                            <Option id="left_side" value={"Left"}>
                              Left
                            </Option>
                            <Option value={"Right"}>Right</Option>
                            <Option value={"Bilateral"}>Bilateral</Option>
                          </Select>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : showSide[index] ? (
                    item.side
                  ) : null,
                  date: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DatePicker
                        id="date_appoiment"
                        onChange={(value) => {
                          updateAppointments("date", index, moment(value).format("MM/DD/YYYY"));
                        }}
                        className={item?.date && item?.date != "Invalid date" && item?.date != null && item?.date.length > 0 ? "" : "input-error"}
                        style={{
                          width: "100%",
                          border:
                            item?.date && item?.date != "Invalid date" && item?.date != null && item?.date.length > 0 ? "0px" : "2px solid #da1e28",
                        }}
                        defaultValue={item.date && moment(item.date, "MM/DD/YYYY")}
                        format={"MM/DD/YYYY"}
                        placeholder="Date"
                        suffixIcon={<ExpandSVG />}
                      />
                    </div>
                  ) : (
                    item.date
                  ),
                  appointment_type: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        placeholder="Type"
                        id="type_appoiment"
                        suffixIcon={<ExpandSVG />}
                        value={
                          item?.procedure_name
                            ? item?.procedure_name
                            : item.appointment_type_id && item.type
                            ? `${item.appointment_type_id}-${item.type}`
                            : undefined
                        }
                        allowClear={true}
                        onChange={(value) => {
                          if (value) {
                            updateAppointments("appointment_type_id", index, value);
                          } else {
                            updateAppointments("appointment_type_id", index, "");
                          }
                        }}
                        className={(item.appointment_type_id && item.type) || !item.date ? "" : "input-error"}
                        style={{
                          border: (item.appointment_type_id && item.type) || !item.date ? "0px" : "2px solid #FC0D1B",
                          width: "100%",
                          height: "2rem",
                        }}
                        disabled={!item?.date}
                      >
                        <OptGroup label="Hospital Procedure">
                          {(procList || []).map((type, indx) => (
                            <Option
                              id={`procedure_${indx}`}
                              value={`${type?.id}-${type?.type}-${type?.value}`}
                              key={`${type?.id}-${index}-${type?.type}`}
                            >
                              {type?.value}
                            </Option>
                          ))}
                        </OptGroup>
                        <OptGroup label="Ultrasound">
                          {(ultrasoundList || []).map((type) => (
                            <Option value={`${type?.id}-${type?.type}-${type?.value}`} key={`${type?.id}-${index}-${type?.type}`}>
                              {type?.value}
                            </Option>
                          ))}
                        </OptGroup>
                        <OptGroup label="OBL Procedure">
                          {(angioList || []).map((type, indx) => (
                            <Option id={`obl_${indx}`} value={`${type?.id}-${type?.type}-${type?.value}`} key={`${type?.id}-${index}-${type?.type}`}>
                              {type?.value}
                            </Option>
                          ))}
                        </OptGroup>
                        <OptGroup label="Clinic Procedure">
                          {(clinicList || []).map((type) => (
                            <Option value={`${type?.id}-${type?.type}-${type?.value}`} key={`${type?.id}-${index}-${type?.type}`}>
                              {type?.value}
                            </Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </div>
                  ) : (
                    item?.procedure_name
                  ),
                  location: editable ? (
                    <React.Fragment>
                      {item?.location && Array.isArray(item?.location) ? (
                        <div
                          style={{
                            padding: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Select
                            disabled={!item?.date || (!item.appointment_type_id && !item.type)}
                            placeholder="Location"
                            id="location_appoiment"
                            suffixIcon={<ExpandSVG />}
                            value={item?.location_id || undefined}
                            allowClear={true}
                            onChange={(value) => {
                              if (value) {
                                updateAppointments("location_id", index, value);
                              } else {
                                updateAppointments("location_id", index, "");
                              }
                            }}
                            className={(!item.appointment_type_id && !item.type) || !item.date || item?.location_id ? "" : "input-error"}
                            style={{
                              border: (!item.appointment_type_id && !item.type) || !item.date || item?.location_id ? "0px" : "2px solid #FC0D1B",
                              width: "100%",
                              height: "2rem",
                            }}
                          >
                            {(item?.location || []).map((type, idx) => (
                              <Option id={`location_${idx}`} value={type?.id} key={type?.id}>
                                {type?.value}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Input
                            disabled={!item?.date || (!item.appointment_type_id && !item.type)}
                            placeholder="Please pick date and apoiment time"
                            id="time_appoiment"
                            value={(item?.location && item?.location?.value) || (item?.location_name && item?.location_name?.value)}
                            style={{
                              border: 0,
                              width: "100%",
                              height: "2rem",
                            }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    (item?.location && item?.location?.value) || (item?.location_name && item?.location_name?.value)
                  ),
                  provider: editable ? (
                    <React.Fragment>
                      {item?.provider && Array.isArray(item?.provider) ? (
                        <div
                          style={{
                            padding: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Select
                            disabled={!item?.date || (!item?.appointment_type_id && !item?.type) || !item?.location_id}
                            placeholder="Provider"
                            id="provider_appoiment"
                            suffixIcon={<ExpandSVG />}
                            value={item?.provider_id || undefined}
                            allowClear={true}
                            onChange={(value) => {
                              handleOption(item, value, index, "provider");
                            }}
                            className={
                              (!item.appointment_type_id && !item.type) || !item.date || !item?.location_id || item?.provider_id ? "" : "input-error"
                            }
                            style={{
                              border:
                                (!item.appointment_type_id && !item.type) || !item.date || !item?.location_id || item?.provider_id || !item.date
                                  ? "0px"
                                  : "2px solid #FC0D1B",
                              width: "100%",
                              height: "2rem",
                            }}
                          >
                            {(item?.provider || []).map((type, idxt) => (
                              <Option id={`${idxt}_click`} value={type?.id} key={type?.id}>
                                {type?.value}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Input
                            disabled={!item?.date || (!item?.appointment_type_id && !item?.type) || !item?.location_id}
                            value={(item?.provider && item?.provider?.value) || (item?.provider_name && item?.provider_name?.value)}
                            id="time_appoiment"
                            placeholder="Please pick date and apoiment time"
                            style={{
                              border: 0,
                              width: "100%",
                              height: "2rem",
                            }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    (item?.provider && item?.provider?.value) || (item?.provider_name && item?.provider_name?.value)
                  ),
                  time: editable ? (
                    <div
                      style={{
                        padding: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TimePicker
                        id="time_appoiment"
                        disabled={!item?.date || !item?.location_id || !item?.provider_id}
                        format={"h:mm A"}
                        use12Hours
                        placeholder="Time"
                        className={[
                          "ant-time-picker",
                          (!item.appointment_type_id && !item.type) || !item.date || !item?.location_id || !item?.provider_id || item?.time
                            ? ""
                            : "input-error",
                        ].join(" ")}
                        style={{
                          border:
                            (!item.appointment_type_id && !item.type) || !item.date || !item?.location_id || !item?.provider_id || item?.time
                              ? "0px"
                              : "",
                          maxWidth: "100%",
                          width: "100%",
                          height: "2rem",
                          background: "transparent",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                        }}
                        suffixIcon={<span />}
                        value={item.time && item.time != "Invalid date" && moment(item.time, "h:mm A")}
                        disabledHours={() => item?.timeDisable || []}
                        minuteStep={15}
                        onChange={(value) => updateAppointments("time", index, moment(value).format("h:mm A"))}
                      />
                    </div>
                  ) : (
                    item.time
                  ),
                  commentBox: (
                    <div
                      className="comment-box"
                      onBlur={() => setCommentEditing(-1)}
                      style={{
                        display: isCommentEditing === index ? "inline-block" : "none",
                      }}
                    >
                      <div className="comment-close-icon" onClick={() => setCommentEditing(-1)}>
                        <CloseIconSVG />
                      </div>
                      <Input.TextArea
                        rows={4}
                        value={item?.comment}
                        onChange={(e) => onCommentChange(index, e.target?.value)}
                        onBlur={() => saveComment()}
                      />
                    </div>
                  ),
                };
              })}
              editable
              handleEdit={(index) => setCommentEditing(index)}
              emptyRows={new Array(4).fill(1)}
              sorts={[
                sortOptions[0].direction,
                sortOptions[1].direction,
                sortOptions[2].direction,
                sortOptions[3].direction,
                sortOptions[4].direction,
              ]}
            />
          )}
          {patient && !appointments.length && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              No Appointments
            </div>
          )}
        </div>
      </div>
    </CardBlock>
  );
};

export default AppointmentManager;

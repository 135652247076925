import { createReducer } from "redux-create-reducer";
import AT from "../actionTypes";
import ReplaceTrash from "src/helpers/ReplaceTrash";

const initialState = {
  saving: false,
  original_ROSs: [],
  ROSs: [],
  physical_examinations: [],
  original_physical_examinations: [],
  vitals: [],
  original_vitals: null,
  medicine_administration: {
    pre_op: [],
    intra_op: [],
    post_op: [],
    discharge: [],
  },
  previous_medical_histories: [],
  previous_surgical_histories: [],
  allergies: [],
  medications: [],
  allMedications: [],
  allPreviousMedicalHistories: [],
  allPreviousSurgicalHistories: [],
  allAllergies: [],
  immunizations: [],
  family_histories: [],
  social_histories: [],
  vessel_states: [],
  original_vessel_states: [],
  all_normal_ros: null,
  original_all_normal_ros: null,
  all_normal_exam: null,
  original_all_normal_exam: null,
  can_copy_from_previous_visit: null,
  copy_from_last_visit_exam: null,
  original_copy_from_last_visit_exam: null,
  copy_from_last_visit_ros: null,
  original_copy_from_last_visit_ros: null,
  medication_administration: {},
  loadingEx: false,
  chiefComplaint: "",
  pre_operative: [],
  original_pre_operative: [],
  post_operative: [],
  original_post_operative: [],
  all_normal_post_operative: false,
  all_normal_pre_operative: false,
  original_all_normal_pre_operative: false,
  original_all_normal_post_operative: false,
  discharge: [],
  original_discharge: [],
  intra: [],
  loading_intra: false,
  complication: "",
  total_contrast_load: "",
  total_radiation_time: "",
  dose_area_product: -1,
  calculated_dose_area_product: -1,
  sedation_minutes: "",
  vital_signs: [],
  text_template: null,
  cpt_codes: [],
  icd_codes: [],
  leftRightOBL: null,
  condition_plans: {},
  rvu_arr: [],
  qualified_healthcare_provider: null,
  first_sedation_time: null,
  last_sedation_time: null,
  total_sedation_time: null,
  qualified_provider_name: null,
  ignore_bp_right: 0,
  ignore_bp_left: 0,
  return_followup_plans: [],
  preoperative_drugs: [],
  right_abi_text: "",
  left_abi_text: "",
  has_wrong_physical_exam: false,
  mips: [],
};

const PATIENT_EXAMINATION_SET_DATA_SUCCEEDED = `${AT.PATIENT_EXAMINATION_SET_DATA}_SUCCEEDED`;
const RESET_VITAL_MEDICATION_SUCCEEDED = `${AT.RESET_VITAL_MEDICATION}_SUCCEEDED`;
const SET_VITAL_OG_SUCCEEDED = `${AT.SET_VITAL_OG}_SUCCEEDED`;
const SET_COPY_FROM_SUCCEEDED = `${AT.SET_COPY_FROM}_SUCCEEDED`;

const logicRender = (value, type) => {
  const mixCheck = (value || []).map((r) => {
    let commentsCheck = type ? r?.comments || `${r?.default_comments}, ` : r?.comments || "";
    if (Array.isArray(r?.values) && commentsCheck) {
      (r?.values || []).map((row) => {
        if (row?.checked) {
          commentsCheck = ReplaceTrash(commentsCheck, `${row?.replaced_value}, `, `${row?.option}, `);
          commentsCheck = ReplaceTrash(commentsCheck, `${row?.replaced_value},`, `${row?.option},`);
        } else {
          commentsCheck = ReplaceTrash(commentsCheck, `${row?.option}, `, `${row?.replaced_value}, `);
          commentsCheck = ReplaceTrash(commentsCheck, `${row?.option},`, `${row?.replaced_value},`);
        }
        // if comment has been remove all we will get again
        if (commentsCheck.includes(`${row?.option},`) || commentsCheck.includes(`${row?.replaced_value},`)) {
        } else {
          if (row?.checked) {
            commentsCheck = `${row?.option}, ${commentsCheck || ""}`;
          } else {
            commentsCheck = `${row?.replaced_value}, ${commentsCheck || ""}`;
          }
        }
      });
    }
    return {
      ...r,
      comments: ReplaceTrash(commentsCheck),
    };
  });
  return mixCheck || [];
};

const rerenderArr = (value, typeRos, TypeExam) => {
  if (value && value.length > 0) {
    if (typeRos == 1 || TypeExam == 1) {
      return logicRender(value, true);
    } else {
      // remove all
      // const mixCheck = (value || []).map((r) => {
      //   let commentsCheck = r?.comments || "";
      //   if (Array.isArray(r?.values) && commentsCheck) {
      //     (r?.values || []).map((row) => {
      //       commentsCheck = ReplaceTrash(commentsCheck, `${row?.replaced_value}, `);
      //       commentsCheck = ReplaceTrash(commentsCheck, `${row?.option}, `);
      //       //
      //       commentsCheck = ReplaceTrash(commentsCheck, `${row?.replaced_value},`);
      //       commentsCheck = ReplaceTrash(commentsCheck, `${row?.option},`);
      //     });
      //   }
      //   return {
      //     ...r,
      //     comments: commentsCheck ? ReplaceTrash(commentsCheck) : "",
      //   };
      // });
      return logicRender(value, false);
    }
  }
  return [];
};

export default createReducer(initialState, {
  [RESET_VITAL_MEDICATION_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...initialState,
    });
  },
  // intra
  [`${AT.SAVE_INTRA_OPERATIVE}_STARTED`](state, action) {
    return {
      ...state,
      loading_intra: true,
    };
  },
  [`${AT.SAVE_INTRA_OPERATIVE}_SUCCEEDED`](state, action) {
    return {
      ...state,
      loading_intra: false,
    };
  },
  [`${AT.LOAD_INTRA_OPERATIVE}_STARTED`](state, action) {
    return {
      ...state,
      loading_intra: true,
    };
  },
  [`${AT.LOAD_INTRA_OPERATIVE}_SUCCEEDED`](state, action) {
    const intra = action?.payload;
    return {
      ...state,
      intra: intra?.angio_procedures,
      loading_intra: false,
    };
  },
  //
  [`${AT.SAVE_CHIEF_COMPLAINT}_SUCCEEDED`](state, action) {
    return {
      ...state,
      loadingEx: false,
    };
  },
  [`${AT.SAVE_CHIEF_COMPLAINT}_STARTED`](state, action) {
    return {
      ...state,
      loadingEx: true,
    };
  },
  [`${AT.SAVE_APPOINTMENT_NOTES}_SUCCEEDED`](state, action) {
    return {
      ...state,
      loadingEx: false,
    };
  },
  [`${AT.SAVE_APPOINTMENT_NOTES}_STARTED`](state, action) {
    return {
      ...state,
      loadingEx: true,
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      saving: false,
      ROSs: [],
      original_ROSs: [],
      physical_examinations: [],
      original_physical_examinations: [],
      vitals: [],
      original_vitals: [],
      previous_medical_histories: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      allMedications: [],
      preoperative_drugs: [],
      allPreviousMedicalHistories: [],
      allPreviousSurgicalHistories: [],
      immunizations: [],
      family_histories: [],
      social_histories: [],
      vessel_states: [],
      original_vessel_states: [],
      pre_operative: [],
      discharge: [],
      post_operative: [],
      allAllergies: [],
      intra: [],
      all_normal_ros: null,
      all_normal_exam: null,
      original_all_normal_ros: null,
      original_all_normal_exam: null,
      chiefComplaint: "",
      can_copy_from_previous_visit: null,
      complication: "",
      total_contrast_load: "",
      total_radiation_time: "",
      dose_area_product: -1,
      calculated_dose_area_product: -1,
      sedation_minutes: "",
      text_template: null,
      right_abi_text: "",
      left_abi_text: "",
    };
  },
  [`${AT.PATIENT_EXAMINATION_LOAD_INIT}_STARTED`](state, action) {
    return {
      ...state,
      loadingEx: true,
    };
  },
  [`${AT.ANGIO_PATIENT_SNAPSHOT_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (action?.payload && Object.keys(action?.payload).length > 0) {
      const {
        immunizations,
        family_histories,
        social_histories,
        medication_administration,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        allMedications,
        allPreviousMedicalHistories,
        allPreviousSurgicalHistories,
        allAllergies,
        pre_operative,
        discharge,
        post_operative,
        all_normal_post_operative,
        all_normal_pre_operative,
        complication,
        leftRight,
        total_contrast_load,
        total_radiation_time,
        dose_area_product,
        calculated_dose_area_product,
        has_wrong_physical_exam,
        sedation_minutes,
        vital_signs,
        text_template,
        cpt_codes,
        icd_codes,
        condition_plans,
        rvu_arr,
        qualified_healthcare_provider,
        first_sedation_time,
        last_sedation_time,
        total_sedation_time,
        qualified_provider_name,
        return_followup_plans,
        preoperative_drugs,
        right_abi_text,
        left_abi_text,
        mips,
      } = action?.payload || {};

      return {
        ...state,
        complication,
        total_contrast_load,
        medication_administration,
        total_radiation_time,
        dose_area_product,
        calculated_dose_area_product,
        immunizations,
        family_histories,
        social_histories,
        leftRightOBL: leftRight,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        pre_operative,
        original_pre_operative: pre_operative,
        discharge,
        original_discharge: discharge,
        all_normal_post_operative,
        original_all_normal_post_operative: all_normal_post_operative,
        all_normal_pre_operative,
        original_all_normal_pre_operative: all_normal_pre_operative,
        post_operative,
        original_post_operative: post_operative,
        medications,
        allMedications,
        allPreviousMedicalHistories,
        allPreviousSurgicalHistories,
        allAllergies,
        vital_signs,
        text_template,
        has_wrong_physical_exam,
        cpt_codes,
        sedation_minutes,
        icd_codes,
        condition_plans,
        rvu_arr,
        qualified_healthcare_provider,
        first_sedation_time,
        last_sedation_time,
        total_sedation_time,
        qualified_provider_name,
        return_followup_plans,
        preoperative_drugs,
        right_abi_text,
        left_abi_text,
        mips,
      };
    }
    return { ...state };
  },
  [SET_VITAL_OG_SUCCEEDED](state, action) {
    return {
      ...state,
      original_vitals: action?.payload?.value || [],
    };
  },
  [SET_COPY_FROM_SUCCEEDED](state, action) {
    return {
      ...state,
      [action?.payload?.scope]: action?.payload?.value || 0,
    };
  },
  [`${AT.PATIENT_EXAMINATION_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (action?.payload && Object.keys(action?.payload).length > 0) {
      const {
        ROSs,
        physical_examinations,
        vitals,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        immunizations,
        family_histories,
        social_histories,
        ignore_bp_left,
        ignore_bp_right,
        vessel_states,
        allMedications,
        allAllergies,
        all_normal_ros,
        chiefComplaint,
        all_normal_exam,
        can_copy_from_previous_visit,
        copy_from_last_visit_ros,
        copy_from_last_visit_exam,
        condition_plans,
      } = action?.payload || {};

      return {
        ...state,
        ROSs: rerenderArr(ROSs, all_normal_ros, 0),
        physical_examinations: rerenderArr(physical_examinations, 0, all_normal_exam),
        original_ROSs: rerenderArr(ROSs, all_normal_ros, 0),
        original_physical_examinations: rerenderArr(physical_examinations, 0, all_normal_exam),
        original_vitals: null,
        vitals,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        immunizations,
        ignore_bp_left,
        ignore_bp_right,
        family_histories,
        social_histories,
        vessel_states,
        original_vessel_states: vessel_states,
        allMedications,
        allAllergies,
        all_normal_ros,
        original_all_normal_ros: all_normal_ros,
        all_normal_exam,
        original_all_normal_exam: all_normal_exam,
        can_copy_from_previous_visit,
        copy_from_last_visit_exam,
        original_copy_from_last_visit_exam: copy_from_last_visit_exam,
        copy_from_last_visit_ros,
        original_copy_from_last_visit_ros: copy_from_last_visit_ros,
        loadingEx: false,
        chiefComplaint,
        condition_plans,
      };
    }

    return {
      ...state,
    };
  },
  [`${AT.PATIENT_EXAMINATION_COPY_LAST}_SUCCEEDED`](state, action) {
    const { ROSs, physical_examinations, all_normal_exam, all_normal_ros, copy_from_last_visit_exam, copy_from_last_visit_ros, vessel_states } =
      action?.payload || {};

    return {
      ...state,
      ROSs: ROSs,
      physical_examinations: physical_examinations,
      // all_normal_exam: all_normal_exam,
      // all_normal_ros: all_normal_ros,
      copy_from_last_visit_exam,
      copy_from_last_visit_ros,
      vessel_states,
    };
  },

  [`${AT.PATIENT_EXAMINATION_SET_NOMAL_CHECK}_SUCCEEDED`](state, action) {
    const { value, type } = action?.payload || {};
    if (type === "ROS") {
      return Object.assign({}, state, {
        ...state,
        all_normal_ros: value,
      });
    } else {
      return Object.assign({}, state, {
        ...state,
        all_normal_exam: value,
      });
    }
  },
  [PATIENT_EXAMINATION_SET_DATA_SUCCEEDED](state, action) {
    const { scope, examinations, type } = action?.payload || {};
    if (scope === "ROSs") {
      return Object.assign({}, state, {
        ROSs: rerenderArr(examinations, type, 0),
      });
    } else if (scope === "physical_examinations") {
      return Object.assign({}, state, {
        physical_examinations: rerenderArr(examinations, 0, type),
      });
    } else if (scope === "previous_medical_histories") {
      return Object.assign({}, state, {
        previous_medical_histories: examinations,
      });
    } else if (scope === "previous_surgical_histories") {
      return Object.assign({}, state, {
        previous_surgical_histories: examinations,
      });
    } else if (scope === "allergies") {
      return Object.assign({}, state, {
        allergies: examinations,
      });
    } else if (scope === "medications") {
      return Object.assign({}, state, {
        medications: examinations,
      });
    } else if (scope === "immunizations") {
      return Object.assign({}, state, {
        immunizations: examinations,
      });
    } else if (scope === "family_histories") {
      return Object.assign({}, state, {
        family_histories: examinations,
      });
    } else if (scope === "social_histories") {
      return Object.assign({}, state, {
        social_histories: examinations,
      });
    } else if (scope === "vitals") {
      return Object.assign({}, state, {
        vitals: examinations,
      });
    } else if (scope === "vessel_states") {
      return Object.assign({}, state, {
        vessel_states: examinations,
      });
    } else if (scope === "all_normal_exam") {
      return Object.assign({}, state, {
        all_normal_exam: examinations,
      });
    } else if (scope === "all_normal_ros") {
      return Object.assign({}, state, {
        all_normal_ros: examinations,
      });
    }
    return Object.assign({}, state);
  },

  [`${AT.PATIENT_EXAMINATION_SAVE}_STARTED`](state, action) {
    return {
      ...state,
      saving: true,
    };
  },
  [`${AT.PATIENT_EXAMINATION_SAVE}_SUCCEEDED`](state, action) {
    const { physical_examination } = action?.payload || {};
    return {
      ...state,
      saving: false,
    };
  },
});

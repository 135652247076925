import { Checkbox, Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./PriorSidebar.scss";

import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Toastr from "../../../common/components/Toast";
import { savePriorAuthorization, setGlobalLoading, setPriorData } from "../../../store/actions";
import NotesComponent from "./Note";

const CtSidebar = React.forwardRef(({ isShow, handleCloseSidebar }, ref) => {
  const loading = useSelector((state) => state.prior?.loadingResource);
  const resource = useSelector((state) => state.prior?.resource);
  const savedResource = useSelector((state) => state.prior?.savedResoruces);
  const locations = useSelector((state) => state.common.locations);
  const dispatch = useDispatch();

  const [prior, setPrior] = useState({});
  const [status, setStatus] = useState(-1);
  const [sendFax, setSendFax] = useState(-1);
  const [timer, setTimer] = useState(0);
  const [noauthorization, setNoauthorization] = useState(-1);
  const [reset, setReset] = useState(false);
  const [expirationError, setExpirationError] = useState(null);
  const [authCodeError, setAuthCodeError] = useState(null);

  const isToastr = useMemo(() => {
    if (savedResource) {
      setTimer(0);
      setTimeout(() => {
        setTimer(600);
      }, 2000);
      return true;
    }
    return false;
  }, [savedResource]);

  useEffect(() => {
    if (resource && resource?.id !== prior?.id) {
      setPrior({
        ...resource,
        date_of_birth: resource.date_of_birth ? moment(resource.date_of_birth).format("MM/DD/YYYY") : undefined,
        expiration_date: resource.expiration_date ? moment(resource.expiration_date).format("MM/DD/YYYY") : undefined,
        type: resource.system_type,
        typeStudy: resource.type,
      });
      setStatus(resource.submitted_for_processing);
      if (resource.status === "No Authorization Required") setNoauthorization(1);
      else {
        setNoauthorization(0);
      }
      if (resource.status === "Order Faxed") setSendFax(1);
      else {
        setSendFax(0);
      }
      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 1000);
    }
  }, [resource]);

  const changeStatus = (e) => {
    if (e.target.checked) {
      setStatus(1);
    } else {
      setStatus(0);
    }
  };
  const changeNoauthorization = (e) => {
    if (e.target.checked) {
      setNoauthorization(1);
    } else {
      setNoauthorization(0);
    }
  };
  const changeSendFax = (e) => {
    if (e.target.checked) {
      setSendFax(1);
    } else {
      setSendFax(0);
    }
  };

  const isValid = !!prior?.authorization_number && !!prior?.expiration_date;

  const onSave = (value) => {
    if (resource) {
      dispatch(setPriorData("loadingResource", true));
      dispatch(
        savePriorAuthorization(resource?.id, {
          authorization_number: value.authorization_number,
          expiration_date: value.expiration_date,
          type: value.type,
          submitted_for_processing: status,
          no_authorization_required: noauthorization,
          location_id: value.location,
          send_fax: sendFax,
        })
      );
    }
  };

  const convertLabel = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "asap":
        return "ASAP";
      case "2_week":
        return "2 weeks";
      case "3_month":
        return "3 months";
      case "6_month":
        return "6 months";
      case "1_year":
        return "1 year";
      case "2_week_to_4_week":
        return "2-4 weeks";
      case "1_month_to_3_month":
        return "1-3 months";
      case "3_month_to_6_month":
        return "3-6 months";
      case "6_month_to_12_month":
        return "6-12 months";
    }
  };

  const changePriorValue = (type) => (value) => {
    const newPrior = { ...prior, [type]: value };
    let failOut = false;
    setPrior(newPrior);

    if (newPrior.expiration_date === "" || newPrior.expiration_date == null) {
      setExpirationError(true);
      failOut = true;
      // set expiration error
    }

    if (newPrior.authorization_number === "" || newPrior.authorization_number == null) {
      setAuthCodeError(true);
      failOut = true;
    }

    if (failOut) {
      return;
    }

    if (reset) {
      setExpirationError(false);
      setAuthCodeError(false);
      setTimeout(() => {
        onSave(newPrior);
      }, 400);
    }
  };

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        onSave(prior);
      }, 400);
    }
  }, [status, noauthorization, sendFax]);

  useEffect(() => {
    if (isShow && resource) {
      dispatch(setGlobalLoading(loading));
    }
  }, [isShow, resource, loading]);

  return (
    <Drawer title={resource?.type || " "} placement="right" closable onClose={handleCloseSidebar} visible={isShow} destroyOnClose key="right">
      <div ref={ref} className="resourceContainer new">
        {isToastr && timer < 600 && (
          <div style={{ position: "fixed", top: "60px", right: "2px" }}>
            <Toastr type="Prior Authorization" status="success" msg="Saved Successfully!" />
          </div>
        )}
        <ScrollWrapper>
          <div className="resourceContent" style={{ paddingLeft: "1.5rem", marginTop: "1.5rem" }}>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label={"Patient Name"} value={prior?.patient_name} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label={"DOB"} value={prior?.date_of_birth} noEditable={true} type="date" />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label={"Physician"} value={prior?.provider} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                isErrorTextSpacing={true}
                label={"Location"}
                value={prior?.location || undefined}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={locations || []}
                handleChange={changePriorValue("location")}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label={"Diagnosis"} value={prior?.diagnosis} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"CPT Code Repository"}
                value={prior?.cpt_code}
                noEditable={true}
                type="area"
                multiline={prior?.cpt_code?.toString().length > 40 ? true : false}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label="Type Study" value={prior?.typeStudy} noEditable />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label="Urgency" value={convertLabel(prior?.urgency)} noEditable />
            </div>
            {resource?.type.indexOf("CT") > -1 || resource?.type.indexOf("CTA") > -1 ? (
              <div className="resourceInfo d-flex">
                <EditableFormInput
                  type="area"
                  multiline
                  rows={5}
                  isErrorTextSpacing={true}
                  label={"Reason"}
                  value={prior?.reason}
                  noEditable={true}
                />
              </div>
            ) : null}
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label="Appointment Date"
                value={moment(prior.created_at).format("MM/DD/YYYY")}
                noEditable
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label="Appointment Time" value={moment(prior.created_at).format("hh:mm A")} noEditable />
            </div>
            <div
              className={`resourceInfo d-flex ${
                prior?.is_verified === 1 && prior?.is_eligible === 0
                  ? "insurance-not-verified"
                  : prior?.is_verified === 1 && prior?.is_eligible === 1
                  ? "insurance-verified"
                  : ""
              }`}
            >
              <EditableFormInput
                isErrorTextSpacing={true}
                required={true}
                isError={prior?.is_verified === 1 && prior?.is_eligible === 0}
                enableErrorText={true}
                label={"Primary Plan Name"}
                value={prior?.plan_name}
                noEditable={true}
              />
            </div>

            <div
              className={`resourceInfo d-flex ${
                prior?.is_verified === 1 && prior?.is_eligible === 0
                  ? "insurance-not-verified"
                  : prior?.is_verified === 1 && prior?.is_eligible === 1
                  ? "insurance-verified"
                  : ""
              }`}
            >
              <EditableFormInput
                isErrorTextSpacing={true}
                required={true}
                isError={prior?.is_verified === 1 && prior?.is_eligible === 0}
                enableErrorText={true}
                label={"Primary Policy Number"}
                value={prior?.policy_number}
                noEditable={true}
              />
            </div>
            <div
              className={`resourceInfo d-flex ${
                prior?.is_verified === 1 && prior?.is_eligible === 0
                  ? "insurance-not-verified"
                  : prior?.is_verified === 1 && prior?.is_eligible === 1
                  ? "insurance-verified"
                  : ""
              }`}
            >
              <EditableFormInput
                isErrorTextSpacing={true}
                isError={prior?.is_verified === 1 && prior?.is_eligible === 0}
                label={"Primary Group Number"}
                value={prior?.group_number}
                noEditable={true}
              />
            </div>
            <hr />

            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Prior Authorization Code"}
                value={prior?.authorization_number}
                handleChange={changePriorValue("authorization_number")}
                required={true}
                isError={authCodeError}
                enableErrorText={authCodeError}
                helperText={"Prior Authorization Code Required"}
                disabled={noauthorization === 1}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Expiration Date"}
                value={prior?.expiration_date}
                type="date"
                handleChange={changePriorValue("expiration_date")}
                required={true}
                isError={expirationError}
                enableErrorText={expirationError}
                helperText={"Expiration Date Required"}
                disabled={noauthorization === 1}
              />
            </div>

            {!isValid && (
              <div className="resourceInfo" style={{ marginTop: "2rem" }}>
                <Checkbox checked={status === 1} onChange={changeStatus} disabled={noauthorization === 1}>
                  Submitted for Processing
                </Checkbox>
              </div>
            )}
            <div className="resourceInfo" style={{ marginTop: "" }}>
              <Checkbox checked={noauthorization === 1} onChange={changeNoauthorization} disabled={status === 1 || isValid}>
                No Authorization Required
              </Checkbox>
            </div>
            <div className="resourceInfo" style={{ marginTop: "" }}>
              <Checkbox checked={sendFax === 1} onChange={changeSendFax}>
                Order Faxed
              </Checkbox>
            </div>
            <div
              className="resourceInfo"
              style={{
                marginTop: "1rem",
                paddingLeft: "0px",
                paddingRight: "26px",
              }}
            >
              <NotesComponent minRows={4} resource={resource} />
            </div>
          </div>
          <div className="actionContent" style={{ paddingBottom: "2rem" }} />
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default CtSidebar;

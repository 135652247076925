import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs } from "@material-ui/core";
import { Icon, Tooltip } from "antd";
import moment from "moment";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import PusherCheck from "../../../common/components/PusherCheck";
import TopBar from "../../../common/components/SubTopBar";
import Appointments from "../../../common/views/Appointment";
import Education from "../../../common/views/Education";
import Medications from "../../../common/views/Medications";
import PatientChart from "../../../common/views/PatientChart";
import PatientOverview from "../../../common/views/PatientSnapshot";
import { getLocal, setLocal } from "../../../helpers/Local";
import RemoveN from "../../../helpers/RemoveN";
import {
  clinicProviderPatientSnapshotLoadInit,
  load_procedurals,
  patientExaminationSave,
  releaseLock,
  remove_selected_patient_resource,
  resetVitalMedication,
  save_intra_operative,
  save_plan_patient,
  setActiveReviewChange,
  setFirstLoadingActive,
  setGlobalLoading,
  setGlobalToastr,
  save_procedurals,
  setProcedureReview,
  patientExaminationSetData,
  patientExaminationCopyLast,
  patientExaminationSetNomalCheck,
  setCheckRequireForReview,
  changeActiveRow,
  setActionGoNextTab,
} from "../../../store/actions";
import { TabHeaders } from "../constant";
import ClinicSchedule from "./providerSubViews/ClinicSchedule";
import Consent from "./providerSubViews/Consen";
import Discharge from "./providerSubViews/Discharge";
import IntaOperative from "./providerSubViews/IntaOperative";
import PostOperative from "./providerSubViews/PostOperative";
import PreOperative from "./providerSubViews/PreOperative";
import Review from "./providerSubViews/Review";
import RosVitals from "src/views/clinicWorkflow/components/providerSubViews/RosVitals";
import PhysicalExamination from "src/views/clinicWorkflow/components/providerSubViews/PhysicalExamination";

export default function Provider() {
  const dispatch = useDispatch();
  const firstLoadingActive = useSelector((state) => state.common.firstLoadingActive);
  const patientResource = useSelector((state) => state.patientSchedule.selectedPatientResource?.id);
  const selectedResource = useSelector((state) => state.patientSchedule.selectedPatientResource);
  const resourceCheck = useSelector((state) => state.patientSnapshot.resource);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  const editLock = useSelector((state) => state.common.editLock);
  const params_plan = useSelector((state) => state.common.params_plan);
  const activeHandleChange = useSelector((state) => state.common.activeHandleChange);
  const is_locked = useSelector((state) => state.common.is_locked);
  const intra_operations = useSelector((state) => state.procedureReview.intra_operations);
  const actionGoNextTab = useSelector((state) => state.common.actionGoNextTab);
  const [activeTab, setActiveTab] = useState(0);
  const [isSelectedPatient, setIsSelectedPatient] = useState(false);
  const [dataPre, setDataPre] = useState(null);
  const [dataRos, setDataRos] = useState(null);
  const [dataPhysical, setDataPhysical] = useState(null);
  const [checkAllPre, setCheckAllPre] = useState(false);
  const [stateStatusChange, setStateStatusChange] = useState(null);
  const [pusherCheckBuild, setPusherCheckBuild] = useState([]);
  const [pusherCheckUnfinalized, setPusherCheckUnfinalized] = useState([]);
  const [patient_id, setPatientId] = useState(null);
  const [dataPostOp, setDataPostOp] = useState(null);
  const [checkAllPostOp, setCheckAllPostOp] = useState(false);
  const [dataDis, setDataDis] = useState(null);
  const [dataCheckIntra, setDataCheckIntra] = useState(null);
  const [lock, setLock] = useState({});
  const [rosCoppy, setRosCopy] = useState(false);
  const [physicalCoppy, setPhysicalCopy] = useState(false);

  useEffect(() => {
    if (patientResource) {
      setIsSelectedPatient(true);
    } else {
      setIsSelectedPatient(false);
      setActiveTab(0);
    }
    if (firstLoadingActive) {
      setActiveTab(12);
      dispatch(setFirstLoadingActive(false));
    }
    setPatientId(getLocal("current_patient_id"));
  }, [patientResource, firstLoadingActive]);

  useEffect(() => {
    setLock(editLock);
  }, [editLock]);

  const goNextTab_ = async () => {
    dispatch(setGlobalLoading(true));
    const current_patient_id = await getLocal("current_patient_id");
    const appointment_id = await getLocal("current_appointment_id");
    dispatch(setCheckRequireForReview(false));
    await dispatch(clinicProviderPatientSnapshotLoadInit(current_patient_id, appointment_id));
    await dispatch(load_procedurals(appointment_id));
    dispatch(setGlobalLoading(false));

    goNextTab();
  };

  useEffect(() => {
    return () => {
      const procedure_id = getLocal("current_appointment_id");
      dispatch(releaseLock(procedure_id, {}));
    };
  }, []);

  const goNextTab = () => {
    setActiveTab(1);
  };

  useEffect(() => {
    if (actionGoNextTab) {
      setActiveTab(1);
      dispatch(setActionGoNextTab(false));
    }
  }, [actionGoNextTab]);

  useEffect(() => {
    if (activeHandleChange) {
      setActiveTab(0);
      dispatch(changeActiveRow(false));
    }
  }, [activeHandleChange]);

  const dataPostCheckAll = (value, checkAll) => {
    setDataPre(value);
    setCheckAllPre(checkAll);
  };

  const dataPostOpCheckAll = (value, checkAll) => {
    setDataPostOp(value);
    setCheckAllPostOp(checkAll);
  };

  const dataDisCheckAll = (value) => {
    setDataDis(value);
  };

  const renderSideComponent = () => {
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";

    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  const goNextTabReview = () => {
    setActiveTab(11);
    dispatch(setActiveReviewChange(false));
  };

  const handleTabIndex = async (index) => {
    const procedure_id = getLocal("current_appointment_id");
    if (index === 0) {
      await dispatch(releaseLock(procedure_id, {}));
      await setLocal("current_appointment_id", null);
      dispatch(remove_selected_patient_resource());
    }
    await dispatch(setGlobalLoading(true));
    if (activeTab === 6 && (is_locked === false || is_locked === 0)) {
      if (dataPre && dataPre.length > 0) {
        const fixArr = (dataPre || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        await dispatch(
          patientExaminationSave(procedure_id, {
            patient_id: patientResource?.id,
            pre_operative: fixArr,
            all_normal_pre_operative: checkAllPre,
          })
        );
        setDataPre(null);
      }
    } else if (activeTab === 8 && (is_locked === false || is_locked === 0)) {
      if (dataPostOp && dataPostOp.length > 0) {
        const fixArr = (dataPostOp || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        await dispatch(
          patientExaminationSave(procedure_id, {
            patient_id: patientResource?.id,
            post_operative: fixArr,
            all_normal_post_operative: checkAllPostOp || -1,
          })
        );
        setDataPostOp(null);
      }
    } else if (activeTab === 9 && (is_locked === false || is_locked === 0)) {
      if (dataDis && dataDis.length > 0) {
        const fixArr = (dataDis || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        await dispatch(
          patientExaminationSave(procedure_id, {
            patient_id: patientResource?.id,
            discharge: fixArr,
          })
        );
        setDataDis(null);
      }
    } else if (activeTab === 10 && (is_locked === false || is_locked === 0)) {
      await dispatch(save_plan_patient(params_plan));
      if (index === 9) {
        // Handle special logic for index 9
      }
    } else if (activeTab === 7 && (is_locked === false || is_locked === 0)) {
      const operations = intra_operations && intra_operations.length > 0 ? intra_operations : null;
      const id_appoinment = getLocal("current_appointment_id");
      if (operations && id_appoinment) {
        await dispatch(
          save_procedurals(id_appoinment, {
            procedurals: operations.filter((r) => r.medicals && r.medicals.length > 0),
          })
        );
        dispatch(setProcedureReview("intra_operations", null));
      }
      if (dataCheckIntra && dataCheckIntra.length > 0) {
        const fixArr = (dataCheckIntra || []).map((r) => {
          return {
            ...r,
            questions: (r?.questions || []).map((q) => {
              return {
                ...q,
                comments: RemoveN(q?.comments),
              };
            }),
          };
        });
        await dispatch(
          save_intra_operative(procedure_id, {
            patient_id: patient_id,
            conditions: fixArr,
          })
        );
        setDataCheckIntra(null);
      }
    } else if (activeTab === 4 && (is_locked === false || is_locked === 0)) {
      // ROS
      if (dataRos && Object.keys(dataRos).length > 0) {
        await dispatch(patientExaminationSave(procedure_id, dataRos));
        setDataRos(null);
      }
    } else if (activeTab === 5 && (is_locked === false || is_locked === 0)) {
      // Physical
      if (dataPhysical && Object.keys(dataPhysical).length > 0) {
        if (
          dataPhysical?.vessel_states &&
          !Array.isArray(dataPhysical?.vessel_states) &&
          Object.values(dataPhysical?.vessel_states).some((r) => !r)
        ) {
          const deleteVessel = { ...(dataPhysical || {}) };
          delete deleteVessel.vessel_states;
          await dispatch(patientExaminationSave(procedure_id, deleteVessel));
          dispatch(setGlobalLoading(false));
        } else {
          await dispatch(patientExaminationSave(procedure_id, dataPhysical));
        }
        // await dispatch(patientExaminationSave(procedure_id, dataPhysical));
        setDataPhysical(null);
      }
    }
    await dispatch(resetVitalMedication());
    await dispatch(setGlobalLoading(false));
    await setActiveTab(index);
  };

  const getItemBuild = (value) => {
    if (value !== null) {
      if (pusherCheckBuild.length == 0 || !pusherCheckBuild.some((r) => r == value)) {
        setPusherCheckBuild([...pusherCheckBuild, value]);
      }
    }
  };

  const dispatchAppointmentUnfinalized = (value) => {
    if (pusherCheckUnfinalized.length == 0 || !pusherCheckUnfinalized.some((r) => r == value)) {
      setPusherCheckUnfinalized([...pusherCheckUnfinalized, value]);
    }
  };

  const updateStatusPusher = (value) => {
    if (value && value.appointment_id) {
      setStateStatusChange(value);
    }
  };

  const RosChange = async (scope, data) => {
    await dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    await dispatch(setGlobalLoading(false));
  };

  const RosCoppy = async (id) => {
    await dispatch(setGlobalLoading(true));
    const procedure_id = getLocal("current_appointment_id");
    await dispatch(patientExaminationCopyLast(procedure_id, "ROS"));
    setRosCopy(true);
    await dispatch(setGlobalLoading(false));
  };

  const RosCheckAll = async (scope, data, checked) => {
    await dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    await dispatch(setGlobalLoading(false));
  };

  const PhysicalChange = async (scope, data) => {
    await dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    await dispatch(setGlobalLoading(false));
  };

  const PhysicalCoppy = async (id) => {
    await dispatch(setGlobalLoading(true));
    const procedure_id = getLocal("current_appointment_id");
    await dispatch(patientExaminationCopyLast(procedure_id, "patient_examination"));
    setPhysicalCopy(true);
    await dispatch(setGlobalLoading(false));
  };

  const PhysicalCheckAll = async (scope, data, checked) => {
    await dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    await dispatch(setGlobalLoading(false));
  };

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="OBL Module" subStatus={""} sideComponent={renderSideComponent} isBarcode={isSelectedPatient} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {TabHeaders.map((tab, index) =>
                (isSelectedPatient && index < 3 && editLock.locked === 0) ||
                (isSelectedPatient && selectedResource?.has_valid_insurance === 1 && editLock.locked === 0) ? (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                    style={{ minWidth: tab.width }}
                    onClick={() => {
                      if (activeTab !== index) {
                        handleTabIndex(index);
                      }
                    }}
                  >
                    {tab.label}
                  </div>
                ) : (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? "active" : ""} ${index > 0 && "disabled"}`}
                    style={{ minWidth: tab.width }}
                  >
                    {tab.label}
                  </div>
                )
              )}
            </Tabs>
          </div>
        </div>
        <PusherCheck
          updateStatus={updateStatusPusher}
          setIndex={() => {
            handleTabIndex(0);
          }}
          getItemBuild={getItemBuild}
          dispatchAppointmentUnfinalized={dispatchAppointmentUnfinalized}
        ></PusherCheck>
        {activeTab >= 0 && (
          <ClinicSchedule
            stateStatusProps={stateStatusChange}
            updateStatusProps={() => {
              setStateStatusChange(null);
            }}
            pusherCheckBuild={pusherCheckBuild}
            pusherCheckUnfinalized={pusherCheckUnfinalized}
            display={activeTab === 0}
            goNextTab={goNextTab_}
            goNextTabReview={goNextTabReview}
            isOblModule={true}
          />
        )}
        {activeTab === 1 && (
          <PatientOverview
            isShowGoto={false}
            sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />}
            patientResource={selectedResource}
          />
        )}
        {activeTab === 2 && (
          <PatientChart sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} patientResource={selectedResource} />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 3 && <Consent />}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 4 && (
          <RosVitals display={true} setDataRos={setDataRos} RosChange={RosChange} RosCheckAll={RosCheckAll} RosCoppy={RosCoppy} rosCoppy={rosCoppy} />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 5 && (
          <PhysicalExamination
            noClinic={true}
            display={true}
            PhysicalChange={PhysicalChange}
            PhysicalCheckAll={PhysicalCheckAll}
            PhysicalCoppy={PhysicalCoppy}
            physicalCoppy={physicalCoppy}
            isSlide={selectedResource}
            setDataPhysical={setDataPhysical}
          />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 6 && (
          <PreOperative
            dataPostCheckAll={(data, checkAll) => {
              dataPostCheckAll(data, checkAll);
            }}
          />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 7 && (
          <IntaOperative
            dataCheckIntra={(data) => {
              setDataCheckIntra(data);
            }}
          />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 8 && (
          <PostOperative
            dataPostOpCheckAll={(data, checkAll) => {
              dataPostOpCheckAll(data, checkAll);
            }}
          />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 9 && (
          <Discharge
            dataDisCheckAll={(data) => {
              dataDisCheckAll(data);
            }}
          />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 10 && (
          <PlanOrders navigateToReview={goNextTabReview} sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 11 && (
          <Review sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 12 && (
          <Medications sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
        )}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 13 && <Appointments />}
        {selectedResource?.has_valid_insurance === 1 && activeTab === 14 && (
          <Education sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} patientResource={selectedResource} />
        )}
      </div>
    </div>
  );
}

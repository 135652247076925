import { Tabs } from "@material-ui/core";
import { Icon, Tooltip } from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { FemaleSVG, MaleSVG } from "../../common/components/icons/FaxSVG";
import SubTopBar from "../../common/components/SubTopBar";
import { DependencyProcessingTabHeaders } from "../../common/tabs";
import CtAppointments from "./components/CtAppointments";
import CtFilter from "./components/CtFilter";
import CtSidebar from "./components/CtSidebar";
import PriorFilters from "./components/PriorFilters";
import PriorResultTable from "./components/PriorResultTable";
import PriorSideBar from "./components/PriorSideBar";
import "./style.scss";
import { getPatients } from "src/services/api.services";
import { getCtAppointments, getPriorAuthorization, setPriorData } from "src/store/actions";

export default function PriorAuthorizationPage() {
  const sidebarRef = useRef(null);
  const sidebarRefCt = useRef(null);
  const dispatch = useDispatch();
  const [isSidebar, setIsSidebar] = useState(false);
  const [isSidebarCt, setIsSidebarCt] = useState(false);
  const [filter, setFilter] = useState({
    type: "",
    appointment_type_id: "",
    authorization_number: "",
    date_of_service: "",
    patient_id: "",
    insurance_company_id: "",
    authorization_status: "",
    expiration_date: "",
    page: 1,
    per_page: 40,
  });
  const [rowSearch, setRowSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const resource = useSelector((state) => state.prior.resource);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleSidebarCt = () => {
    setIsSidebarCt(true);
  };

  const handleCloseSidebarCt = () => {
    setIsSidebarCt(false);
  };

  const handleSearch = (search) => {
    setSearch(search);
  };

  const valueSearch = async (value) => {
    setSearch(value);
    setLoading(true);
    const dataSearchPatient = await getPatients(value);
    setRowSearch(dataSearchPatient || []);
    setLoading(false);
  };

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  const onSelectChoice = (value, payload) => {
    const params = {
      ...filter,
      patient_id: payload?.id || "",
    };
    if (payload?.id) {
      const name = value.split(" - ")?.[0] || "";
      setSearch(encodeURIComponent(name.toLowerCase()));
      setFilter(params);
    } else {
      setFilter(params);
    }
    if (activeTab == 0) {
      dispatch(setPriorData("loadingPriors", true));
      dispatch(getPriorAuthorization(params));
    } else {
      dispatch(setPriorData("loadingPriors", true));
      dispatch(getCtAppointments(params));
    }
  };

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular | Prior Authorizations</title>
        <meta name="title" content="FAACT Vascular | Prior Authorizations" />
      </Helmet>
    );
  };

  const handleChangeTab = (index) => {
    setFilter({
      type: "",
      appointment_type_id: "",
      authorization_number: "",
      date_of_service: "",
      patient_id: "",
      insurance_company_id: "",
      authorization_status: "",
      expiration_date: "",
      page: 1,
      per_page: 40,
    });
    setSearch("");
    setRowSearch([]);
    setActiveTab(index);
  };

  const getStatus = (data) => {
    return (
      <div className="statusHealth">
        <Icon type="mail" theme="twoTone" twoToneColor="#0f62fe" />
        {data?.count_complete || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
        {data?.count_second_authorization_required || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="plus-circle" theme="twoTone" twoToneColor="#FFA500" />
        {data?.count_processing || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
        {data?.count_send_fax || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
        {data?.count_authorization_required || 0}
      </div>
    );
  };

  const getStatusCt = (data) => {
    return (
      <div className="statusHealth">
        <Icon type="mail" theme="twoTone" twoToneColor="#0f62fe" />
        {data?.count_complete || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
        {data?.count_second_authorization_required || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="plus-circle" theme="twoTone" twoToneColor="#FFA500" />
        {data?.count_processing || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
        {data?.count_send_fax || 0}
        <span style={{ minWidth: "10px", display: "inline-block" }} />

        <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
        {data?.count_authorization_required || 0}
      </div>
    );
  };

  const renderSideComponent = () => {
    if (!resource || !Object.keys(resource).length) {
      return null;
    }

    const fullName = resource.patient_name.toUpperCase();
    const age = resource.date_of_birth ? "(" + moment().diff(resource.date_of_birth, "years") + ")" : "";

    const allerrgies = [];
    if (resource.allergies) {
      resource.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resource.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resource.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resource.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={resource.allergies.map((allergy) => allergy.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {resource.gender && resource.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  const priorsAll = useSelector((state) => state.prior.priors);
  const ctAppointmentsAll = useSelector((state) => state.prior.ct_appointments);
  const isResource = !resource || !Object.keys(resource).length;

  return (
    <div className="priorAuthorizationPage">
      {renderMetadata()}
      <SubTopBar
        title="Dependency Processing"
        subStatus={activeTab === 0 ? getStatus(priorsAll) : getStatusCt(ctAppointmentsAll)}
        noLeftSide={true}
        sideComponent={renderSideComponent()}
        isBarcode={!isResource}
      />
      <div className={`clinicWorkflow__main-container`}>
        <div className="clinicWorkflow__main-subTitle tabContainer" style={{ paddingTop: "1.5rem", paddingLeft: "5%" }}>
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {DependencyProcessingTabHeaders.map((tab, index) => {
                return (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item
                        ${activeTab === index ? "active" : ""}
                      `}
                    style={{
                      width: tab.width,
                      marginLeft: index !== 0 ? "3rem" : "",
                    }}
                    onClick={() => {
                      handleChangeTab(index);
                    }}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </Tabs>
          </div>
        </div>

        <PriorSideBar ref={sidebarRef} isShow={isSidebar} handleCloseSidebar={handleCloseSidebar} />

        <CtSidebar ref={sidebarRefCt} isShow={isSidebarCt} handleCloseSidebar={handleCloseSidebarCt} />
        <div className="main">
          <div className={`mainContainer ${isResource || !isSidebar || !isSidebarCt ? "no-sidebar" : ""}`}>
            {activeTab == 0 && (
              <PriorFilters
                dataOption={rowSearch}
                loading={loading}
                onSelectChoice={onSelectChoice}
                searchState={search}
                valueSearch={(value) => valueSearch(value)}
                filter={filter}
                handleChangeFilter={handleFilter}
                handleSearch={handleSearch}
              />
            )}
            {activeTab == 1 && (
              <CtFilter
                dataOption={rowSearch}
                loading={loading}
                onSelectChoice={onSelectChoice}
                searchState={search}
                valueSearch={(value) => valueSearch(value)}
                filter={filter}
                handleChangeFilter={handleFilter}
                handleSearch={handleSearch}
              />
            )}
            <div className="tableSection">
              {activeTab == 0 && <PriorResultTable title="result" filter={filter} search={search} handleSidebar={handleSidebar} />}
              {activeTab == 1 && <CtAppointments title="result" filter={filter} search={search} handleSidebar={handleSidebarCt} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOnlyProviders, getProcedureEvents, getProcedureLocations, setGlobalLoading, setProcedureData } from "../../store/actions";
import ClinicalCalendar from "./components/ClinicalCalendar";

const ProcedureCalendar = (props) => {
  const events = useSelector((state) => state.procedure.events);
  const activeOpenings = useSelector((state) => state.procedure.activeOpenings);
  const closedDates = useSelector((state) => state.procedure.closedDates);
  const openingTime = useSelector((state) => state.procedure.openingTime);
  const closingTime = useSelector((state) => state.procedure.closingTime);
  const locations = useSelector((state) => state.common.procedureLocations);
  const providers = useSelector((state) => state.common.only_providers);
  const dispatch = useDispatch();

  const fetchApi = async (value) => {
    dispatch(setGlobalLoading(true));
    if (value) {
      if (!value?.locations) {
        await dispatch(getProcedureLocations());
      }
      if (!value?.providers) {
        await dispatch(getOnlyProviders());
      }
    }
    dispatch(setProcedureData("resource", null));
    dispatch(setProcedureData("loadingEvents", true));
    if (value?.date) {
      await dispatch(getProcedureEvents(value));
    } else {
      const date = moment(new Date()).format("MM/DD/YYYY");
      await dispatch(getProcedureEvents({ date }));
    }
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const handleEvents = (date, location, provider) => {
    fetchApi({ date, location, provider });
  };

  const onClickEvent = (event) => {
    if (props.onHandleClick) {
      props.onHandleClick(event);
    }
  };

  return (
    <div className="calendarContainer" style={{ position: "relative", left: 10, right: 10 }}>
      <ClinicalCalendar
        activeID={props?.activeID}
        onClickEvent={onClickEvent}
        eventData={events || []}
        fetchEvents={handleEvents}
        locations={locations || []}
        providers={providers || []}
        activeOpenings={activeOpenings || []}
        closedDates={closedDates || []}
        openingTime={openingTime || ""}
        closingTime={closingTime || ""}
      />
    </div>
  );
};

export default ProcedureCalendar;

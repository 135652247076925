import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { VeinLabels } from "src/common/constants/Vein";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { DevicesSVG } from "../../../../common/components/icons/SVGIcon";
import ArteryLabels, { getArteryLabel } from "../../../../common/constants/Artery";
import { getMedical } from "../../../../common/constants/Operations";
import "./style.scss";

const AccessDetails = ({
  icon,
  title,
  data,
  items,
  inventories,
  activeItem,
  activeIndex,
  handleSubmit,
  removeItem,
  current,
  handleChangeArtery,
  isVenoGram,
}) => {
  const [item, setItem] = useState({});
  const [checkItem, setCheckItem] = useState(null);
  const [dataSelectArtery, setDataSelectArtery] = useState([]);
  const [medical, setMedical] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [hasAtmInflation, setAtmInflation] = useState(false);
  const [hasPostStenosis, setHasPostStenosis] = useState(false);
  const [hasProductDescription, setHasProductDescription] = useState(false);
  const [hasTreatmentTime, setHasTreatmentTime] = useState(false);
  const [hasNumberOfPasses, setHasNumberOfPasses] = useState(false);
  const [hasPulsesDelivered, setHasPulsesDelivered] = useState(false);
  const [hasFrequency, setHasFrequency] = useState(false);
  const [hasInflationTime, setHasInflationTime] = useState(false);
  const [hasStenosis, setHasStenosis] = useState(false);
  const [hasDiameter, setHasDiameter] = useState(false);
  const [hasPlaque, setHasPlaque] = useState(false);

  let diamaterArray = [];
  let cond = "";

  for (let i = 0; i <= 10.0; i += 0.5) {
    cond = i.toString().length === 1 || i === 10 ? i.toString().concat(".0") : i.toString();
    diamaterArray.push({ value: cond, label: cond + "mm" });
  }

  useEffect(() => {
    if (!isVenoGram) {
      if (ArteryLabels && Object.keys(ArteryLabels).length > 0) {
        const mixData = Object.keys(ArteryLabels).map((r) => {
          return {
            label: ArteryLabels[r],
            value: r,
          };
        });
        setDataSelectArtery(mixData);
      }
    } else {
      if (VeinLabels && Object.keys(VeinLabels).length > 0) {
        const mixData = Object.keys(VeinLabels).map((r) => {
          return {
            label: VeinLabels[r],
            value: r,
          };
        });
        setDataSelectArtery(mixData);
      }
    }
  }, [ArteryLabels]);

  useEffect(() => {
    if (activeItem) {
      setMedical(getMedical(activeItem));
    }
    if (current) {
      if (current.body) {
        setCheckItem(current?.body?.part || "");
      }
      const detail = {
        ...(current?.details?.[activeIndex] || {}),
        item: activeItem ? getMedical(activeItem)?.value || "" : "",
        body: current.body ? getArteryLabel(current.body.part) : "",
        post_stenosis:
          current?.details?.[activeIndex] && current?.details?.[activeIndex]?.post_stenosis
            ? current?.details?.[activeIndex]?.post_stenosis
            : activeItem && getMedical(activeItem).type === "status"
            ? getMedical(activeItem).value
            : "",
        cost: (current?.details?.[activeIndex] && current?.details?.[activeIndex]?.cost) || "",
        treatment_time: (current?.details?.[activeIndex] && current?.details?.[activeIndex]?.treatment_time) || "",
      };
      setItem(detail);
    }
  }, [current, activeItem, activeIndex]);

  useEffect(() => {
    if (item.atm === "" || item.atm === null || item.atm === undefined) setAtmInflation(true);
    else setAtmInflation(false);
    if (item.post_stenosis === "" || item.post_stenosis === null || item.post_stenosis === undefined) setHasPostStenosis(true);
    else setHasPostStenosis(false);
    if (item.product_description === "" || item.product_description === null || item.product_description === undefined)
      setHasProductDescription(true);
    else setHasProductDescription(false);
    if (item.treatment_time === "" || item.treatment_time === null || item.treatment_time === undefined) setHasTreatmentTime(true);
    else setHasTreatmentTime(false);
    if (item.number_of_passes === "" || item.number_of_passes === null || item.number_of_passes === undefined) setHasNumberOfPasses(true);
    else setHasNumberOfPasses(false);
    if (item.pulses_delivered === "" || item.pulses_delivered === null || item.pulses_delivered === undefined) setHasPulsesDelivered(true);
    else setHasPulsesDelivered(false);
    if (item.frequency === "" || item.frequency === null || item.frequency === undefined) setHasFrequency(true);
    else setHasFrequency(false);
    if (item.inflation_time === "" || item.inflation_time === null || item.inflation_time === undefined) setHasInflationTime(true);
    else setHasInflationTime(false);
    if (item.stenosis === "" || item.stenosis === null || item.stenosis === undefined) setHasStenosis(true);
    else setHasStenosis(false);
    if (item.diameter === "" || item.diameter === null || item.diameter === undefined) setHasDiameter(true);
    else setHasDiameter(false);
    if (item.plaque === "" || item.plaque === null || item.plaque === undefined) setHasPlaque(true);
    else setHasPlaque(false);
  }, [
    item.atm,
    item.post_stenosis,
    item.product_description,
    item.treatment_time,
    item.number_of_passes,
    item.pulses_delivered,
    item.frequency,
    item.inflation_time,
    item.stenosis,
    item.diameter,
    item.proximal,
    item.distal,
    item.remodeling,
    item.plaque,
  ]);

  const handleChange = (type) => (value) => {
    if (type === "product_description") {
      if (value) {
        const productItem = items.find((product) => product?.name === value);
        setItem({
          ...item,
          product: productItem,
          cost: productItem.cost,
          [type]: value,
        });
        onSave({
          ...item,
          product: productItem,
          cost: productItem.cost,
          [type]: value,
        });
      } else {
        setItem({ ...item, product: null, cost: null, [type]: value });
        onSave({ ...item, product: null, cost: null, [type]: value });
      }
    } else {
      setItem({ ...item, [type]: value });
      onSave({ ...item, [type]: value });
    }
  };

  const handleFields = (type) => (value) => {
    if (type === "proximal") {
      setItem({ ...item, ["proximal"]: value.target.checked });
      onSave({ ...item, ["proximal"]: value.target.checked });
    } else if (type === "distal") {
      setItem({ ...item, ["distal"]: value.target.checked });
      onSave({ ...item, ["distal"]: value.target.checked });
    } else if (type === "remodeling") {
      setItem({ ...item, ["remodeling"]: value.target.checked });
      onSave({ ...item, ["remodeling"]: value.target.checked });
    } else {
      setItem({ ...item, [type]: value });
      onSave({ ...item, [type]: value });
    }
  };

  const onSave = (detail) => {
    if (handleSubmit) {
      handleSubmit(detail);
    }
  };

  const removeMedical = () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    removeItem(activeItem);
  };

  return (
    <div className="optDetails">
      <div className="optDetails-header">
        {medical && medical.icon ? medical.icon : <DevicesSVG />}
        <div className="optDetails-header__title">{medical && medical.label ? medical.label : title}</div>
      </div>
      <div className="optDetails-body">
        <div className="optDetails-body__fields">
          <div className="optDetails-body__fields-left">
            {medical && !medical.state && (
              <>
                <div className="optDetails-body__control">
                  <EditableFormInput
                    isErrorTextSpacing={true}
                    label={"Product Description"}
                    allowClear={true}
                    value={item.product_description}
                    type={"check"}
                    options={items || []}
                    valueKey={"name"}
                    optionKey={"name"}
                    fullWidth
                    handleChange={handleChange("product_description")}
                    isError={hasProductDescription}
                    enableErrorText={hasProductDescription}
                    helperText="Must Select Product Description"
                    required={true}
                  />
                </div>
              </>
            )}
            <div className="optDetails-body__control">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Location"}
                fullWidth
                value={item?.body?.part || checkItem}
                handleChange={(value) => {
                  setCheckItem(value);
                  handleChangeArtery && handleChangeArtery(value, checkItem, medical && medical.label ? medical.label : title);
                }}
                type={"check"}
                allowClear={true}
                options={dataSelectArtery}
                optionKey={"value"}
                valueKey={"label"}
                required={true}
              />
            </div>
            {medical.label === "Laser Atherectomy" && (
              <div className="optDetails-body__control">
                <EditableFormInput
                  isErrorTextSpacing={true}
                  label={"Treatment Time"}
                  value={item.treatment_time || ""}
                  unit={"Seconds"}
                  handleChange={handleChange("treatment_time")}
                  isError={hasTreatmentTime}
                  enableErrorText={hasTreatmentTime}
                  helperText="Must Select Treatment Time"
                  required={true}
                />
              </div>
            )}
            {medical &&
              (medical.fields || []).map((field) => (
                <>
                  {field === "stenosis" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Stenosis"}
                        fullWidth
                        value={item.stenosis}
                        handleChange={handleFields("stenosis")}
                        type={"check"}
                        allowClear={true}
                        options={[
                          { value: "0", label: "0%" },
                          { value: "20", label: "20%" },
                          { value: "40", label: "40%" },
                          { value: "60", label: "60%" },
                          { value: "80", label: "80%" },
                          { value: "100", label: "100%" },
                        ]}
                        optionKey={"value"}
                        valueKey={"label"}
                        isError={hasStenosis}
                        enableErrorText={hasStenosis}
                        helperText="Must Select Stenosis"
                        required={true}
                      />
                    </div>
                  )}
                  {field === "diameter" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Diameter"}
                        fullWidth
                        value={item.diameter}
                        handleChange={handleFields("diameter")}
                        type={"check"}
                        allowClear={true}
                        options={diamaterArray}
                        optionKey={"value"}
                        valueKey={"label"}
                        isError={hasDiameter}
                        enableErrorText={hasDiameter}
                        helperText="Must Select Diameter"
                        required={true}
                      />
                    </div>
                  )}
                  {field === "plaque" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Plaque Characteristics"}
                        fullWidth
                        value={item.plaque}
                        handleChange={handleFields("plaque")}
                        type={"check"}
                        allowClear={true}
                        options={[
                          { value: "homogenous", label: "Homogenous" },
                          { value: "heterogeneous", label: "Heterogeneous" },
                          { value: "calcified", label: "Calcified" },
                          { value: "none", label: "None" },
                        ]}
                        optionKey={"value"}
                        valueKey={"label"}
                        isError={hasPlaque}
                        enableErrorText={hasPlaque}
                        helperText="Must Select Plaque characteristics"
                        required={true}
                      />
                    </div>
                  )}
                </>
              ))}
          </div>
          <div className="optDetails-body__fields-right">
            {medical &&
              (medical.fields || []).map((field) => (
                <>
                  {field === "numberOfPasses" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Number of Passes"}
                        fullWidth
                        value={item.number_of_passes}
                        handleChange={handleFields("number_of_passes")}
                        isError={hasNumberOfPasses}
                        enableErrorText={hasNumberOfPasses}
                        helperText="Must Select Number of Passes"
                        required={true}
                      />
                    </div>
                  )}
                  {field === "postStenosis" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Post-Stenosis"}
                        fullWidth
                        type={"check"}
                        allowClear={true}
                        value={item.post_stenosis}
                        isError={hasPostStenosis}
                        enableErrorText={hasPostStenosis}
                        required={true}
                        helperText="Must Select Post Stenosis"
                        handleChange={handleFields("post_stenosis")}
                        options={[
                          { value: "0%", label: "0%" },
                          { value: "20%", label: "20%" },
                          { value: "40%", label: "40%" },
                          { value: "60%", label: "60%" },
                          { value: "80%", label: "80%" },
                          { value: "100%", label: "100%" },
                        ]}
                        optionKey={"value"}
                        valueKey={"label"}
                      />
                    </div>
                  )}
                  {field === "pulsesDelivered" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Pulses Delivered"}
                        fullWidth
                        value={item.pulses_delivered}
                        handleChange={handleFields("pulses_delivered")}
                        isError={hasPulsesDelivered}
                        enableErrorText={hasPulsesDelivered}
                        helperText="Must Select Pulses Delivered"
                        required={true}
                      />
                    </div>
                  )}
                  {field === "frequency" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Frequency"}
                        fullWidth
                        value={item.frequency}
                        handleChange={handleFields("frequency")}
                        isError={hasFrequency}
                        enableErrorText={hasFrequency}
                        helperText="Must Select Frequency"
                        required={true}
                      />
                    </div>
                  )}
                  {field === "atmInflation" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"ATM Inflation"}
                        fullWidth
                        value={item.atm}
                        isError={hasAtmInflation}
                        enableErrorText={hasAtmInflation}
                        helperText="Must Select Atm Infaltion"
                        required={true}
                        handleChange={handleFields("atm")}
                        type={"check"}
                        allowClear={true}
                        options={[
                          { value: "3", label: "3 ATM" },
                          { value: "4", label: "4 ATM" },
                          { value: "5", label: "5 ATM" },
                          { value: "6", label: "6 ATM" },
                          { value: "7", label: "7 ATM" },
                          { value: "8", label: "8 ATM" },
                          { value: "9", label: "9 ATM" },
                          { value: "10", label: "10 ATM" },
                          { value: "11", label: "11 ATM" },
                          { value: "12", label: "12 ATM" },
                          { value: "13", label: "13 ATM" },
                          { value: "14", label: "14 ATM" },
                          { value: "15", label: "15 ATM" },
                          { value: "16", label: "16 ATM" },
                        ]}
                        optionKey={"value"}
                        valueKey={"label"}
                      />
                    </div>
                  )}
                  {field === "inflationTime" && (
                    <div className="optDetails-body__control" key={field}>
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        label={"Inflation Time"}
                        fullWidth
                        value={item.inflation_time}
                        handleChange={handleFields("inflation_time")}
                        type={"check"}
                        allowClear={true}
                        options={[
                          { value: "1", label: "1 minutes" },
                          { value: "2", label: "2 minutes" },
                          { value: "3", label: "3 minutes" },
                          { value: "4", label: "4 minutes" },
                        ]}
                        optionKey={"value"}
                        valueKey={"label"}
                        isError={hasInflationTime}
                        enableErrorText={hasInflationTime}
                        helperText="Must Select Inflation Time"
                        required={true}
                      />
                    </div>
                  )}

                  {field === "proximal" && (
                    <div className="optDetails-body__control" key={field}>
                      <Checkbox checked={item.proximal} onChange={handleFields("proximal")} style={{ width: "100%" }}>
                        Is there continuous plaque extending from proximal-vessel segment?
                      </Checkbox>
                    </div>
                  )}
                  {field === "distal" && (
                    <div className="optDetails-body__control" key={field}>
                      <Checkbox checked={item.distal} onChange={handleFields("distal")} style={{ width: "100%" }}>
                        Is there continuous plaque extending from distal-vessel segment?
                      </Checkbox>
                    </div>
                  )}
                  {field === "remodeling" && (
                    <div className="optDetails-body__control" key={field}>
                      <Checkbox checked={item.remodeling} onChange={handleFields("remodeling")} style={{ width: "100%" }}>
                        Is there negative remodeling?
                      </Checkbox>
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
        <div className="optDetails-body__action">
          <button
            className={`common-btn ${confirmDelete ? "delete-btn" : "outlined-btn"}`}
            style={
              confirmDelete
                ? {
                    marginRight: 0,
                    backgroundColor: "#CA373B",
                  }
                : {}
            }
            onClick={removeMedical}
          >
            {confirmDelete ? "Confirm Delete?" : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessDetails;

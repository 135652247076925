import { Icon, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import InputField from "../../../common/components/EditableText/EditableFormInput";
import { validateField } from "../../../services/validations";
import { useOutsideDetect } from "../../utils";
import DataTable from "../DataTable/WhiteHeaderTable";
import EditableFormInput from "../EditableText/EditPlanOrder";
import { CloseIconSVG, RedRequiredCircleSVG, SearchSVG } from "../icons/SVGIcon";
import Loading from "../Loading";
import ScrollWrapper from "../ScrollBar";
import "./style.scss";

const { Option } = Select;
const { Search, TextArea } = Input;
const selectArray = [
  { value: "ASAP", id: "asap" },
  { value: "Within 2 weeks", id: "2_week" },
  { value: "2 to 4 weeks", id: "2_week_to_4_week" },
  { value: "1 to 3 months", id: "1_month_to_3_month" },
  { value: "3 to 6 months", id: "3_month_to_6_month" },
  { value: "6 to 12 months", id: "6_month_to_12_month" },
  { value: "1 year", id: "1_year" },
  { value: "6 months", id: "6_month" },
  { value: "3 months", id: "3_month" },
];

const timer = null;

const initFre = [
  {
    value: "Qid",
    id: "qid",
  },
  {
    value: "Bid",
    id: "bid",
  },
  {
    value: "Qdaily",
    id: "qdaily",
  },
  {
    value: "Qweekly",
    id: "qweekly",
  },
  {
    value: "Tid",
    id: "tid",
  },
];

const AutoCompleteComponentPlanOder = ({
  rows,
  options,
  query,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keyLabel,
  keyValue,
  keyType,
  selectItem,
  removeItem,
  disableOptions,
  onclickRow,
  activeIndex,
  isSelectRow,
  handleValueSelect,
  onChangeSelect,
  valueSelected,
  processArray,
  keyIdPlan,
  isWhite,
  sideHandle,
  onChangeSelectSide,
  isGeneral,
  isPrescription,
  keyCheck,
  hasSideHandle,
  enableError,
  isLocked,
  handleChange,
  processSave,
  arrCheck,
  indexItem,
  HPICheck,
  onChangeNote,
  isMarginTop,
  planAll,
  onChangeTextNote,
  textNotes,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [notes, setNotes] = useState("");
  const [isHPILoaded, setIsHPILoaded] = useState(false);
  const [hpiText, setHpiText] = useState("");
  const [textEdit, setTextEdit] = useState("");
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedIdPlan, setSelectedIdPlan] = useState("");
  const [selectedAll, setSelectedAll] = useState({});
  const [valueSelectedGlobal, setValueSelectedGlobal] = useState({});
  const [selectRow, setSelectRow] = useState(false);
  const [valueHandleSelect, setValueHandleSelect] = useState([]);
  const [activeDetail, setActiveDetail] = useState(null);
  const [hasSide, setHasSide] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [uparrCheck, setUparrCheck] = useState(false);
  const [openBox, setBox] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setUparrCheck(arrCheck);
  }, [arrCheck]);

  useEffect(() => {
    setData(rows && rows[keyIdPlan] ? rows[keyIdPlan] : []);
  }, [rows]);

  const optionData = options ? (isGeneral ? options.filter((r) => r?.name !== "Referral") : options) : [];
  let emptyRows = [];
  if (data && data.length < 5) {
    emptyRows = new Array(4 - data.length).fill({ label: "" });
  }

  useEffect(() => {
    const isHPI = HPICheck && HPICheck[indexItem] && HPICheck[indexItem]["note"];
    if (isHPI && !isHPILoaded) {
      setHpiText(isHPI);
      setIsHPILoaded(true);
    }
  }, [HPICheck]);

  useEffect(() => {
    setSelectedIdPlan(keyIdPlan);
  }, [keyIdPlan]);

  useEffect(() => {
    setValueSelectedGlobal(valueSelected[keyIdPlan] || []);
  }, [valueSelected]);

  useEffect(() => {
    setValueHandleSelect(handleValueSelect[keyIdPlan] || []);
  }, [handleValueSelect]);

  useEffect(() => {
    setSelectRow(isSelectRow);
  }, [isSelectRow]);

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row, index) => {
    setSelected(row[keyLabel]);
    setSelectedAll({
      item: row,
      key: index,
    });
    if (row.has_side && row.has_side === true) {
      setHasSide(true);
    } else {
      setHasSide(false);
    }
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keyLabel]);
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (!search.trim()) {
      return;
    }
    if (selectItem) {
      selectItem(
        selected,
        selectedValue,
        selectedType,
        selectedIdPlan,
        hasSide,
        isPrescription ? "isPrescription" : null,
        keyIdPlan,
        selectedAll,
        isGeneral
      );
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target?.value);
    if (handleSearch) {
      if (isPrescription) {
        handleSearch(e.target?.value, "isPrescription");
      } else if (isGeneral) {
        handleSearch(e.target?.value, "isGeneral");
      } else {
        handleSearch(e.target.value);
      }
    }
  };

  const clickHandle = (row, index, type, rowAll) => {
    if (onclickRow) {
      onclickRow(row, index, type, rowAll, uparrCheck);
    }
  };

  const selectHandle = (value, index, type) => {
    const arrayMap = valueSelectedGlobal.map((r, i) => {
      if (index === i) {
        return value;
      }
      return r;
    });
    setValueSelectedGlobal(arrayMap);
    onChangeSelect(value, index, type);
  };

  const selectHandleSide = (value, index, type) => {
    onChangeSelectSide(value, index, type);
  };

  const handleIconClick = (index, value, indexSide) => {
    setBox(!openBox);
    setActiveDetail({
      row: value,
      index: index,
      indexSide: indexSide,
    });
  };

  console.log(valueSelectedGlobal, "valueSelectedGlobal");
  console.log(selectArray, "selectArray");
  return (
    <div className="atuoComplete" style={{ padding: "1rem", background: "#fff", position: "relative" }}>
      <div className="atuoComplete__filter">
        <div ref={ref} className={`atuoComplete__filter-search ${disableSearchIcon ? "disable-icon" : ""} ${isWhite ? "white" : ""}`}>
          <Search
            disabled={isLocked == 1 ? true : false}
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div className={`atuoComplete__filter-action ${isLocked == 1 ? "disabled" : ""}`}>
          <button className="atuoComplete__filter-add" onClick={addSelectedItem} disabled={isLocked}>
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoComplete__filter-result" style={{ left: "1rem", top: "1rem" }}>
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper css="no-padding">
                {!!optionData.length &&
                  (optionData || []).map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`empty-${title}-${index}-${keyIdPlan}`}
                      onClick={() => handleSelectItem(row, index)}
                    >
                      <p style={{ paddingLeft: "28px" }}>{row[keyLabel]}</p>
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
      <div
        onBlur={() => setBox(false)}
        style={{
          display: openBox ? "inline-block" : "none",
          position: "absolute",
          width: "80%",
          zIndex: 100,
          top: 150,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "5px",
            top: "5px",
            zIndex: 10,
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
          onClick={() => setBox(false)}
        >
          <CloseIconSVG />
        </div>
        <TextArea
          rows={4}
          value={textNotes?.[activeDetail?.row?.keyIdPlan]?.[activeDetail?.index] || ""}
          onChange={(e) => {
            onChangeTextNote && onChangeTextNote(e?.target?.value, activeDetail?.index, activeDetail?.row);
          }}
        />
      </div>
      <div className="atuoComplete__result fullContainer" style={{ height: "calc(8 * 16px + 52px)", background: "#fff" }}>
        <DataTable
          hideHeaderHeight={true}
          isMarginTop={isMarginTop}
          title="plan_orders_autocomplete"
          isNormal={true}
          isFixHeight
          sizeHeightFix="100%"
          disableDelete={isLocked}
          editable={isLocked ? false : true}
          handleEdit={handleIconClick}
          indexItem={indexItem}
          keyCheck={keyCheck}
          activeIndex={activeIndex}
          noneAllActive={true}
          isNotFirstactive={true}
          labels={
            isGeneral ? ["Plan"] : isPrescription ? ["Medication", "Strength", "Form", "Route", "Frequency"] : ["Plan", "Side", "Time to Schedule"]
          }
          widths={isGeneral ? ["100%"] : isPrescription ? ["20%", "20%", "20%", "20%", "20%"] : ["40%", "30%", "30%"]}
          showIcon={true}
          columns={
            isGeneral
              ? [{ sortable: true, key: "row" }]
              : isPrescription
              ? [
                  { sortable: false, key: "medication" },
                  { sortable: false, key: "strength" },
                  { sortable: false, key: "form" },
                  { sortable: false, key: "route" },
                  { sortable: false, key: "frequency" },
                ]
              : [
                  { sortable: true, key: "row" },
                  { sortable: false, key: "side" },
                  { sortable: false, key: "schedule" },
                ]
          }
          handleDelete={(value, index) => {
            if (!isLocked) {
              removeItem(value.data, index, keyIdPlan, isPrescription);
            }
          }}
          handleClickRow={(row, index) => clickHandle(row.data, index, keyIdPlan, row)}
          rowData={(data || []).map((item, index) => {
            if (isGeneral) {
              return {
                key: item?.key,
                id: item?.id,
                data: item,
                isShowPaddingHeight: enableError,
                keyIdPlan: keyIdPlan,
                activeIndex: activeIndex,
                processSave: processSave && processSave[keyIdPlan] && processSave[keyIdPlan][index],
                processCheck: processArray && processArray[keyIdPlan] && processArray[keyIdPlan][index],
                row: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {processArray && processArray[keyIdPlan] && processArray[keyIdPlan][index] ? (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                      </span>
                    ) : (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                      </span>
                    )}
                    <p style={{ marginLeft: selectRow ? 13 : 12 }}>{item}</p>
                  </div>
                ),
              };
            } else if (isPrescription) {
              return {
                keyIdPlan: keyIdPlan,
                activeIndex: activeIndex,
                processSave: processSave && processSave[keyIdPlan] && processSave[keyIdPlan][index],
                processCheck: processArray && processArray[keyIdPlan] && processArray[keyIdPlan][index],
                isShowPaddingHeight: enableError,
                frequency: (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <EditableFormInput
                      label={"Frequency"}
                      handleChange={(value) => {
                        handleChange("frequency", value, index, item, keyIdPlan);
                      }}
                      value={item?.frequency || item?.prescription?.frequency}
                      isError={validateField("text", item?.frequency || item?.prescription?.frequency)}
                      fullWidth={true}
                      helperText={"Frequency is Required!"}
                      enableErrorText={enableError}
                      required={true}
                      type={"check"}
                      style={{ width: "100%" }}
                      allowClear={true}
                      optionKey={"id"}
                      valueKey={"value"}
                      options={initFre}
                      disabled={isLocked}
                    />
                  </div>
                ),
                strength: (
                  <InputField
                    value={item?.strength || item?.prescription?.medication?.strength}
                    onChange={(e) => {
                      handleChange("strength", e.target?.value, index, item, keyIdPlan);
                    }}
                  />
                ),
                form: (
                  <InputField
                    value={item?.form || item?.prescription?.medication?.form}
                    onChange={(e) => {
                      handleChange("form", e.target?.value, index, item, keyIdPlan);
                    }}
                  />
                ),
                route: (
                  <InputField
                    value={item?.route || item?.prescription?.medication?.route}
                    onChange={(e) => {
                      handleChange("route", e.target?.value, index, item, keyIdPlan);
                    }}
                  />
                ),
                medication: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {processArray && processArray[keyIdPlan] && processArray[keyIdPlan][index] ? (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                      </span>
                    ) : (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                      </span>
                    )}
                    <p style={{ marginLeft: 13 }}>{item?.prescription?.medication?.drug_name || item?.drug_name || ""}</p>
                  </div>
                ),
              };
            } else {
              return {
                key: item?.key,
                id: item?.id,
                data: item,
                keyIdPlan: keyIdPlan,
                activeIndex: activeIndex,
                isShowPaddingHeight: enableError,
                processSave: processSave && processSave[keyIdPlan] && processSave[keyIdPlan][index],
                processCheck: processArray && processArray[keyIdPlan] && processArray[keyIdPlan][index],
                side: hasSideHandle && hasSideHandle[keyIdPlan] && hasSideHandle[keyIdPlan][index] && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <EditableFormInput
                      label={"Side"}
                      handleChange={(value) => {
                        selectHandleSide(value, index, keyIdPlan);
                      }}
                      placeholder="Side"
                      value={sideHandle[keyIdPlan] && sideHandle[keyIdPlan][index] ? sideHandle[keyIdPlan][index] : undefined}
                      isError={validateField(
                        "text",
                        sideHandle[keyIdPlan] && sideHandle[keyIdPlan][index] ? sideHandle[keyIdPlan][index] : undefined
                      )}
                      type={"check"}
                      fullWidth={true}
                      helperText={"Side is Required!"}
                      enableErrorText={enableError}
                      required={true}
                      style={{ width: "100%" }}
                      allowClear={true}
                      optionKey={"name"}
                      valueKey={"name"}
                      disabled={isLocked}
                      options={[{ name: "Left" }, { name: "Right" }, { name: "Bilateral" }, { name: "Not applicable" }]}
                    />
                  </div>
                ),
                row: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {processArray && processArray[keyIdPlan] && processArray[keyIdPlan][index] ? (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                      </span>
                    ) : (
                      <span
                        className="normal-icon"
                        style={{
                          width: "1rem",
                          minWidth: "1rem",
                          height: "18px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                      </span>
                    )}
                    <p style={{ marginLeft: selectRow ? 13 : 12 }}>{item}</p>
                  </div>
                ),
                schedule:
                  selectRow && valueHandleSelect ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <EditableFormInput
                        label={"Urgency"}
                        handleChange={(value) => {
                          selectHandle(value, index, keyIdPlan);
                        }}
                        value={valueSelectedGlobal[index] ? valueSelectedGlobal[index] : undefined}
                        isError={validateField("text", valueSelectedGlobal[index] ? valueSelectedGlobal[index] : undefined)}
                        fullWidth={true}
                        helperText={"Urgency is Required!"}
                        enableErrorText={enableError}
                        required={true}
                        type={"check"}
                        style={{ width: "100%" }}
                        allowClear={true}
                        optionKey={"id"}
                        valueKey={"value"}
                        options={selectArray}
                        disabled={isLocked}
                      />
                    </div>
                  ) : undefined,
              };
            }
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>

      <TextArea
        disabled={!!isLocked}
        placeholder={"Notes"}
        autoSize={{ minRows: 3 }}
        style={{
          marginTop: "0.5rem",
          borderRadius: 0,
          backgroundColor: "#f4f7fb",
        }}
        onChange={(e) => {
          const {
            target: { value },
          } = e;
          setHpiText(value);
          if (onChangeNote) onChangeNote(value, indexItem);
        }}
        value={hpiText}
      />
      {uparrCheck && uparrCheck.length > 0 && uparrCheck.some((r) => r.type == "procedure" || r.bundle == "procedure") && (
        <div className="div-require-plan-order">
          <RedRequiredCircleSVG />
          <span className="text-require-plan-order">Describe the complete operative details for the procedure selected (if applicable)</span>
        </div>
      )}
    </div>
  );
};

export default AutoCompleteComponentPlanOder;

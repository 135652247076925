import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import DiseaseBody from "../../../../common/components/DiseasBody";
import {
  HPIGetConditions,
  HPISaveCondition,
  HPISetConditions,
  loadUltrasoundHistoryForConditionHPI,
  setGlobalLoading,
  setGlobalToastr,
  setSidebarState,
  saveSameHpiCheck,
} from "../../../../store/actions";
import HPIConditionList from "./HPIConditionList";
import HPISideBar from "./HPISidebar";
import { Icon } from "antd";
import { getLocal } from "../../../../helpers/Local";
import RemoveN from "../../../../helpers/RemoveN";
import ReplaceN from "../../../../helpers/ReplaceN";

class HPIContainer extends Component {
  state = {
    isSidebar: false,
    clickedCondition: "",
    saveQueued: false,
    checkSavingFuncId: null,
    first: false,
    leftRight: null,
    conditionMix: [],
  };
  timer = null;
  async componentDidMount() {
    this.props.setGlobalLoading(true);
    this.props.setSidebarState(true);
    const { patientResource } = this.props;
    if (patientResource) {
      const procedure_id = getLocal("current_appointment_id");
      await this.props.HPIGetConditions(procedure_id);
      this.setState({
        ...this.state,
        first: true,
        isSidebar: window.innerWidth <= 768 ? false : true,
      });
    }
    this.props.setGlobalLoading(false);
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  onChangeData = async (conditions, shouldRefresh = false) => {
    const { patientResource, is_locked } = this.props;
    const patient_id = patientResource?.id;
    const procedure_id = getLocal("current_appointment_id");
    this.props.HPISetConditions(conditions || []);
    if (this.state.first) {
      if (is_locked == 0 || is_locked == null) {
        await this.props.setGlobalLoading(true);
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          const fixArr = (conditions || []).map((r) => {
            return {
              ...r,
              questions: (r?.questions || []).map((q) => {
                return {
                  ...q,
                  comments: RemoveN(q?.comments || ""),
                };
              }),
              text: RemoveN(r?.text || ""),
            };
          });
          this.setState({
            conditionMix: conditions || [],
          });
          const leftRightData = await this.props.HPISaveCondition(patient_id, procedure_id, fixArr);
          if (shouldRefresh === true) {
            const data = await this.props.HPIGetConditions(procedure_id);
            this.updateNewData(data?.conditions);
          }
          this.setState({
            ...this.state,
            saveQueued: true,
            leftRight: leftRightData?.leftRight,
          });
          console.log(leftRightData?.has_same_hpi, "leftRightData?.has_same_hpi");
          this.props.saveSameHpiCheck(leftRightData?.has_same_hpi);
          await this.props.setGlobalLoading(false);
        }, 1000);
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
      }
    }
  };

  onChangeDataNotAPI = async (conditions) => {
    const { is_locked } = this.props;
    this.props.HPISetConditions(conditions || []);
    if (is_locked == 0 || is_locked == null) {
      this.setState({
        conditionMix: conditions || [],
      });
      this.setState({
        ...this.state,
        saveQueued: true,
      });
    }
  };

  onClickConditionPanel = async (condition) => {
    const { patientResource } = this.props;
    const procedure_id = getLocal("current_appointment_id");
    if (condition) {
      condition = condition.replace("collapse-", "");
      this.setState({ ...this.state, clickedCondition: condition });
      if (condition && condition.length > 0 && condition.includes("_")) {
        const check = condition.split("_");
        condition = check.join(" ");
      }
      if (patientResource && patientResource?.id) {
        await this.props.setGlobalLoading(true);
        await this.props.loadUltrasoundHistoryForConditionHPI(patientResource?.id, condition, procedure_id);
        await this.props.setGlobalLoading(false);
      }
    } else {
      this.setState({ ...this.state, clickedCondition: "" });
    }
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  componentWillReceiveProps(props, state) {
    const fixArr = (props?.conditions || []).map((r) => {
      return {
        ...r,
        questions: (r?.questions || []).map((q) => {
          return {
            ...q,
            comments: ReplaceN(q?.comments || ""),
          };
        }),
        text: ReplaceN(r?.text || ""),
      };
    });
    this.setState({
      conditionMix: fixArr,
    });
    this.setState({
      leftRight: props.leftRight,
    });
  }

  updateNewData = (data) => {
    const fixArr = (data || []).map((r) => {
      return {
        ...r,
        questions: (r?.questions || []).map((q) => {
          return {
            ...q,
            comments: ReplaceN(q?.comments || ""),
          };
        }),
        text: ReplaceN(r?.text || ""),
      };
    });
    this.setState({
      conditionMix: fixArr,
    });
  };

  render() {
    const { isSidebar, conditionMix, leftRight } = this.state;
    const { allConditions } = this.props;
    const { clickedCondition } = this.state;
    let conditionClassName = "";
    for (const condition_ of conditionMix) {
      conditionClassName += _.snakeCase(condition_["condition"]) + " ";
    }

    return (
      <div
        style={{
          display: !this.props.display ? "flex" : this.props.display === "flex" ? "flex" : "none",
        }}
        className="clinicWorkflow__main-section"
      >
        <div
          className="eventsContainer has-sub-header"
          style={{
            width: "100%",
          }}
        >
          <div className="patientContainer">
            <div className={`imageSection disease-icon ${_.snakeCase(clickedCondition)}-clicked ${conditionClassName}`}>
              <DiseaseBody />
            </div>
            <div className="detailSection">
              <HPIConditionList
                onChangeDataNotAPI={this.onChangeDataNotAPI}
                updateNewData={this.updateNewData}
                rows={conditionMix}
                leftRight={leftRight}
                options={allConditions}
                onChangeData={this.onChangeData}
                onClickConditionPanel={this.onClickConditionPanel}
                setIsSaveActiveNextTab={this?.props?.setIsSaveActiveNextTab || (() => {})}
              />
            </div>
          </div>
        </div>
        <HPISideBar
          ref={this.sidebarRef}
          sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
          isShow={this.props.isSlide || isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          conditions={conditionMix}
          clickedCondition={clickedCondition}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  conditions: state.clinicProviderHPI.conditions,
  leftRight: state.clinicProviderHPI.leftRight,
  allConditions: state.clinicProviderHPI.allConditions,
  patientResource: state.clinicProvider.patientResource,
});

const mapDispatchToProps = {
  HPIGetConditions,
  HPISetConditions,
  HPISaveCondition,
  loadUltrasoundHistoryForConditionHPI,
  setGlobalLoading,
  setGlobalToastr,
  setSidebarState,
  saveSameHpiCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(HPIContainer);

import { Collapse, Icon } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReplaceNN from "../../../helpers/ReplaceNN";
import "./PatientOverviewList.scss";
import SnapChart from "./SnapChart";
import SnapList from "./SnapList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CardWhiteBlock from "src/common/components/CardWhite";
import TableCustom from "src/common/components/TableCustom";
import ExtendDataTable from "src/common/components/SubDataTable/ExtendDataTable";
import { get_compliance } from "src/services/api.services";
import { getLocal } from "src/helpers/Local";

const dataLabel = [
  { name: "check", width: "30px", type: "status" },
  { name: "Date and Time", width: "calc(16% - 10px)", type: "service_date" },
  { name: "Appointment Type", width: "calc(20% - 10px)", type: "service" },
  { name: "Specification", width: "calc(34% - 10px)", type: "text" },
  { name: "Compliance Measures", width: "calc(40%)", type: "compliance" },
];

const PatientOverviewList = ({ onClickConditionPanel, removeItem, activeDelete, indexDelete }) => {
  const list = useSelector((state) => state.clinicProviderPatientSnapshot.list);
  const calList = useSelector((state) => state.clinicProviderPatientSnapshot.calList);
  const descList = useSelector((state) => state.clinicProviderPatientSnapshot.descList);
  const [dataCompliance, setDataCompliance] = useState(null);
  const appointment_id = getLocal("current_appointment_id");
  const [tab, setTab] = useState("history");
  const [conditionPanel, setConditionPanel] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const onSelect = (selectedDate_) => {
    setSelectedDate(selectedDate_);
  };

  const [descListState, setDescListState] = useState([]);

  useEffect(() => {
    if (descList?.length && descList[0]) {
      setSelectedDate(moment(descList[0].date).format("MM/DD/YYYY"));
    }
  }, [descList]);

  useEffect(() => {
    const sublist =
      (descList || []).map((r) => {
        return {
          ...r,
          values:
            (r?.values || []).map((v) => {
              return {
                ...v,
                value: ReplaceNN(v.value) || "",
              };
            }) || [],
        };
      }) || [];
    setDescListState(sublist);
  }, [descList]);

  const fetchApi = async () => {
    const data = await get_compliance(appointment_id);
    if (data?.condition_id) {
      // TODO
      setDataCompliance({
        ...data,
        appointments: (data?.appointments || []).map((r) => {
          const valueText = r?.text && typeof r?.text === "string" ? r?.text.replaceAll("\\n", "\n") : r?.text;
          return {
            status: r?.status,
            service_date: r?.service_date ? moment(r?.service_date).format("MM/DD/YYYY hh:mm A") : null,
            service: r?.ultrasound_id ? `${r?.service}\nUltrasound` : r?.service,
            text: valueText && typeof valueText === "string" ? valueText?.replaceAll("--", "•") : valueText,
            compliance: r?.compliance,
            patient_id: r?.patient_id,
            appointment_ultrasound_id: r?.appointment_ultrasound_id || null,
            ultrasound_id: r?.ultrasound_id || null,
            id: r?.id,
          };
        }),
      });
    } else {
      setDataCompliance(null);
    }
  };

  useEffect(() => {
    fetchApi();
  }, [appointment_id]);

  const PanelToolbar = ({ onRemoveCondition, row, index }) => {
    if (row.is_system === 0) {
      return (
        <div
          className="atuoAccordion__result-item-close_btn"
          onClick={(e) => {
            e.stopPropagation();
            onRemoveCondition && onRemoveCondition(index, row);
          }}
        >
          <Icon
            style={{
              color: activeDelete && indexDelete === index ? "red" : "#000",
            }}
            type="delete"
          />
        </div>
      );
    } else {
      return (
        <div
          className="atuoAccordion__result-item-close_btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      );
    }
  };

  return (
    <div className="patient-overview-list">
      <DndProvider backend={HTML5Backend}>
        {/* header */}
        <div className="tabHeaders new">
          <div
            className={`tabHeaders-item ${tab === "history" ? "active" : ""}`}
            style={{ textAlign: "left" }}
            onClick={() => {
              setConditionPanel("");
              setTab("history");
            }}
          >
            History
          </div>
          {dataCompliance && (
            <div
              className={`tabHeaders-item ${tab === "compliance" ? "active" : ""} `}
              onClick={() => {
                setConditionPanel("");
                setTab("compliance");
              }}
            >
              Compliance
            </div>
          )}
        </div>
        {/* content */}
        <CardWhiteBlock
          varient={"default"}
          title={""}
          other={"full-height"}
          cardContainerStyle={{
            height: "100%",
          }}
          extraStyle={{
            padding: "0px",
            paddingTop: "10px",
            backgroundColor: "#f4f7fb",
            height: "100%",
          }}
        >
          <div style={{ height: "69vh", width: "100%", padding: 0, margin: 0, overflow: "auto" }}>
            <Collapse
              activeKey={conditionPanel}
              accordion
              onChange={(key) => {
                onClickConditionPanel(key);
                setConditionPanel(key);
              }}
            >
              {tab === "history" ? (
                <React.Fragment>
                  {(list || []).map((data, index) => (
                    <Collapse.Panel
                      header={data?.condition}
                      key={`collapse-${data["id"]}`}
                      extra={<PanelToolbar onRemoveCondition={() => removeItem && removeItem(index, data)} row={data} index={index} />}
                    >
                      {calList && descListState ? (
                        <React.Fragment>
                          <div className="content">
                            <SnapChart items={calList} selectedDate={selectedDate} onSelect={onSelect} />
                            <div className="snapDetails">
                              <div className="snapDetails__title" style={{ borderBottom: "0.5px solid rgb(223, 227, 230)" }}>
                                Clinical Summary
                              </div>
                              <SnapList
                                items={descListState}
                                selectedDate={selectedDate}
                                onChangeSelectedDate={onSelect}
                                isClinicWorkFlow={true}
                                collapseLabel={data?.condition}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </Collapse.Panel>
                  ))}
                </React.Fragment>
              ) : (
                <Collapse.Panel header={dataCompliance?.condition_name} key={`collapse-${dataCompliance?.["condition_id"]}`}>
                  <TableCustom dataCompliance={dataCompliance?.appointments} dataLabel={dataLabel} />
                </Collapse.Panel>
              )}
            </Collapse>
          </div>
        </CardWhiteBlock>
      </DndProvider>
    </div>
  );
};

export default PatientOverviewList;

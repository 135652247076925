import { Collapse, Icon, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import CardWhiteBlock from "../../../../common/components/CardWhite";
import ExaminationIntra from "../../../../common/components/Examination";
import LabelIcon from "../../../../common/components/LabelIcon";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { IntaOperationBody } from "../../../../common/components/icons/IntaOperation";
import { IntraOperationNewBody } from "../../../../common/components/icons/IntraOperationNew";
import {
  AtherectomyDirectionalIcon,
  AtherectomyLaserIcon,
  AtherectomyRotationalIcon,
  AtherectomyTransluminalIcon,
  BalloonIcon,
  BloodVesselPlaque0,
  BloodVesselPlaque100,
  BloodVesselPlaque20,
  BloodVesselPlaque40,
  BloodVesselPlaque60,
  BloodVesselPlaque80,
  BloodVesselPlaqueStent0,
  BloodVesselPlaqueStent100,
  BloodVesselPlaqueStent20,
  BloodVesselPlaqueStent40,
  BloodVesselPlaqueStent60,
  BloodVesselPlaqueStent80,
  IVUSIcon,
  StentBalloonCoveredIcon,
  StentBalloonIcon,
  StentCoveredIcon,
  StentIcon,
  UltrasoundProbeIcon,
} from "../../../../common/components/icons/OperationIcon";
import { DevicesSVG, DugsSVG, ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import { getArteryLabel } from "../../../../common/constants/Artery";
import { getMedical } from "../../../../common/constants/Operations";
import { getVeinLabel } from "../../../../common/constants/Vein";
import { getLocal } from "../../../../helpers/Local";
import { save_intra_answer } from "../../../../services/api.services";
import {
  HPIGetConditions,
  angioPatientSnapshotLoadInit,
  getActiveStaff,
  getAngiographicSupplies,
  getAppointmentInventory,
  get_angio_type_variable,
  get_intra_answer,
  load_procedurals,
  saveAppointmentInventory,
  save_info_side,
  save_intra_operative,
  setGlobalLoading,
  setProcedureReview,
} from "../../../../store/actions";
import { SampleAccessData, StaticResource, filterMedicationsByQuery as filterDataByQuery } from "../../constant";
import AccessDetails from "../operativeDetailsComponents/AccessDetails";
import ArteryDetail from "../operativeDetailsComponents/ArteryDetail";
import VeinDetail from "../operativeDetailsComponents/VeinDetail";
import IntaOperativeSideBar from "./IntaOperativeSidebar";
import ProcedureSearch from "./procedureSearch";
import RemoveN from "src/helpers/RemoveN";

const { Panel } = Collapse;
const { Option } = Select;

let timer = null;

const ProcedureOptions = [
  "Right Lower Extremity Intervention",
  "Right Iliac Intervention",
  "Right Renal Artery Intervention",
  "Right Upper Extremity Intervention",
  "Right Innominate Artery Intervention",
  "Right PICC Line Placement",
  "Right Dialysis Catheter Placement",
  "Right Carotid Angiogram",
  "Right Dialysis Intervention",
  "Left Lower Extremity Intervention",
  "Left Iliac Intervention",
  "Left Renal Artery Intervention",
  "Left Upper Extremity Intervention",
  "Left Innominate Artery Intervention",
  "Left PICC Line Placement",
  "Left Dialysis Catheter Placement",
  "Left Carotid Angiogram",
  "Left Dialysis Intervention",
];

// Inta Operation Data Structure
const SampleOperation = {
  body: "",
  medicals: [],
  details: [],
};

const IntaOperative = ({ data, title, dataCheckIntra }) => {
  const collapseEndRef = useRef(null);
  const sidebarRef = useRef();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const CPT = useSelector((state) => state.common.CPT);
  const supplies = useSelector((state) => state.inventory.supplies);
  const appointmentInventories = useSelector((state) => state.procedure.inventories);
  const list_procedurals = useSelector((state) => state.procedure.list_procedurals);
  const allConditions = useSelector((state) => state.clinicProviderHPI.allConditions);
  const is_locked = useSelector((state) => state.common.is_locked);
  const [scrollState, setScrollState] = useState(false);
  const [getCollapseHeight, setGetCollapseHeight] = useState(0);
  const [allInventory, setAllInventory] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [inputInventory, setInputInventory] = useState("");
  const [inventoryItems, setInventoryItems] = useState([]);
  const [operationIndex, setOperationIndex] = useState(0);
  const [dataCheck, setDataCheck] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);
  const [disableDetail, setDisableDetail] = useState(true);
  const [loading, setLoading] = useState("");
  const [tab, setTab] = useState("documentation");
  const [bodyTab, setBodyTab] = useState("arterial");
  const [intaOperations, setIntaOperations] = useState([]);
  const [currentOperation, setCurrentOperation] = useState({
    ...SampleOperation,
  });
  const [selectedOperation, setSelectedOperation] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const id_appoinment = getLocal("current_appointment_id");
  const debouncedSearch = useDebounce(inputInventory, 400);
  const [dataCPT, setDataCPT] = useState(null);
  const [isActiveTool, setIsActiveTool] = useState(true);
  const checkActiveAppointmentType = getLocal("checkActiveAppointmentType");
  const patient_id = getLocal("current_patient_id");
  const [isVenoGram, setIsVenoGram] = useState(false);
  const is_venogram = getLocal("content_is_venogram");

  useEffect(() => {
    dataCheckIntra && dataCheckIntra(dataCheck);
  }, [dataCheck]);

  useEffect(() => {
    if (checkActiveAppointmentType) {
      if (checkActiveAppointmentType == "active") {
        setIsActiveTool(false);
      } else {
        setIsActiveTool(true);
      }
    } else {
      setIsActiveTool(true);
    }
  }, [checkActiveAppointmentType]);

  useEffect(() => {
    setDataCPT(CPT);
  }, [CPT]);

  useEffect(() => {
    if (dataCPT && dataCPT?.appointment_type_id && !isActiveTool) {
      fetchListTypeAnswer(dataCPT?.appointment_type_id);
    }
  }, [dataCPT, isActiveTool]);

  const fetchListTypeAnswer = async (id) => {
    dispatch(get_angio_type_variable(id));
  };

  const fetchDetailAnswers = async () => {
    await dispatch(get_intra_answer(id_appoinment));
  };

  useEffect(() => {
    if (!isActiveTool) {
      fetchDetailAnswers();
    }
  }, [isActiveTool]);

  useEffect(() => {
    setIsVenoGram(is_venogram === "isHave" ? true : false);
    if (is_venogram === "isHave") {
      setBodyTab("vein");
    } else {
      setBodyTab("arterial");
    }
  }, [is_venogram]);

  useEffect(() => {
    if (list_procedurals) {
      setIntaOperations(list_procedurals);
      if (list_procedurals[0]) {
        setCurrentOperation(list_procedurals[0]);
      }
    }
  }, [list_procedurals]);

  useEffect(() => {
    dispatch(getAngiographicSupplies(""));
    dispatch(getAppointmentInventory(id_appoinment));
    dispatch(angioPatientSnapshotLoadInit(id_appoinment));
    dispatch(load_procedurals(id_appoinment));
    dispatch(HPIGetConditions(id_appoinment));
    dispatch(getActiveStaff(""));
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, [id_appoinment]);

  useEffect(() => {
    const scrollContainer = document.querySelector(".collapseParentt").firstChild;
    const id = currentOperation.body.part;
    const panelHeight = 48;
    if (currentOperation.body.part) {
      const index = intaOperations
        .filter((r) => r?.body?.type !== "vein")
        .findIndex((operation) => {
          return operation.body.part === id;
        });
      const scrollHeight = panelHeight * index + 24;
      scrollContainer.scroll({
        top: scrollHeight,
        behavior: "smooth",
      });
    }
  }, [currentOperation]);

  useEffect(() => {
    const temp_names = [];
    const temp_id = [];
    const temp_items = [];
    if (appointmentInventories && appointmentInventories.length > 0) {
      (appointmentInventories || []).map((item) => {
        if (!temp_names.some((r) => r === item?.name)) {
          temp_names.push(item?.name);
        }
        if (!temp_id.some((r) => r === item?.id)) {
          temp_id.push(item?.id);
        }
        temp_items.push(item);
      });
      setItemNames(temp_names);
      setInventories(temp_id);
      setInventoryItems(temp_items);
    } else {
      setItemNames(temp_names);
      setInventories(temp_id);
      setInventoryItems(temp_items);
    }
  }, [appointmentInventories]);

  useEffect(() => {
    if (debouncedSearch) {
      const allInventories = filterDataByQuery(supplies, debouncedSearch);
      setAllInventory(allInventories);
      setLoading(false);
    }
  }, [debouncedSearch]);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const removeStateTools = async () => {
    setCurrentOperation({
      body: "",
      medicals: [],
      details: [],
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(load_procedurals(id_appoinment));
    }, 300);
  };

  const handleInventorySearch = (search) => {
    setInputInventory(search);
    if (search) {
      setLoading(true);
    }
  };

  const selectItemInventory = (itemName, itemId) => {
    if (itemName === "") return;
    for (const itemId_ of inventories) {
      if (itemId_ === itemId) return;
    }
    const inventory = supplies.find((su) => su?.id === itemId);
    let listInventory = [...inventories];
    listInventory.push(itemId);
    setInventories(listInventory);
    dispatch(
      saveAppointmentInventory(id_appoinment, {
        inventory_id: listInventory,
      })
    );
    listInventory = [...itemNames];
    listInventory.push(itemName);
    setItemNames(listInventory);
    listInventory = [...inventoryItems];
    listInventory.push(inventory);
    setInventoryItems(listInventory);
  };

  useEffect(() => {
    const operationIndexCheck = intaOperations.findIndex((inta) => inta.body.part === currentOperation.body.part);
    setOperationIndex(operationIndexCheck);
  }, [currentOperation, intaOperations]);

  const removeRowIventory = (item, index) => {
    let listItems = [...inventories];
    listItems.splice(index, 1);
    setInventories(listItems);
    dispatch(
      saveAppointmentInventory(id_appoinment, {
        inventory_id: listItems,
      })
    );
    listItems = [...itemNames];
    listItems.splice(index, 1);
    setItemNames(listItems);
    listItems = [...inventoryItems];
    listItems.splice(index, 1);
    setInventoryItems(listItems);
  };

  const handleIntaOperation = (type) => (value) => {
    if (!currentOperation.body.part && value.part && currentOperation.medicals.length > 0) {
      setCurrentOperation({
        ...currentOperation,
        [type]: { ...value, type: bodyTab },
      });
      updateOperations({
        ...currentOperation,
        [type]: { ...value, type: bodyTab },
      });
    } else if (currentOperation.body.part && value.part === currentOperation.body.part) {
      setCurrentOperation({ details: [], body: "", medicals: [] });
    } else if (currentOperation.body.part && value.part !== currentOperation.body.part) {
      const existingOperation = intaOperations.find((inta) => inta.body.part === value.part);
      if (existingOperation) {
        setCurrentOperation(existingOperation);
      } else {
        setCurrentOperation({
          details: [],
          medicals: intaOperations.length > 0 ? [] : currentOperation.medicals,
          [type]: { ...value, type: bodyTab },
        });
        if (!intaOperations.length && currentOperation.medicals[0]) {
          updateOperations({
            details: [],
            medicals: currentOperation.medicals,
            [type]: { ...value, type: bodyTab },
          });
        }
      }
    } else {
      setCurrentOperation({
        body: "",
        medicals: [],
        details: [],
        [type]: { ...value, type: bodyTab },
      });
    }
    setSelectedIndex(-1);
    setSelectedOperation("");
    setDisableDetail(true);
    setTab("tools");
  };

  const updateOperations = async (data) => {
    const existing = intaOperations.findIndex((inta) => inta?.body?.part === data?.body?.part);
    const operations = [...intaOperations];
    if (existing === -1) {
      operations.push({
        ...data,
        body: { ...(data?.body || {}), type: bodyTab },
      });
    } else {
      operations[existing] = {
        ...data,
        body: { ...(data?.body || {}), type: bodyTab },
      };
    }
    let isArrayGreater = true;
    dispatch(setProcedureReview("intra_operations", operations));
    if (intaOperations.length < operations.length) isArrayGreater = true;
    else isArrayGreater = false;
    await setIntaOperations(operations);
    if (isArrayGreater) {
      if (getCollapseHeight) setGetCollapseHeight(0);
      let scrollHeightt = document.querySelector(".collapseParentt") ? document.querySelector(".collapseParentt").clientHeight : null;
      if (scrollHeightt) {
        setGetCollapseHeight(scrollHeightt);
        collapseEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  };

  const handleClickLabel = (value) => {
    if (is_locked === false || is_locked === 0) {
      const current = { ...currentOperation };
      if (current.body && current.body.part) {
        current.medicals.push(value);
        current.details.push(undefined);
        updateOperations({ ...current });
      } else {
        current.medicals = [value];
        current.body = {
          offset: {
            screenX: 456,
            screenY: 704,
            x: 176,
            y: 341,
          },
          part: "m-body",
        };
        updateOperations({ ...current });
      }
      setCurrentOperation(current);
      setScrollState(true);
      if (scrollState) setScrollState(false);
    }
  };

  /*
  @params value: artery name
  @params currentPosition: artery object include body, medicals, details
  @params medicalLabel: medical name of object that we want to move
  */
  const handleChangeArtery = (value, currentPosition, medicalLabel) => {
    if (is_locked === false || is_locked === 0) {
      let positionRemove = intaOperations.findIndex((inta) => inta.body.part === currentPosition);
      let positionAdd = intaOperations.findIndex((inta) => inta.body.part === value);
      /*
      if value is null then the user may want to remove the medical?
      */
      if (!value || value == "") {
        if (positionRemove !== -1) {
          if (intaOperations?.[positionRemove]?.["medicals"] && Array.isArray(intaOperations?.[positionRemove]?.["medicals"])) {
            // check remove atery when not have medical
            if (intaOperations?.[positionRemove]?.["medicals"].filter((r) => r !== valueMedical).length == 0) {
              intaOperations.splice(positionRemove, 1);
            } else {
              // check remove medical when move
              intaOperations[positionRemove]["medicals"] = intaOperations?.[positionRemove]?.["medicals"].filter(
                (r, index) => index !== selectedIndex
              );
            }
          }
          return;
        }
      }
      // have 1 label not have stenosis
      let valueMedical = medicalLabel ? medicalLabel.toLowerCase() : "";
      valueMedical = valueMedical.replace(" ", "-").replace("%", "");
      let destinationLocation = intaOperations?.[positionAdd] || {};
      let previousDetails = intaOperations?.[positionRemove]?.["details"]?.[selectedIndex];
      // have 2 label have stenosis
      let previousDetailsNext = intaOperations?.[positionRemove]?.["details"]?.[selectedIndex + 1] || [];
      let valueMedicalNext = intaOperations?.[positionRemove]?.["medicals"]?.[selectedIndex + 1] || "";
      const passCantCheck = ["Laser Atherectomy", "Ultrasound", "Local Anesthesia", "Access", "Closure Device", "Stenosis", "In-Stent"];
      // check if when u change location have stenosis we need move all
      if ((previousDetails?.stenosis || previousDetails?.post_stenosis) && !passCantCheck.some((r) => r.includes(previousDetails?.item))) {
        // if when location change item and item not have in array all we will push new  and remove current
        if (positionRemove !== -1) {
          // remove current position
          if (intaOperations?.[positionRemove]?.["medicals"] && Array.isArray(intaOperations?.[positionRemove]?.["medicals"])) {
            // check remove atery when not have medical
            if (intaOperations[positionRemove]["medicals"].filter((r) => r !== valueMedical).length == 0) {
              intaOperations.splice(positionRemove, 1);
              positionAdd = positionAdd - 1;
            } else {
              // check remove medical when move
              const medicalsAll = intaOperations?.[positionRemove]?.["medicals"] || [];
              if ((medicalsAll || []).filter((r, index) => index !== selectedIndex && index !== selectedIndex + 1).length == 0) {
                intaOperations.splice(positionRemove, 1);
              } else {
                intaOperations[positionRemove]["medicals"] = intaOperations[positionRemove]["medicals"].filter(
                  (r, index) => index !== selectedIndex && index !== selectedIndex + 1
                );
                intaOperations[positionRemove]["details"] = intaOperations[positionRemove]["details"].filter(
                  (r, index) => index !== selectedIndex && index !== selectedIndex + 1
                );
              }
            }
          }
        }
        // add position change and merge
        if (positionAdd !== -1) {
          if (intaOperations?.[positionAdd]?.["medicals"] && Array.isArray(intaOperations?.[positionAdd]?.["medicals"])) {
            //get the copy of {body, details, medicals} at the new position
            destinationLocation.medicals = [...destinationLocation.medicals, valueMedical, valueMedicalNext]; //append the medicals with new medical
            destinationLocation.details = [...destinationLocation.details, previousDetails, previousDetailsNext]; //copy all the details from previous location to new location
          } else {
            destinationLocation.medicals = [valueMedical, valueMedicalNext];
            destinationLocation.details = [previousDetails, previousDetailsNext];
          }
          setCurrentOperation(destinationLocation);
          setIntaOperations([...intaOperations]);
          updateOperations({ ...destinationLocation });
        } else {
          // if this is a new artery that does not exist yet
          let newBodyPart = { ...currentOperation };
          newBodyPart.body = {
            offset: {
              screenX: 456,
              screenY: 704,
              x: 176,
              y: 341,
            },
            part: value,
          };
          newBodyPart.medicals = [valueMedical, valueMedicalNext];
          newBodyPart.details = [previousDetails, previousDetailsNext];
          setCurrentOperation(newBodyPart);
          updateOperations({ ...newBodyPart });
        }
      } else {
        // if when location change item and item not have in array all we will push new  and remove current
        if (positionRemove !== -1) {
          // remove current position
          if (intaOperations?.[positionRemove]?.["medicals"] && Array.isArray(intaOperations?.[positionRemove]?.["medicals"])) {
            // check remove atery when not have medical
            if (intaOperations[positionRemove]["medicals"].filter((r) => r !== valueMedical).length == 0) {
              intaOperations.splice(positionRemove, 1);
              positionAdd = positionAdd - 1;
            } else {
              // check remove medical when move
              intaOperations[positionRemove]["medicals"] = intaOperations[positionRemove]["medicals"].filter((r, index) => index !== selectedIndex);
              intaOperations[positionRemove]["details"] = intaOperations[positionRemove]["details"].filter((r, index) => index !== selectedIndex);
            }
          }
        }
        // add position change and merge
        if (positionAdd !== -1) {
          if (intaOperations?.[positionAdd]?.["medicals"] && Array.isArray(intaOperations?.[positionAdd]?.["medicals"])) {
            //get the copy of {body, details, medicals} at the new position
            destinationLocation.medicals = [...destinationLocation.medicals, valueMedical]; //append the medicals with new medical
            destinationLocation.details = [...destinationLocation.details, previousDetails]; //copy all the details from previous location to new location
          } else {
            destinationLocation.medicals = [valueMedical];
            destinationLocation.details = [previousDetails];
          }
          setCurrentOperation(destinationLocation);
          setIntaOperations([...intaOperations]);
          updateOperations({ ...destinationLocation });
        } else {
          // if this is a new artery that does not exist yet
          let newBodyPart = { ...currentOperation };
          newBodyPart.body = {
            offset: {
              screenX: 456,
              screenY: 704,
              x: 176,
              y: 341,
            },
            part: value,
          };
          newBodyPart.medicals = [valueMedical];
          newBodyPart.details = [previousDetails];
          setCurrentOperation(newBodyPart);
          updateOperations({ ...newBodyPart });
        }
      }
    }
  };

  const handleSelectIcon = (value, index) => {
    setDisableDetail(false);
    setSelectedOperation(value);
    setSelectedIndex(index);
    setTab("details");
  };

  const dataOnchange = (value) => {
    if (is_locked === false || is_locked === 0) {
      const operations = [...intaOperations];
      dispatch(setProcedureReview("intra_operations", operations));
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          save_info_side(id_appoinment, {
            ...value,
          })
        );
      }, 500);
    }
  };

  const handleRemoveMedical = (medical) => {
    const current = { ...currentOperation };
    if (selectedIndex === -1) {
      setSelectedOperation("");
      setDisableDetail(true);
      setTab("tools");
      return;
    } else {
      current.medicals.splice(selectedIndex, 1);
      current.details.splice(selectedIndex, 1);
      setCurrentOperation(current);
      updateOperations(current);
      setSelectedOperation("");
      setDisableDetail(true);
      setSelectedIndex(-1);
      if (
        intaOperations &&
        intaOperations[operationIndex] &&
        intaOperations[operationIndex]["medicals"] &&
        intaOperations[operationIndex]["medicals"].length == 0
      ) {
        intaOperations.splice(operationIndex, 1);
        dispatch(setProcedureReview("intra_operations", intaOperations));
        setIntaOperations([...intaOperations]);
      }
      setTab("tools");
    }
  };

  const handleMedicals = (medicals, from, to, details) => {
    const params = { ...currentOperation, medicals, details };
    setCurrentOperation(params);
    updateOperations(params);
    setSelectedIndex(-1);
    setSelectedOperation("");
    setDisableDetail(true);
    setTab("tools");
  };

  const saveCurrentOperation = (detail) => {
    const current = { ...currentOperation };
    if (current.medicals[selectedIndex]) {
      if (detail.post_stenosis) {
        const curOperation = getMedical(current.medicals[selectedIndex]);
        if (curOperation) {
          const operation = current.medicals[selectedIndex + 1] ? getMedical(current.medicals[selectedIndex + 1]) : undefined;
          if (curOperation.type !== "status" && !operation) {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.post_stenosis.replace("%", "");
            current.details[selectedIndex + 1] = [];
          } else if (curOperation.type !== "status" && operation.state && operation.state === "stenosis") {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.post_stenosis.replace("%", "");
          } else if (curOperation.type !== "status" && !operation.state) {
            current.medicals.splice(selectedIndex + 1, 0, "stenosis-" + detail.post_stenosis.replace("%", ""));
            current.details.splice(selectedIndex + 1, 0, []);
          }
          if (curOperation.type === "status") {
            current.medicals[selectedIndex] = curOperation.state + "-" + detail.post_stenosis.replace("%", "");
          }
          current.details[selectedIndex] = detail;
          setCurrentOperation(current);
          updateOperations(current);
        }
        // Todo implement
      } else if (detail.stenosis) {
        const curOperation = getMedical(current.medicals[selectedIndex]);
        if (curOperation) {
          const operation = current.medicals[selectedIndex + 1] ? getMedical(current.medicals[selectedIndex + 1]) : undefined;
          if (curOperation.type !== "status" && !operation) {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.stenosis.replace("%", "");
          } else if (curOperation.type !== "status" && operation.state && operation.state === "stenosis") {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.stenosis.replace("%", "");
          } else if (curOperation.type !== "status" && !operation.state) {
            current.medicals.splice(selectedIndex + 1, 0, "stenosis-" + detail.stenosis.replace("%", ""));
          }
          if (curOperation.type === "status") {
            current.medicals[selectedIndex] = curOperation.state + "-" + detail.stenosis.replace("%", "");
          }
          current.details[selectedIndex] = detail;
          setCurrentOperation(current);
          updateOperations(current);
        }
      } else {
        if (current.details[selectedIndex]) {
          current.details[selectedIndex] = detail;
          setCurrentOperation(current);
          updateOperations(current);
        }
      }
      // Todo implement
    }
  };

  const onHandleSaveAnswer = async (value) => {
    const arrAnswers = [];
    (value || []).map((r) => {
      (r?.values || []).map((ans) => {
        if (ans?.checked) {
          arrAnswers.push({
            ...ans,
            question: r?.typeFull,
            checked_choice: ans?.option,
            comments: "",
            id_question: r?.id,
            angio_procedure_type_variable_id: r?.id,
          });
        } else {
          arrAnswers.push({
            ...ans,
            question: r?.typeFull,
            checked_choice: "",
            comments: "",
            id_question: r?.id,
            angio_procedure_type_variable_id: r?.id,
          });
        }
      });
    });
    // check to join arr checked
    const arrChecked = [];
    for (let index = 0; index < arrAnswers.length; index++) {
      const element = arrAnswers[index];
      if (
        (arrChecked && arrChecked?.length == 0) ||
        !arrChecked?.some((r) => r?.angio_procedure_type_variable_id === element?.angio_procedure_type_variable_id)
      ) {
        arrChecked.push({
          ...element,
        });
      } else {
        const indexId = arrChecked.findIndex((find) => find?.angio_procedure_type_variable_id === element?.angio_procedure_type_variable_id);
        if (indexId != -1) {
          arrChecked[indexId] = {
            ...arrChecked[indexId],
            checked_choice: `${arrChecked[indexId]["checked_choice"]},${element?.checked_choice}`,
          };
        }
      }
    }

    clearTimeout(timer);
    timer = setTimeout(async () => {
      dispatch(setGlobalLoading(true));
      await save_intra_answer(id_appoinment, {
        answers: arrChecked,
        appointment_id: Number(id_appoinment),
        angio_procedure_type_variable_id: (dataCPT && dataCPT["appointment_type_id"]) || 0,
      });
      await dispatch(get_intra_answer(id_appoinment));
      dispatch(setGlobalLoading(false));
    }, 300);
  };

  const handleSaveDocument = async () => {
    dispatch(setGlobalLoading(true));
    if (dataCheck && dataCheck.length > 0) {
      const fixArr = (dataCheck || []).map((r) => {
        return {
          ...r,
          questions: (r?.questions || []).map((q) => {
            return {
              ...q,
              comments: RemoveN(q?.comments),
            };
          }),
        };
      });
      await dispatch(
        save_intra_operative(id_appoinment, {
          patient_id: patient_id,
          conditions: fixArr,
        })
      );
      setDataCheck(null);
    }
    dispatch(setGlobalLoading(false));
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="patientContainer">
          <div className="imageSection" ref={imageRef} style={{ position: "relative" }}>
            <div
              className="tabHeaders new"
              style={{
                position: "absolute",
                top: "2rem",
                left: "calc(50% + 3rem)",
                transform: "translateX(-50%)",
              }}
            >
              {!isVenoGram && (
                <div
                  className={`tabHeaders-item
                 ${bodyTab === "arterial" ? "active" : ""}`}
                  style={{ textAlign: "left" }}
                  onClick={() => {
                    setBodyTab("arterial");
                    removeStateTools("arterial");
                  }}
                >
                  Arterial Tree
                </div>
              )}
              {isVenoGram && (
                <div
                  className={`tabHeaders-item 
                  ${bodyTab === "vein" ? "active" : ""}`}
                  onClick={() => {
                    setBodyTab("vein");
                    removeStateTools("vein");
                  }}
                >
                  Vein Tree
                </div>
              )}
            </div>
            {bodyTab === "arterial" ? (
              <IntaOperationBody
                current={currentOperation}
                operations={intaOperations.filter((r) => r?.body?.type !== "vein")}
                handleClick={handleIntaOperation("body")}
              />
            ) : (
              <IntraOperationNewBody
                current={currentOperation}
                operations={intaOperations.filter((r) => r?.body?.type === "vein")}
                handleClick={handleIntaOperation("body")}
              />
            )}
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className="detailSection" style={{ paddingRight: "10px" }}>
              <div className="tabHeaders new">
                <div
                  className={`tabHeaders-item ${tab === "documentation" ? "active" : ""}`}
                  style={{ textAlign: "left" }}
                  onClick={() => setTab("documentation")}
                >
                  Documentation
                </div>
                <div
                  className={`tabHeaders-item ${tab === "tools" ? "active" : ""} ${isActiveTool ? "" : "disabled"}`}
                  onClick={() => {
                    handleSaveDocument();
                    setTab("tools");
                  }}
                >
                  Tools
                </div>
                <div
                  className={`tabHeaders-item ${tab === "details" ? "active" : ""} ${disableDetail && isActiveTool && "disabled"}`}
                  onClick={() => {
                    handleSaveDocument();
                    if (!disableDetail || !isActiveTool) {
                      setTab("details");
                    }
                  }}
                >
                  Details
                </div>
                <div
                  className={`tabHeaders-item ${tab === "inventory-utilization" ? "active" : ""}`}
                  onClick={() => {
                    handleSaveDocument();
                    setTab("inventory-utilization");
                  }}
                >
                  Inventory & Utilization
                </div>
              </div>

              <CardWhiteBlock
                varient={"default"}
                title={""}
                other={tab === "documentation" ? "full-height" : "half-height"}
                cardContainerStyle={{
                  height: tab === "documentation" ? "100%" : "calc(50% - 1rem - 20px)",
                }}
                extraStyle={{
                  padding: tab === "documentation" ? "0px" : "0 1.5rem 1rem",
                  backgroundColor: tab === "documentation" ? "#f4f7fb" : "transparent",
                  height: "100%",
                }}
              >
                {tab === "inventory-utilization" ? (
                  <div
                    className="content"
                    style={{
                      padding: "28px 1rem 12px",
                    }}
                  >
                    <AutoCompleteComponent
                      title="inta-operative"
                      placeholder="Search Inventory"
                      rows={itemNames}
                      disableDelete={is_locked}
                      keyLabel={"name"}
                      keyValue={"id"}
                      disableSearchIcon={false}
                      removeItem={removeRowIventory}
                      loading={loading}
                      options={allInventory}
                      isResult={false}
                      selectItem={selectItemInventory}
                      handleSearch={handleInventorySearch}
                      minRows={14}
                      isLocked={is_locked}
                      isIntraOperative={true}
                    />
                  </div>
                ) : (
                  <ScrollWrapper
                    css={`x-hidden
                    ${tab === "documentation" ? "no-padding" : "no-padding-obl"}
                  `}
                  >
                    {tab === "documentation" && (
                      <div
                        className="content"
                        style={{
                          padding: "28px 0 12px",
                          height: `calc(${dataCheck && dataCheck.length <= 2 ? "160em" : "100em"} * ${
                            dataCheck && dataCheck.length > 1 ? dataCheck.length : 1
                          })`,
                        }}
                      >
                        <ProcedureSearch options={allConditions} updateData={setDataCheck} />
                      </div>
                    )}
                    {tab === "indication" && (
                      <div className="content" style={{ padding: "28px 0 12px" }}>
                        <div className="info d-flex">
                          <Select
                            placeholder="Procedure to be Performed..."
                            suffixIcon={<ExpandSVG />}
                            allowClear={true}
                            style={{
                              width: "25%",
                              minWidth: "260px",
                            }}
                          >
                            {ProcedureOptions.map((opt, index) => (
                              <Option key={`procedure-to-be-performed-${index}`} value={opt}>
                                {opt}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="info d-flex">
                          <Select
                            placeholder="Clinical Indication..."
                            suffixIcon={<ExpandSVG />}
                            allowClear={true}
                            style={{
                              width: "25%",
                              minWidth: "260px",
                            }}
                          >
                            <Option value="Critical Limb Ischemia">Critical Limb Ischemia</Option>
                            <Option value="Critical Limb Ischemia1">Critical Limb Ischemia</Option>
                          </Select>
                        </div>
                      </div>
                    )}
                    {tab === "tools" && (
                      <Collapse className="card-collapse" accordion style={{ paddingTop: "12px" }}>
                        <Panel header="Access" key="Access">
                          <div
                            className="content"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <LabelIcon
                              label={"Ultrasound"}
                              isDraggable={true}
                              icon={<UltrasoundProbeIcon />}
                              item="ultrasound"
                              handleClick={() => handleClickLabel("ultrasound")}
                            />
                            <LabelIcon
                              label={"Local Anesthesia"}
                              isDraggable={true}
                              icon={<DugsSVG />}
                              item="local-anesthesia"
                              handleClick={() => handleClickLabel("local-anesthesia")}
                            />
                            <LabelIcon
                              label={"Access"}
                              isDraggable={true}
                              icon={<DevicesSVG />}
                              item="access"
                              handleClick={() => handleClickLabel("access")}
                            />
                            <LabelIcon
                              label={"Closure Device"}
                              isDraggable={true}
                              icon={
                                <img
                                  src="/images/closure-featured.png"
                                  alt="closure device"
                                  style={{
                                    width: "32px",
                                    height: "32px",
                                    marginLeft: 0,
                                    marginBottom: "5px",
                                  }}
                                />
                              }
                              item="closure-device"
                              handleClick={() => handleClickLabel("closure-device")}
                            />
                          </div>
                        </Panel>
                        <Panel header="Diagnostic" key="Diagnostic">
                          <div
                            className="content"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              flexWrap: "wrap",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <LabelIcon
                              label={"Stenosis 0%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaque0 />}
                              isHover={true}
                              item="stenosis-0"
                              active={!!currentOperation && currentOperation.diagnostic === "stenosis-0"}
                              handleClick={() => handleClickLabel("stenosis-0")}
                            />
                            <LabelIcon
                              label={"Stenosis 20%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaque20 />}
                              isHover={true}
                              item="stenosis-20"
                              active={!!currentOperation && currentOperation.diagnostic === "stenosis-20"}
                              handleClick={() => handleClickLabel("stenosis-20")}
                            />
                            <LabelIcon
                              label={"Stenosis 40%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaque40 />}
                              isHover={true}
                              item="stenosis-40"
                              active={!!currentOperation && currentOperation.diagnostic === "stenosis-40"}
                              handleClick={() => handleClickLabel("stenosis-40")}
                            />
                            <LabelIcon
                              label={"Stenosis 60%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaque60 />}
                              isHover={true}
                              item="stenosis-60"
                              active={!!currentOperation && currentOperation.diagnostic === "stenosis-60"}
                              handleClick={() => handleClickLabel("stenosis-60")}
                            />
                            <LabelIcon
                              label={"Stenosis 80%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaque80 />}
                              isHover={true}
                              item="stenosis-80"
                              active={!!currentOperation && currentOperation.diagnostic === "stenosis-80"}
                              handleClick={() => handleClickLabel("stenosis-80")}
                            />
                            <LabelIcon
                              label={"Stenosis 100%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaque100 />}
                              isHover={true}
                              item="stenosis-100"
                              active={!!currentOperation && currentOperation.diagnostic === "stenosis-100"}
                              handleClick={() => handleClickLabel("stenosis-100")}
                            />
                            <LabelIcon
                              label={"In-Stent 0%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaqueStent0 />}
                              isHover={true}
                              item="in-stent-0"
                              active={!!currentOperation && currentOperation.diagnostic === "in-stent-0"}
                              handleClick={() => handleClickLabel("in-stent-0")}
                            />
                            <LabelIcon
                              label={"In-Stent 20%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaqueStent20 />}
                              isHover={true}
                              item="in-stent-20"
                              active={!!currentOperation && currentOperation.diagnostic === "in-stent-20"}
                              handleClick={() => handleClickLabel("in-stent-20")}
                            />
                            <LabelIcon
                              label={"In-Stent 40%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaqueStent40 />}
                              isHover={true}
                              item="in-stent-40"
                              active={!!currentOperation && currentOperation.diagnostic === "in-stent-40"}
                              handleClick={() => handleClickLabel("in-stent-40")}
                            />
                            <LabelIcon
                              label={"In-Stent 60%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaqueStent60 />}
                              isHover={true}
                              item="in-stent-60"
                              active={!!currentOperation && currentOperation.diagnostic === "in-stent-60"}
                              handleClick={() => handleClickLabel("in-stent-60")}
                            />
                            <LabelIcon
                              label={"In-Stent 80%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaqueStent80 />}
                              isHover={true}
                              item="in-stent-80"
                              active={!!currentOperation && currentOperation.diagnostic === "in-stent-80"}
                              handleClick={() => handleClickLabel("in-stent-80")}
                            />
                            <LabelIcon
                              label={"In-Stent 100%"}
                              isDraggable={true}
                              icon={<BloodVesselPlaqueStent100 />}
                              isHover={true}
                              item="in-stent-100"
                              active={!!currentOperation && currentOperation.diagnostic === "in-stent-100"}
                              handleClick={() => handleClickLabel("in-stent-100")}
                            />
                            <LabelIcon
                              label={"IVUS"}
                              isDraggable={true}
                              icon={<IVUSIcon />}
                              isHover={true}
                              item="ivus"
                              active={!!currentOperation && currentOperation.diagnostic === "ivus"}
                              handleClick={() => handleClickLabel("ivus")}
                            />
                          </div>
                        </Panel>
                        {bodyTab !== "vein" && (
                          <Panel header="Atherectomy" key="Atherectomy">
                            <div
                              className="content"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                paddingTop: "0.5rem",
                              }}
                            >
                              <LabelIcon
                                label={"Directional Atherectomy"}
                                isDraggable={true}
                                icon={<AtherectomyDirectionalIcon />}
                                item="directional-atherectomy"
                                handleClick={() => handleClickLabel("directional-atherectomy")}
                              />
                              <LabelIcon
                                label={"Laser Atherectomy"}
                                isDraggable={true}
                                icon={<AtherectomyLaserIcon />}
                                item="laser-atherectomy"
                                handleClick={() => handleClickLabel("laser-atherectomy")}
                              />
                              <LabelIcon
                                label={"Rotational Atherectomy"}
                                isDraggable={true}
                                icon={<AtherectomyRotationalIcon />}
                                item="rotational-atherectomy"
                                handleClick={() => handleClickLabel("rotational-atherectomy")}
                              />
                              <LabelIcon
                                label={"Obital Atherectomy"}
                                isDraggable={true}
                                icon={<AtherectomyTransluminalIcon />}
                                item="obital-atherectomy"
                                handleClick={() => handleClickLabel("obital-atherectomy")}
                              />
                            </div>
                          </Panel>
                        )}
                        <Panel header="Angioplasty" key="Angioplasty">
                          <div
                            className="content"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <LabelIcon
                              label={"Balloon Angioplasty"}
                              isDraggable={true}
                              icon={<BalloonIcon />}
                              item="balloon-angioplasty"
                              handleClick={() => handleClickLabel("balloon-angioplasty")}
                            />
                          </div>
                        </Panel>
                        <Panel header="Stenting" key="Stenting">
                          <div
                            className="content"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              flexWrap: "wrap",
                              paddingTop: "0.5rem",
                            }}
                          >
                            <LabelIcon
                              label={"Balloon Expandable Covered Stent"}
                              isDraggable={true}
                              icon={<StentBalloonCoveredIcon />}
                              item="balloon-expandable-covered-stent"
                              handleClick={() => handleClickLabel("balloon-expandable-covered-stent")}
                            />
                            <LabelIcon
                              label={"Covered Stent"}
                              isDraggable={true}
                              icon={<StentCoveredIcon />}
                              item="covered-stent"
                              handleClick={() => handleClickLabel("covered-stent")}
                            />
                            <LabelIcon
                              label={"Balloon Expandable Stent"}
                              isDraggable={true}
                              icon={<StentBalloonIcon />}
                              item="balloon-expandable-stent"
                              handleClick={() => handleClickLabel("balloon-expandable-stent")}
                            />
                            <LabelIcon
                              label={"Self Expanding Stent"}
                              isDraggable={true}
                              icon={<StentIcon />}
                              item="self-expanding-stent"
                              handleClick={() => handleClickLabel("self-expanding-stent")}
                            />
                          </div>
                        </Panel>
                        <Panel header="Other" key="Other">
                          <div
                            className="content"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              paddingTop: "0.5rem",
                            }}
                          ></div>
                        </Panel>
                      </Collapse>
                    )}
                    {tab === "details" && (
                      <>
                        {isActiveTool ? (
                          <AccessDetails
                            handleChangeArtery={handleChangeArtery}
                            title={"19G Access"}
                            inventories={supplies}
                            items={inventoryItems}
                            data={SampleAccessData}
                            current={currentOperation}
                            activeIndex={selectedIndex}
                            activeItem={selectedOperation}
                            handleSubmit={saveCurrentOperation}
                            removeItem={handleRemoveMedical}
                            isVenoGram={isVenoGram}
                          />
                        ) : (
                          <div
                            className="resourceContent"
                            style={{
                              flex: "unset",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <div
                              className="resourceInfo"
                              style={{
                                paddingLeft: "0px",
                                display: "block",
                                paddingTop: 20,
                              }}
                            >
                              <ExaminationIntra
                                type="procedure"
                                onChangeData={(value) => {
                                  onHandleSaveAnswer(value);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </ScrollWrapper>
                )}
              </CardWhiteBlock>
              <CardWhiteBlock
                varient={"default"}
                cardContainerStyle={{
                  height: "calc(50% - 20px)",
                  marginBottom: 0,
                }}
                extraStyle={{
                  height: "calc(100% - 2.8rem)",
                  paddingBottom: 0,
                }}
                title={
                  <div
                    className="pannel-title"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "-12px",
                      display: "flex",
                    }}
                  >
                    <Icon type="appstore" theme="twoTone" twoToneColor="#EE5396" />
                    <span
                      style={{
                        marginLeft: "8px",
                        fontSize: "18px",
                        fontWeight: "normal",
                      }}
                    >
                      PROCEDURAL DOCUMENTATION
                    </span>
                  </div>
                }
                other={tab === "documentation" ? "d-none" : "half-height"}
              >
                <ScrollWrapper css="no-padding-procedural x-hidden collapseParentt">
                  <Collapse
                    className="card-collapse bodyPart_parent "
                    accordion
                    style={{ paddingTop: "24px" }}
                    activeKey={currentOperation?.body?.part}
                    onChange={(pannelKey) => {
                      const operation = intaOperations.find((inta) => inta.body.part === pannelKey);
                      if (currentOperation && operation && pannelKey !== currentOperation.body.part) {
                        setCurrentOperation(operation);
                        setDisableDetail(true);
                        setSelectedOperation("");
                        setSelectedIndex(-1);
                        setTab("tools");
                      } else {
                        setCurrentOperation({
                          ...SampleOperation,
                          body: "",
                          medicals: [],
                        });
                        setDisableDetail(true);
                        setSelectedOperation("");
                        setSelectedIndex(-1);
                        setTab("tools");
                      }
                    }}
                  >
                    {bodyTab === "arterial"
                      ? (intaOperations.filter((r) => r?.body?.type !== "vein") || []).map((operation, index, arr) => {
                          return (
                            <Panel
                              header={getArteryLabel(operation.body.part)}
                              key={operation.body.part}
                              id={operation.body.part}
                              className={[arr.length - 1].includes(index) ? `secondLastPanel` : ""}
                            >
                              <ArteryDetail
                                data={operation.body}
                                medicals={operation.medicals}
                                details={operation.details}
                                diagnostic={operation.diagnostic}
                                medical={operation.medical}
                                handleOperation={handleSelectIcon}
                                handleMedicals={handleMedicals}
                                isDraggable={true}
                              />
                            </Panel>
                          );
                        })
                      : (intaOperations.filter((r) => r?.body?.type === "vein") || []).map((operation, index) => (
                          <Panel header={getVeinLabel(operation.body.part)} key={operation.body.part}>
                            <VeinDetail
                              data={operation.body}
                              medicals={operation.medicals}
                              diagnostic={operation.diagnostic}
                              medical={operation.medical}
                              handleOperation={handleSelectIcon}
                              handleMedicals={handleMedicals}
                              isDraggable={true}
                            />
                          </Panel>
                        ))}
                    {[1, 2, 3, 4, 5, 6]?.map((item) => {
                      return (
                        <Panel header={"my header"} key={item} style={{ visibility: "hidden" }}>
                          <div></div>
                        </Panel>
                      );
                    })}
                    <div ref={collapseEndRef}></div>
                  </Collapse>
                </ScrollWrapper>
              </CardWhiteBlock>
            </div>
          </DndProvider>
        </div>
      </div>
      {isSidebar === true && (
        <IntaOperativeSideBar
          ref={sidebarRef}
          dataOnchange={dataOnchange}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          appointment={dataCPT}
        />
      )}
    </div>
  );
};

export default IntaOperative;

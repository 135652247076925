import React, { Component } from "react";
import { connect } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ReviewSidebar from "./ReviewSidebar";
import ReviewText from "./ReviewText";
import {
  clinicProviderReviewLoadInit,
  get_cpt_code_review,
  HPIGetConditions,
  patientExaminationLoadInit,
  setSidebarState,
  patient_insurances_id_insurances,
  updateAppointmentFinalizedDate,
} from "../../../../store/actions";
import { Icon } from "antd";
import { getLocal } from "../../../../helpers/Local";
import moment from "moment";
import _ from "lodash";

class Review extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  state = {
    isSidebar: false,
    valueText: "",
    finalizedDate: moment().format("MM/DD/YYYY hh:mm:ss A"),
    finalizedDateDate: moment().format("MM/DD/YYYY"),
    providerId: 0,
    providerName: "",
  };

  async componentDidMount() {
    this.props.setSidebarState(true);
    const procedure_id = getLocal("current_appointment_id");
    const current_patient_id = getLocal("current_patient_id");
    if (current_patient_id) {
      this.props.patient_insurances_id_insurances(current_patient_id);
    }
    this.props.HPIGetConditions(procedure_id);
    this.props.patientExaminationLoadInit(procedure_id);
    this.props.get_cpt_code_review(procedure_id);
    this.setState({ isSidebar: window.innerWidth <= 768 ? false : true });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.CPT?.finalized_date) {
      this.setState({
        finalizedDate: moment(nextProps?.CPT?.finalized_date).format("MM/DD/YYYY hh:mm:ss A"),
        finalizedDateDate: moment(nextProps?.CPT?.finalized_date).format("MM/DD/YYYY"),
      });
    }
    const current_provider_id = getLocal("cvai-current-user-provider-id");
    const current_provider_name = getLocal("cvai-current-user-provider-name");
    //if current logged in provider == provider on the app
    if (current_provider_name && current_provider_id && parseInt(current_provider_id) != 110) {
      if (!this.state.providerName) {
        this.setState({ providerId: current_provider_id });
        this.setState({ providerName: current_provider_name });
      }
    } else {
      if (nextProps?.CPT?.provider_id) {
        if (!this.state.providerName) {
          this.setState({ providerId: nextProps?.CPT?.provider_id });
          this.setState({ providerName: nextProps?.CPT?.provider.value });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEditable = (status) => {
    this.setState({ isEditable: status });
  };

  handleContent = (e) => {
    this.setState({ content: e.target?.value });
  };

  handleSubmit = () => {
    this.setState({ isEditable: false, content: this.state.editedContent });
  };

  cancleEditable = () => {
    this.setState({ isEditable: false });
  };

  changeValueText = (value) => {
    this.setState({ valueText: value });
  };

  handleChangeTime = (value) => {
    const procedure_id = getLocal("current_appointment_id");
    this.setState({
      finalizedDate: moment(value).format("MM/DD/YYYY hh:mm:ss A"),
    });
    this.props.updateAppointmentFinalizedDate(procedure_id, {
      finalized_date: moment(value).format("YYYY-MM-DD HH:mm:ss"),
    });
    this.setState({ finalizedDateDate: moment(value).format("MM/DD/YYYY") });
  };

  handleChangeProvider = (value) => {
    this.setState({
      providerId: value,
    });
    if (this.props.providers.length > 0) {
      let found_provider = _.find(this.props.providers, function (provider) {
        return provider.id === value;
      });
      if (found_provider) {
        this.setState({
          providerName: found_provider.value,
        });
      }
    }
  };

  render() {
    const { isSidebar, valueText, finalizedDate, finalizedDateDate, providerId, providerName } = this.state;

    return (
      <div className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <ScrollWrapper css="conditionList">
            <ReviewText
              finalizedDate={finalizedDate}
              finalizedDateDate={finalizedDateDate}
              changeValueText={(value) => {
                this.changeValueText(value);
              }}
              providerId={providerId}
              providerName={providerName}
            />
          </ScrollWrapper>
        </div>
        <ReviewSidebar
          sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
          ref={this.sidebarRef}
          valueText={valueText}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          handleChangeTime={this.handleChangeTime}
          handleChangeProvider={this.handleChangeProvider}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  CPT: state.common.CPT,
  providers: state.common.only_providers,
});

const mapDispatchToProps = {
  HPIGetConditions,
  get_cpt_code_review,
  patientExaminationLoadInit,
  setSidebarState,
  patient_insurances_id_insurances,
  updateAppointmentFinalizedDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
